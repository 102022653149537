<template>
  <div>
    <div v-show="error" style="border: 1px solid red; color: red; padding: 10px">
      {{ error }}
    </div>
    <div>
      <label for="new-board-name">Name</label>
      <b-form-input id="new-board-name" v-model="name"></b-form-input>
    </div>
    <div style="margin-top: 20px">
      <b-btn variant="primary" style="margin-right: 10px" @click="save()">Save</b-btn>
      <b-btn @click="close()">Close</b-btn>
    </div>
  </div>
</template>

<script>
  import backend from '@/utils/TaskBackend'
  export default {
    components: {},
    props: [],
    data() {
      return {
        name: '',
        type: 'board',
        error: '',
      }
    },
    methods: {
      save() {
        const payload = {
          name: this.name,
          type: this.type,
        }
        backend.newBoard(payload).then(
          (board) => {
            this.$emit('saved', board)
          },
          (error) => {
            this.error = error
          },
        )
      },
      close() {
        this.$emit('close')
      },
    },
  }
</script>

<style lang="sass" scoped>
</style>
