<template>
  <div>
    <text-input
      :value="value"
      :placeholder="placeholder"
      :clear-on-blur="clearOnBlur"
      @input="(v, ...extra) => $emit('input', v, ...extra)"
      @enter="(...args) => $emit('enter', ...args)"
    >
    </text-input>
  </div>
</template>

<script>
  import TextInput from '@/components/Task/TextInput'

  export default {
    components: {
      TextInput,
    },
    props: ['value', 'placeholder', 'clearOnBlur'],
  }
</script>

<style lang="sass" scoped>
</style>
