<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-lg-4">
        <stats-card
          :chart="closeCount.chart"
          :label="closeCount.label"
          :suffix="closeCount.suffix"
          :thresholds="closeCount.thresholds"
          :value="closeCount.value"
        >
        </stats-card>
      </div>

      <div class="col col-lg-4">
        <stats-card
          :chart="openCount.chart"
          :label="openCount.label"
          :suffix="openCount.suffix"
          :thresholds="openCount.thresholds"
          :value="openCount.value"
        >
        </stats-card>
      </div>

      <div class="col col-lg-4">
        <div style="float: right; padding: 15px">user pic and name here</div>
      </div>
    </div>
    <!-- .row -->

    <div class="row">
      <div class="col col-lg-4">
        <stats-card
          :chart="closeTime.chart"
          :label="closeTime.label"
          :suffix="closeTime.suffix"
          :thresholds="closeTime.thresholds"
          :value="closeTime.value"
        >
        </stats-card>
      </div>

      <div class="col col-lg-4">
        <stats-card
          :chart="reactionTime.chart"
          :label="reactionTime.label"
          :suffix="reactionTime.suffix"
          :thresholds="reactionTime.thresholds"
          :value="reactionTime.value"
        >
        </stats-card>
      </div>

      <div class="col col-lg-4">
        <stats-card
          :chart="ages.chart"
          :label="ages.label"
          :suffix="ages.suffix"
          :thresholds="ages.thresholds"
          :type="'bar'"
          :value="ages.value"
        >
        </stats-card>
      </div>
    </div>
    <!-- .row -->

    <div class="row">
      <div class="col col-lg-7">
        <div>
          <h5>Close goal</h5>
          <!--
          <heatmap :entries="closeGoalHeatmap.entries"></heatmap>
          -->
          (heatmap disabled)
        </div>

        <div>
          <h5>Close count</h5>
          <!--
          <heatmap :entries="closeCountHeatmap.entries"></heatmap>
          -->
          (heatmap disabled)
        </div>
      </div>
      <div class="col col-lg-5">
        <h5>Closed per day in week</h5>
        <week-chart :items="closedPerDayInWeek.items"></week-chart>
      </div>
    </div>
  </div>
  <!-- .front-page container-fluid -->
</template>

<script>
  import StatsCard from '@/components/StatsCard.vue'
  import WeekChart from '@/components/WeekChart.vue'
  import http from '@/utils/http'

  export default {
    name: 'TicketsUser',
    components: { StatsCard, WeekChart },
    data() {
      return {
        openCount: {
          value: 0,
          suffix: 'tickets',
          label: 'open',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        closeCount: {
          value: 0,
          suffix: 'tickets',
          label: 'closed this week',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        reactionTime: {
          value: 0,
          suffix: 'min',
          label: 'react time',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        closeTime: {
          value: 0,
          suffix: 'days',
          label: 'solve time',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        ages: {
          value: 0,
          suffix: 'days',
          label: 'age',
          chart: {
            labels: ['0-3d', '4-15d', '>15d'],
            values: [2, 8, 20],
          },
          thresholds: { levels: null, ascending: null },
        },
        closeCountHeatmap: {
          entries: [],
        },
        closeGoalHeatmap: {
          entries: [],
        },
        closedPerDayInWeek: {
          items: [],
        },
      }
    },
    mounted() {
      http
        .get('/tickets/stats/user')
        .then((response) => {
          const data = response.data.data
          console.log('data:', data)
          for (const key in data) {
            if (this[key] && this[key].value !== undefined) {
              // Count-ish stat (open tickets, new tickets, solve time, etc)
              this[key].value = data[key].value
              const labels = []
              const values = []
              for (let i = 0; i < data[key].chart.length; i++) {
                labels[i] = data[key].chart[i].label
                values[i] = data[key].chart[i].value
              }
              this[key].chart.labels = labels
              this[key].chart.values = values
              this[key].suffix = data[key].suffix
              this[key].thresholds.levels = data[key].thresholds || null
              this[key].thresholds.ascending = data[key].thresholdAscending || true
            }
          }
          this.closeCountHeatmap.entries = data.closeCountHeatmap
          this.closeGoalHeatmap.entries = data.closeGoalHeatmap
          this.closedPerDayInWeek.items = data.closedPerDayInWeek
        })
        .catch((error) => {
          console.error('get /api/stats', error)
        })
    },
  }
</script>

<style scoped>
  h4 {
    background: #666;
    color: white;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
  }

  h4 .comment {
    font-size: 12px;
    float: right;
    color: #eee;
  }
</style>
