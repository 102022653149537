<template>
  <div class="card">
    <div class="card-header"> Todos </div>
    <ul class="list-group list-group-flush">
      <li v-for="todo in todos" :key="todo._id" class="list-group-item">
        <div>
          {{ todo.issue_title }}
        </div>
        <a :href="todo.target_url" target="_new">
          {{ todo.body }}
        </a>
        <div class="row extra-info">
          <div class="col-lg-4 project">
            {{ todo.project }}
          </div>
          <div class="col-lg-4 number"> {{ todo.target_type }} #{{ todo.target_number }} </div>
          <div class="col-lg-4 author">
            {{ todo.author }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'GitlabTodos',
    data() {
      return {
        todos: [],
      }
    },
    mounted() {
      http
        .get('/gitlab/todos')
        .then((response) => {
          this.todos = response.data.data || []
        })
        .catch((error) => {
          console.error('get todos', error)
        })
    },
    methods: {},
  }
</script>

<style scoped lang="sass">
  .extra-info
    font-size: 11px
    .project
      text-align: left
    .number
      text-align: center
    .author
      text-align: right
</style>
