<template>
  <canvas width="650" height="200"></canvas>
</template>

<script>
  import Chart from 'chart.js/dist/Chart.bundle.js'

  export default {
    name: 'DashTicketsDistributionChart',
    props: ['data'],
    data() {
      return {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        datasets: [
          {
            label: 'Support',
            data: [1, 2, 3, 4],
            backgroundColor: 'rgba(0, 54, 235, 0.3)',
            borderColor: 'rgba(0, 54, 235, 1)',
            borderWidth: 1,
          },
          {
            label: 'Tech',
            data: [2, 2, 2, 2],
            backgroundColor: 'rgba(255, 54, 54, 0.3)',
            borderColor: 'rgba(255, 54, 54, 1)',
            borderWidth: 1,
          },
          {
            label: 'Solved',
            data: [3, 3, 3, 3],
            backgroundColor: 'rgba(54, 255, 54, 0.3)',
            borderColor: 'rgba(54, 255, 54, 1)',
            borderWidth: 1,
          },
        ],
      }
    },
    watch: {
      data(_newData) {
        this._chart.destroy()
        this.renderChart()
      },
    },
    mounted() {
      this.renderChart()
    },
    methods: {
      renderChart() {
        this._chart = new Chart(this.$el, {
          type: 'line',
          data: {
            legend: {
              display: false,
            },
            labels: this.labels,
            datasets: this.datasets,
          },
          options: {
            scales: {
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            legend: {
              display: true,
            },
          },
        })
      },
    },
  }
</script>
