<template>
  <dash-card :title="title" :clickable="true">
    <dash-card-value
      :is-todo="true"
      :thresholds="thresholds.pendingRemittancesCount"
      :value="data.count || '0'"
      subtext="Pending approval"
    >
    </dash-card-value>
    <dash-card-value
      :is-todo="true"
      :small="true"
      :thresholds="thresholds.pendingRemittancesAge"
      :value="data.maxAgeDays || '0'"
      subtext="Oldest days"
    >
    </dash-card-value>
    <template #dialog>
      <table class="table">
        <thead>
          <tr>
            <th>Due Date</th>
            <th>Supplier</th>
            <th>Description</th>
            <th style="text-align: right">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="v in data.remittances" :key="v._id">
            <td>{{ v.dueDate }}</td>
            <td>{{ v.supplier_name }}</td>
            <td>{{ v.description }}</td>
            <td style="text-align: right">{{ v.amount }}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </dash-card>
</template>

<script>
  import DashCard from './DashCard'
  import DashCardValue from './DashCardValue'

  export default {
    components: { DashCard, DashCardValue },
    props: ['title', 'data', 'thresholds'],
    data() {
      return {
        isDialogVisible: false,
      }
    }
  }
</script>

<style lang="sass" scoped>
  .dash-card
    margin: 20px
    border: 1px solid #ccc
    border-radius: 10px

    &.clickable
      cursor: pointer

    .title
      padding: 10px
      text-align: center
      background: #dddddd
      border-radius: 8px 8px 0 0

    .content
      border-top: 1px solid #ccc
      min-height: 180px
</style>
