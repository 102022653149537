<template>
  <div>
    <auto-complete
      :value="value"
      :options="options"
      :always-show-suggestions="false"
      :disallow-new="false"
      @input="(v) => $emit('input', v)"
      @new="(v) => $emit('new', v)"
      @enter="(...args) => $emit('enter', ...args)"
    >
    </auto-complete>
  </div>
</template>

<script>
  import AutoComplete from '@/components/Task/AutoComplete'

  export default {
    components: {
      AutoComplete,
    },
    props: ['value', 'options', 'loading'],
    data() {
      return {
        internalValue: '',
      }
    },
    mounted() {
      this.internalValue = this.value
    },
  }
</script>

<style lang="sass" scoped>
</style>
