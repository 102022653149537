<template>
  <div>
    <div class="row toolbar">
      <div class="col-md-3">
        <b-form-select v-model="author" :options="authors" />
      </div>
      <div class="col-md-6">
        <period-selector v-model="period"></period-selector>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-table
          striped
          hover
          :items="getItemsByAuthor"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        />
      </div>
    </div>
  </div>
</template>

<script>
  // todo: heatmap of commit/changes activity each day

  import http from '@/utils/http'
  import PeriodSelector from '@/components/PeriodSelector'

  export default {
    components: {
      'period-selector': PeriodSelector,
    },
    data() {
      return {
        period: {
          from: this.$route.params.from,
          to: this.$route.params.to,
        },
        author: this.$route.params.author,
        authors: [],
        items: [],
        sortBy: 'date',
        sortDesc: true,
        fields: [
          {
            key: 'issueTitle',
            label: 'Issue',
            sortable: true,
          },
          {
            key: 'project',
            label: 'Project',
            sortable: true,
          },
          {
            key: 'changes',
            label: 'Changes',
            sortable: true,
          },
          {
            key: 'commits',
            label: 'Commits',
            sortable: true,
          },
          {
            key: 'commitHours',
            label: 'CommitHours',
            sortable: true,
          },
          {
            key: 'date',
            label: 'Last commit',
            sortable: true,
          },
          {
            key: 'labels',
            label: 'Labels',
            sortable: true,
          },
        ],
      }
    },
    computed: {
      getItemsByAuthor() {
        const result = []
        this.items.forEach((item) => {
          if (item.author === this.author) {
            result.push(item)
          }
        })
        return result
      },
    },
    watch: {
      period(value) {
        this.updateRoute()
        this.loadData()
      },
      author(value) {
        this.updateRoute()
      },
    },
    mounted() {
      this.loadAuthors()
      this.loadData()
    },
    methods: {
      loadAuthors() {
        this.$store.commit('setLoading', true)
        http
          .get('/gitlab/users')
          .then((response) => {
            this.authors = []
            response.data.data.forEach((user) => {
              this.authors.push(user.email)
            })
            this.$store.commit('setLoading', false)
          })
          .catch((error) => {
            console.error('loadAuthors:', error)
            this.$store.commit('setLoading', false)
          })
      },
      loadData() {
        if (!this.period.from || !this.period.to) {
          this.items = []
          return
        }
        this.$store.commit('setLoading', true)
        const period = this.period ? '?from=' + this.period.from + '&to=' + this.period.to : ''
        http
          .get('/gitlab/issue-activity' + period)
          .then((response) => {
            this.items = response.data.data || []
            this.items.forEach((item) => {
              if (!item.issueTitle) {
                item.issueTitle = item.issueNumber
              }
            })
            this.$store.commit('setLoading', false)
          })
          .catch((error) => {
            console.error('loadData:', error)
            this.$store.commit('setLoading', false)
          })
      },
      updateRoute() {
        const params = { author: this.author, from: this.period.from, to: this.period.to }
        this.$router.push({ name: 'IssueActivityDetailsPerPerson', params })
      },
    },
  }
</script>

<style scoped lang="sass">
  .toolbar
    margin-bottom: 20px
</style>
