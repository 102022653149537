export const numberFormat = (number, decimals = 2, decimalSeparator = '.', thounsandsSeparator = ' ') => {
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thounsandsSeparator === 'undefined' ? ',' : thounsandsSeparator
  const dec = typeof decimalSeparator === 'undefined' ? '.' : decimalSeparator
  const toFixedFix = (n, prec) => {
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    const k = Math.pow(10, prec)
    return Math.round(n * k) / k
  }
  const s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}
