<template>
  <div class="row todo-overview-container">
    <div class="col-2">
      <div class="todo-sidebar-left">
        <todo-side-bar-left :boards="boards" @update="onUpdate"></todo-side-bar-left>
      </div>
    </div>
    <div class="col-10">
      <div class="todo-main">
        <h1>{{ title }}</h1>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Tasks</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="board in boardsByType" :key="board.id" :class="'board-status-' + board.status">
              <td>
                <router-link :to="{ name: 'Todo', params: { type: 'board', id: board.id } }">
                  {{ board.id }}
                </router-link>
              </td>
              <td>{{ board.name }}</td>
              <td>{{ board.type }}</td>
              <td class="status">{{ board.status }}</td>
              <td>{{ board.count || '0' }}</td>
              <td>{{ board.createdAt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import TodoSideBarLeft from '../components/TodoSideBarLeft'
  import backend from '@/utils/TaskBackend'

  export default {
    components: {
      TodoSideBarLeft,
    },
    data() {
      return {
        isLoading: false,
        boards: [],
      }
    },
    computed: {
      type() {
        return this.$route.query && this.$route.query.type ? this.$route.query.type : ''
      },
      title() {
        switch (this.type) {
          case 'sprint':
            return 'All sprints'
          case 'week':
            return 'All weeks'
          case 'era':
            return 'All eras'
          default:
            return 'All boards'
        }
      },
      boardsByType() {
        const result = []
        for (let i = 0; i < this.boards.length; i++) {
          const b = this.boards[i]
          if (!this.type || b.type === this.type) {
            result.push(b)
          }
        }
        return result
      },
    },
    watch: {
      $route: {
        handler() {
          this.load()
        },
        deep: true,
      },
    },
    mounted() {
      this.load()
    },
    methods: {
      load() {
        this.isLoading = true
        backend.getBoards().then(
          (boards) => {
            this.boards = boards
            this.isLoading = false
          },
          (error) => {
            this.error = error
            this.isLoading = false
          },
        )
      },
    },
  }
</script>

<style lang="sass" scoped>
  .board-status-open td.status
    color: green
  .board-status-closed td.status
    color: red
</style>
