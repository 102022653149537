<template>
  <div>
    <h3 :style="color ? 'background: ' + color : ''">
      <a @click="toggle()">
        <font-awesome-icon :icon="internal ? 'caret-left' : 'caret-down'"></font-awesome-icon>
      </a>
      <span>
        {{ title }}
      </span>
    </h3>
  </div>
</template>

<script>
  export default {
    props: ['title', 'value', 'color'],
    data() {
      return {
        internal: false,
      }
    },
    watch: {
      value() {
        this.updateFromExternal()
      },
    },
    mounted() {
      this.updateFromExternal()
    },
    methods: {
      updateFromExternal() {
        this.internal = this.value
      },
      toggle() {
        this.internal = !this.internal
        this.$emit('input', this.internal)
      },
    },
  }
</script>

<style lang="sass" scoped>
  h3
    margin: 10px 0 20px 0
    text-align: center
    text-transform: uppercase
    font-weight: bold
    font-size: 14px
    line-height: 40px
    background: #647a90
    color: white
  span
    margin-right: -40px
  a
    float: right
    display: inline-block
    width: 30px
    line-height: 30px
    margin-top: 5px
    margin-right: 10px
    border-radius: 5px
    &:hover
      background: #849ab0
</style>
