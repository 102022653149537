<template>
  <div>
    <input
      ref="input"
      v-model="internalValue"
      class="input"
      type="text"
      :placeholder="placeholderOrDefault"
      @focusout="update"
      @keyup.enter="onEnter"
    />
  </div>
</template>

<script>
  export default {
    props: ['value', 'placeholder', 'clearOnBlur'],
    data() {
      return {
        internalValue: '',
        ctrl: false,
      }
    },
    computed: {
      placeholderOrDefault() {
        return this.placeholder || ''
      },
    },
    watch: {
      value() {
        this.internalValue = this.value
      },
    },
    mounted() {
      this.internalValue = this.value
    },
    methods: {
      update() {
        if (this.internalValue !== this.value) {
          this.$emit('input', this.internalValue, this.ctrl)
          if (this.clearOnBlur) {
            this.internalValue = ''
          }
        }
        this.ctrl = false
      },
      onEnter(ev) {
        this.ctrl = ev.ctrlKey
        this.$refs.input.blur()
        this.$emit('enter', this.$refs.input)
      },
    },
  }
</script>

<style lang="sass" scoped>
</style>
