<template>
  <div class="front-page container-fluid">
    <div class="row">
      <div class="col col-lg-12">
        <b-form-textarea
          id="query-input"
          v-model="query"
          placeholder="Enter query... Ctrl+Enter to submit, Ctrl+Backspace to clear"
          :rows="3"
          @keyup.native.ctrl.enter="submit"
          @keyup.native.ctrl.backspace="reset"
        >
        </b-form-textarea>
      </div>
    </div>

    <div v-show="error != ''" class="mt-4 alert alert-danger">
      {{ error }}
    </div>

    <div class="mt-4">
      <b-form-checkbox v-model="striped">Striped</b-form-checkbox>
      <b-form-checkbox v-model="bordered">Bordered</b-form-checkbox>
      <b-form-checkbox v-model="outlined">Outlined</b-form-checkbox>
      <b-form-checkbox v-model="small">Small</b-form-checkbox>
      <b-form-checkbox v-model="stacked">Stacked</b-form-checkbox>
      <b-form-checkbox v-model="hover">Hover</b-form-checkbox>
    </div>

    <div class="mt-4 row">
      <div class="col col-lg-3">
        <b-form-input v-model="savePresetName" type="text" placeholder="Save as name"></b-form-input>
      </div>
      <div class="col col-lg-3">
        <b-button variant="primary" @click="savePreset">Save</b-button>
      </div>
      <div class="col col-lg-3">
        <b-form-select v-model="loadPresetId" :options="loadPresetOptions" class="mb-3" />
      </div>
      <div class="col col-lg-3">
        <b-button variant="primary" @click="loadPreset">Load</b-button>
      </div>
    </div>

    <div class="mt-4 table-container">
      <b-table
        :hover="hover"
        :striped="striped"
        :bordered="bordered"
        :outlined="outlined"
        :small="small"
        :stacked="stacked"
        :items="items"
      >
      </b-table>
    </div>
  </div>
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'FrontPage',
    data() {
      return {
        query: '',
        error: '',
        items: [],
        hover: true,
        striped: false,
        bordered: false,
        outlined: false,
        small: false,
        stacked: false,
        savePresetName: '',
        loadPresetOptions: [],
        loadPresetId: null,
      }
    },
    mounted() {
      this.loadPresets()
    },
    methods: {
      reset() {
        this.query = ''
        this.error = ''
        this.items = []
      },
      submit() {
        console.log('submit:')
        this.error = ''
        this.items = []
        const query = this.query.trim()
        if (!query) {
          return
        }
        http
          .get('/query?query=' + encodeURIComponent(query))
          .then((response) => {
            console.log('resp:', response)
            this.items = response.data.data
          })
          .catch((error) => {
            console.error('err:', error)
            this.error = '' + error
          })
      },
      savePreset() {
        this.error = ''
        http
          .post('/reports', this.toPreset())
          .then((response) => {
            console.log('LOAD.resp:', response)
            this.loadPresets()
            const id = response.data.data._id
            this.loadPresetId = id
          })
          .catch((error) => {
            this.error = '' + error
            console.error('LOAD.err:', error)
          })
      },
      loadPreset() {
        this.error = ''
        http
          .get('/reports/' + this.loadPresetId)
          .then((response) => {
            console.log('LOAD.resp:', response)
            this.fromPreset(response.data.data)
            this.submit()
          })
          .catch((error) => {
            this.error = '' + error
            console.error('LOAD.err:', error)
          })
      },
      loadPresets() {
        this.presets = []
        http
          .get('/reports')
          .then((response) => {
            const presets = []
            for (let i = 0; i < response.data.data.length; i++) {
              presets[presets.length] = {
                value: response.data.data[i]._id,
                text: response.data.data[i].name,
              }
            }
            this.loadPresetOptions = presets
          })
          .catch((error) => {
            this.error = '' + error
          })
      },
      toPreset() {
        return {
          name: this.savePresetName,
          query: this.query,
          tableOptions: {
            hover: this.hover,
            striped: this.striped,
            bordered: this.bordered,
            outlined: this.outlined,
            small: !!this.small,
            stacked: this.stacked,
          },
        }
      },
      fromPreset(preset) {
        this.savePresetName = preset.name
        this.query = preset.query
        const opt = preset.table_options
        this.hover = opt.hover
        this.striped = opt.striped
        this.bordered = opt.bordered
        this.outlined = opt.outlined
        this.small = !!opt.small
        this.stacked = opt.stacked
      },
    },
  }
</script>

<style>
  .table-container td {
    white-space: nowrap;
  }
</style>
