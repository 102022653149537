<template>
  <div class="card">
    <div class="card-header">
      <div class="card-actions">
        <a :class="isToday ? { active: true } : {}" @click="isToday = true">today</a>
        <a :class="!isToday ? { active: true } : {}" @click="isToday = false">tomorrow</a>
      </div>
      Google Calendar
    </div>
    <div class="card-body">
      <div>
        <div v-for="event in filterEvents(events)" :key="event.id">
          <div class="row">
            <div class="col col-lg-2">
              {{ formatStartTime(event.start) }}
            </div>
            <div class="col col-lg-10">
              <a target="gcal" :href="event.url">
                {{ event.summary }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '@/utils/http'
  import moment from 'moment'

  export default {
    name: 'GoogleCalendarSoon',
    data() {
      return {
        isToday: true,
        events: [],
      }
    },
    mounted() {
      http
        .get('/google-calendar/soon')
        .then((response) => {
          this.events = response.data.data || []
        })
        .catch((error) => {
          console.error('get events', error)
        })
    },
    methods: {
      formatStartTime(t) {
        const m = moment(t)
        return m.format('HH:mm')
      },
      filterEvents(events) {
        const result = []
        for (let i = 0; i < events.length; i++) {
          const isToday = events[i].day === 'today'
          if (isToday === this.isToday) {
            result.push(events[i])
          }
        }
        return result
      },
    },
  }
</script>

<style scoped lang="sass">
  .card-body
    padding: 10px
  .card-actions
    float: right
    a
      color: #007bff
      &.active
        text-decoration: underline
      &:hover
        color: #007bff
        cursor: pointer
</style>
