<template>
  <div class="container-fluid">
    <!-- Error -->
    <div v-show="error" style="color: red">
      {{ error }}
    </div>

    <!-- Filter and prev/next -->

    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col">
            <b-checkbox v-model="filter.solved">Solved</b-checkbox>
          </div>
          <div class="col">
            <b-checkbox v-model="filter.skipNoise">SkipNoise</b-checkbox>
          </div>
          <div class="col">
            <b-checkbox v-model="filter.skipDone">SkipDone</b-checkbox>
          </div>
          <div class="col">
            <b-btn @click="loadTickets()">Load</b-btn>
          </div>
        </div>
      </div>
      <div class="col-3">
        <a class="link" @click="gotoPrevTicket()">Prev</a>
        |
        <a class="link" @click="gotoNextTicket()">Next</a>
      </div>
      <div class="col-3">
        <a class="link" @click="docReset()">Reset</a>
        |
        <a class="link" @click="docAuto()">Auto</a>
        |
        <a class="link" @click="docSave()">Save</a>
        <span v-show="savedAt" class="saved-at">Saved at: {{ savedAt }}</span>
      </div>
    </div>

    <!-- List of tickets -->

    <div class="row">
      <div :class="ticket ? 'col-6' : 'col-12'">
        <table class="table table-bordered table-condensed">
          <thead>
            <tr>
              <th>Ticket</th>
              <th>Date</th>
              <th>Status</th>
              <th>Type</th>
              <th>DocClass</th>
              <th>Subject</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ticket in tickets" :key="ticket._id" :class="getRowClass(ticket)">
              <td>
                <a class="link" @click="clickTicket(ticket)">
                  {{ ticket.external_id }}
                </a>
              </td>
              <td class="date">{{ formatDate(ticket.created_at) }}</td>
              <td>{{ ticket.status }}</td>
              <td>{{ ticket.type }}</td>
              <td>{{ ticket.doc_class }}</td>
              <td>
                <a class="link" @click="clickTicket(ticket)">
                  {{ ticket.subject }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Ticket -->

      <div v-if="ticket" class="col-6">
        <!-- Ticket subject -->

        <div class="ticket-subject">
          {{ ticket.subject }}
        </div>

        <!-- Ticket fields -->

        <div class="row ticket-fields">
          <div class="col-sm-1">
            <a :href="getTicketUrl(ticket)" target="_blank">
              {{ ticket.external_id }}
            </a>
          </div>
          <div class="col-sm-1">{{ ticket.status }}</div>
          <div class="col-sm-1">{{ ticket.type }}</div>
          <div class="col-sm-1">{{ getContactFirstName(ticket.responder_id) }}</div>
          <div class="col-sm-1">{{ ticket.spam ? 'Spam' : 'Not spam' }}</div>
          <div class="col-sm-7">
            <a
              v-for="cat in doc_classes"
              :key="cat"
              :class="getDocClass(ticket, cat)"
              @click="setDocClass(ticket, cat)"
            >
              {{ cat }}
            </a>
          </div>
        </div>

        <!-- Question answer inputs -->

        <div v-show="!isCurrentDocClassNoise()">
          <div class="ticket-heading">Question</div>
          <b-form-textarea v-model="ticket.doc_question" rows="8"></b-form-textarea>
        </div>
        <div v-show="!isCurrentDocClassNoise()">
          <div class="ticket-heading">Answer</div>
          <b-form-textarea v-model="ticket.doc_answer" rows="8"></b-form-textarea>
        </div>

        <!-- Ticket initial message -->

        <div style="margin-top: 15px; padding-top: 15px; border-top: 1px solid #ccc">
          <div class="from-addr"> From: {{ getContactEmail(ticket.requester_id) }} </div>
          <div class="text-body" v-html="textToHtml(ticket.description)"> </div>
        </div>

        <!-- Ticket conversations -->

        <div
          v-for="conversation in conversations"
          :key="conversation._id"
          style="margin-top: 15px; padding-top: 15px; border-top: 1px solid #ccc"
        >
          <div class="conversation-actions">
            <a class="link" @click="setAnswer(conversation)">SetAnswer</a>
          </div>
          <div class="from-addr"> From: {{ getContactEmail(conversation.user_id) }} </div>
          <div class="text-body" v-html="textToHtml(conversation.body)"> </div>
        </div>

        <!-- Ticket debug -->

        <div style="margin-top: 15px; padding-top: 15px; border-top: 1px solid #ccc; margin-bottom: 15px">
          <a @click="isDebugVisible = !isDebugVisible"> Toggle debug </a>
        </div>
        <div v-show="isDebugVisible">
          <pre>ticket: {{ ticket }}</pre>
          <pre>conv: {{ conversations }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '@/utils/http'
  import moment from 'moment'

  export default {
    components: {},
    data() {
      return {
        error: '',
        filter: {
          solved: true,
          skipNoise: true,
          skipDone: true,
        },
        isDebugVisible: false,
        companies: [],
        contacts: [],
        tickets: [],
        ticket: null,
        conversations: [],
        doc_classes: ['supplier', 'data', 'feature', 'incident', 'noise', 'noinfo', 'question'],
        savedAt: '',
        savedAtTimeout: null,
      }
    },
    watch: {
      $route: {
        handler() {
          this.onChangeTicket()
        },
        deep: true,
      },
    },
    mounted() {
      this.loadCompanies(() => {
        this.loadContacts(() => {
          this.loadTickets(() => {
            this.onChangeTicket()
          })
        })
      })
    },
    methods: {
      loadContacts(callback = null) {
        http
          .get('/freshdesk/contacts')
          .then((response) => {
            this.contacts = response.data.data || []
            if (callback) {
              callback()
            }
          })
          .catch((err) => {
            this.error = 'Error fetching data: ' + err
          })
      },
      loadCompanies(callback = null) {
        http
          .get('/freshdesk/companies')
          .then((response) => {
            this.companies = response.data.data || []
            if (callback) {
              callback()
            }
          })
          .catch((err) => {
            this.error = 'Error fetching data: ' + err
          })
      },
      loadTickets(callback = null) {
        let query = '?'
        if (this.filter.solved !== null) {
          query += '&solved=' + (this.filter.solved ? '1' : '0')
        }
        if (this.filter.skipNoise !== null) {
          query += '&skipNoise=' + (this.filter.skipNoise ? '1' : '0')
        }
        if (this.filter.skipDone !== null) {
          query += '&skipDone=' + (this.filter.skipDone ? '1' : '0')
        }
        const url = '/freshdesk/tickets?' + query
        console.log('url: ' + url)
        http
          .get(url)
          .then((response) => {
            this.tickets = response.data.data || []
            if (callback) {
              callback()
            }
            /*
            if (this.ticket) {
              this.ticket = this.tickets[0]
            } else {
              this.ticket = null
            }
            */
          })
          .catch((err) => {
            this.error = 'Error fetching data: ' + err
          })
      },
      loadTicketConversations(ticketId) {
        this.conversations = []
        const url = '/freshdesk/tickets/' + ticketId + '/conversations'
        http
          .get(url)
          .then((response) => {
            this.conversations = response.data.data || []
          })
          .catch((err) => {
            this.error = 'Error fetching data: ' + err
          })
      },
      setTicket(ticket) {
        this.$router.push({
          name: 'FreshdeskDoc',
          query: { ticket: ticket.external_id },
        })
      },
      clickTicket(ticket) {
        this.setTicket(ticket)
      },
      onChangeTicket() {
        if (this.$route.query.ticket) {
          for (let i = 0; i < this.tickets.length; i++) {
            if (this.tickets[i].external_id === this.$route.query.ticket) {
              this.ticket = this.tickets[i]
              this.loadTicketConversations(this.ticket._id)
              return
            }
          }
        }
        this.ticket = null
        this.conversations = []
      },
      gotoNextTicket() {
        let index = null
        if (this.ticket) {
          for (let i = 0; i < this.tickets.length; i++) {
            if (this.tickets[i]._id === this.ticket._id) {
              index = i
              break
            }
          }
        } else {
          this.setTicket(this.tickets[0])
          return
        }
        if (index !== null && this.tickets[index + 1]) {
          this.setTicket(this.tickets[index + 1])
        }
      },
      gotoPrevTicket() {
        let index = null
        if (this.ticket) {
          for (let i = 0; i < this.tickets.length; i++) {
            if (this.tickets[i]._id === this.ticket._id) {
              index = i
              break
            }
          }
        } else {
          this.setTicket(this.tickets[0])
          return
        }
        if (index !== null && this.tickets[index - 1]) {
          this.setTicket(this.tickets[index - 1])
        }
      },
      getRowClass(ticket) {
        if (this.ticket && ticket._id === this.ticket._id) {
          return 'selected-ticket'
        } else {
          return ''
        }
      },
      getCompanyName(externalId) {
        for (let i = 0; i < this.companies.length; i++) {
          if (this.companies[i].external_id === externalId) {
            return this.companies[i].name
          }
        }
        return ''
      },
      getContactName(externalId) {
        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i].external_id === externalId) {
            return this.contacts[i].name
          }
        }
        return ''
      },
      getContactEmail(externalId) {
        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i].external_id === externalId) {
            return this.contacts[i].email
          }
        }
        return ''
      },
      getContactFirstName(externalId) {
        const name = this.getContactName(externalId)
        if (!name) {
          return ''
        }
        const parts = name.split(' ')
        return parts[0]
      },
      getTicketUrl(ticket) {
        return 'https://eontyre.freshdesk.com/a/tickets/' + ticket.external_id
      },
      getDocClass(ticket, category) {
        if (ticket.doc_class === category) {
          return 'link badge badge-success'
        }
        return 'link badge badge-secondary'
      },
      isCurrentDocClassNoise() {
        return this.isDocClassNoise(this.ticket.doc_class)
      },
      isDocClassNoise(category) {
        return category !== 'question'
      },
      setDocClass(ticket, category) {
        ticket.doc_class = category
        if (this.isDocClassNoise(category)) {
          ticket.doc_question = ''
          ticket.doc_answer = ''
        }
        this.docSave()
      },
      setAnswer(conversation) {
        this.ticket.doc_answer = conversation.body
      },
      docReset() {
        const ticket = this.ticket
        if (!ticket) {
          return
        }
        ticket.doc_class = ''
        ticket.doc_question = ''
        ticket.doc_answer = ''
      },
      docAuto() {
        const ticket = this.ticket
        if (!ticket) {
          return
        }
        ticket.doc_class = 'question'
        ticket.doc_question = ticket.description
        let lastConversationBody = ''
        for (let i = 0; i < this.conversations.length; i++) {
          if (!this.conversations[i].private && !this.conversations[i].incoming) {
            lastConversationBody = this.conversations[i].body
          }
        }
        ticket.doc_answer = lastConversationBody
        this.$forceUpdate()
      },
      docSave() {
        const ticket = this.ticket
        if (!ticket) {
          return
        }
        if (this.savedAtTimeout) {
          clearTimeout(this.savedAtTimeout)
        }
        this.savedAt = ''
        http
          .post('/freshdesk/tickets/' + ticket._id + '/doc-save', {
            doc_class: ticket.doc_class,
            doc_question: ticket.doc_question,
            doc_answer: ticket.doc_answer,
          })
          .then((response) => {
            this.savedAt = moment().format('HH:mm:ss')
            this.savedAtTimeout = setTimeout(() => {
              this.savedAt = ''
            }, 5000)
          })
          .catch((err) => {
            this.error = 'Error saving data: ' + err
          })
      },
      textToHtml(text) {
        return text.split('\n').join('<br/>')
      },
      formatDate(value) {
        if (!value) {
          return ''
        }
        return value.split(' ')[0].split('T')[0]
      },
    },
  }
</script>

<style scoped>
  .link:hover {
    cursor: pointer;
  }
  .ticket-heading,
  .ticket-subject,
  tr.selected-ticket td {
    background: #eef;
  }
  .ticket-subject {
    padding: 10px;
    font-weight: bold;
  }
  .ticket-heading {
    padding: 10px;
    margin-top: 10px;
  }
  .ticket-fields {
    margin-top: 10px;
  }
  .from-addr {
    color: #999;
    padding-bottom: 5px;
  }
  .text-body {
  }
  .link.badge {
    font-size: 100%;
    margin-right: 8px;
  }
  .saved-at {
    margin-left: 10px;
    color: #0a0;
    font-weight: bold;
  }
  .date {
    white-space: nowrap;
  }
  .conversation-actions {
    float: right;
  }
</style>
