<template>
  <div class="periods-container">
    <input v-model="from" type="text" placeholder="From date" class="date-input form-control" />
    <input v-model="to" type="text" placeholder="To date" class="date-input form-control" />
    <div class="links">
      <a :class="{ active: isPeriod('day') }" @click="setPeriod('day')">Day</a>
      <a :class="{ active: isPeriod('week') }" @click="setPeriod('week')">Week</a>
      <a :class="{ active: isPeriod('month') }" @click="setPeriod('month')">Month</a>
    </div>
    <div class="links">
      <a @click="prevPeriod()">Prev</a>
      <a :class="{ active: periodOffset === 0 }" @click="currentPeriod()">Cur</a>
      <a @click="nextPeriod()">Next</a>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'PeriodSelector',
    props: ['value'],
    data() {
      return {
        from: '',
        to: '',
        period: '',
        periodOffset: 0,
      }
    },
    watch: {
      from() {
        this.$emit('input', { from: this.from, to: this.to })
      },
      to() {
        this.$emit('input', { from: this.from, to: this.to })
      },
    },
    mounted() {
      this.from = this.value ? this.value.from : ''
      this.to = this.value ? this.value.to : ''
    },
    methods: {
      isPeriod(value) {
        return this.period === value
      },
      setPeriod(value) {
        this.period = value
        this.periodOffset = 0
        this.updatePeriod()
      },
      nextPeriod() {
        this.periodOffset += 1
        this.updatePeriod()
      },
      prevPeriod() {
        this.periodOffset -= 1
        this.updatePeriod()
      },
      currentPeriod() {
        this.periodOffset = 0
        this.updatePeriod()
      },
      offsetPeriod(m) {
        if (this.periodOffset > 0) {
          m = m.add(this.periodOffset, this.period + 's')
        } else if (this.periodOffset < 0) {
          m = m.subtract(0 - this.periodOffset, this.period + 's')
        }
        return m
      },
      updatePeriod() {
        let from, to
        switch (this.period) {
          case 'day':
            from = moment()
            to = moment()
            break
          case 'week':
            from = moment().startOf('isoWeek')
            to = moment().endOf('isoWeek')
            break
          case 'month':
            from = moment().startOf('month')
            to = moment().endOf('month')
            break
        }
        const format = 'YYYY-MM-DD'
        this.from = this.offsetPeriod(from).format(format)
        this.to = this.offsetPeriod(to).format(format)
      },
    },
  }
</script>

<style lang="sass">
  .periods-container
    display: flex
  .date-input
    width: 130px
    margin-right: 10px
    display: flex
    margin-bottom: 0
  .links
    display: flex
    line-height: calc(2.25rem + 2px)
    margin-left: 10px
    a
      cursor: pointer
      padding-right: 6px
      &.active
        text-decoration: underline
</style>
