<template>
  <div class="container-fluid">
    <h1> Accounts </h1>

    <div>
      <table class="table table-condensed">
        <thead>
          <tr>
            <th>Account</th>
            <th>Type</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in data" :key="account.number">
            <td>
              {{ account.number }}
            </td>
            <td>{{ account.type }}</td>
            <td>{{ account.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .front-page container-fluid -->
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'FinanceAccounts',
    components: {},
    data() {
      return {
        data: [],
        activeAccount: null,
      }
    },
    mounted() {
      const url = '/finance/accounts'
      http
        .get(url)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          this.error = 'Error fetching data: ' + err
        })
    },
  }
</script>

<style scoped>
</style>
