<template>
  <div>
    <div class="section">
      <todo-side-bar-header
        v-model="collapsed.completePerAssignee"
        title="Progress"
        :color="completeTitleColor"
      ></todo-side-bar-header>
      <div v-show="!collapsed.completePerAssignee" class="inner">
        <div v-for="alloc in completePerAssignee" :key="alloc.id">
          <todo-alloc-progress :data="alloc" suffix="tasks" color-scheme="done"></todo-alloc-progress>
        </div>
      </div>
    </div>

    <div class="section">
      <todo-side-bar-header
        v-model="collapsed.problems"
        title="Problems"
        :color="allocTitleColor"
      ></todo-side-bar-header>
      <div v-show="!collapsed.problems" class="inner">
        <div v-if="hasProblems">
          <div v-for="problem in problems" :key="problem.id" class="problem">
            <div class="name">{{ problem.name }}</div>
            <div class="count">{{ problem.count }}</div>
          </div>
        </div>
        <div v-if="!hasProblems"> No problems :) </div>
      </div>
    </div>

    <div class="section">
      <todo-side-bar-header
        v-model="collapsed.allocationPerAssignee"
        title="Allocation per dev"
        :color="allocTitleColor"
      ></todo-side-bar-header>
      <div v-show="!collapsed.allocationPerAssignee" class="inner">
        <div v-for="alloc in allocationPerAssignee" :key="alloc.id">
          <todo-alloc-progress :data="alloc" suffix="hours" color-scheme="allocation"></todo-alloc-progress>
        </div>
      </div>
    </div>

    <div class="section">
      <todo-side-bar-header
        v-model="collapsed.allocationPerTrack"
        title="Allocation per track"
        :color="allocTitleColor"
      ></todo-side-bar-header>
      <div v-show="!collapsed.allocationPerTrack" class="inner">
        <div v-for="alloc in allocationPerTrack" :key="alloc.id">
          <todo-alloc-progress
            :data="alloc"
            :suffix="isTrackAllocationHours ? 'hours' : '%'"
            color-scheme="allocation"
          ></todo-alloc-progress>
        </div>
        <div>
          <b-checkbox v-model="isTrackAllocationHours">As hours</b-checkbox>
        </div>
      </div>
    </div>

    <div class="section">
      <todo-side-bar-header
        v-model="collapsed.allocationPerWorkspace"
        title="Allocation per WS"
        :color="allocTitleColor"
      ></todo-side-bar-header>
      <div v-show="!collapsed.allocationPerWorkspace" class="inner">
        <div v-for="alloc in allocationPerWorkspace" :key="alloc.id">
          <todo-alloc-progress :data="alloc" :suffix="'hours'" color-scheme="allocation"></todo-alloc-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TodoSideBarHeader from '../components/TodoSideBarHeader'
  import TodoAllocProgress from '../components/TodoAllocProgress'
  import { parseEstimate } from '@/utils/estimate'

  export default {
    components: {
      TodoSideBarHeader,
      TodoAllocProgress,
    },
    props: ['board', 'assignees', 'tracks', 'assigneeCapacity', 'trackCapacity', 'customers', 'workspaces'],
    data() {
      return {
        collapsed: {
          completePerAssignee: false,
          allocationPerAssignee: false,
          allocationPerTrack: false,
          allocationPerWorkspace: false,
          problems: false,
        },
        isTrackAllocationHours: true,
        completeTitleColor: '#5aad5f',
        allocTitleColor: '#ba8dbf',
      }
    },
    computed: {
      groups() {
        return this.board && this.board.groups ? this.board.groups : []
      },
      activeWorkspaceIds() {
        const result = {}
        for (let i = 0; i < this.tasks.length; i++) {
          const customer = this.getCustomer(this.tasks[i].customerId)
          if (customer) {
            result[customer.workspaceId] = 1
          }
        }
        return Object.keys(result).map((v) => parseInt(v))
      },
      activeWorkspaces() {
        const result = []
        const ids = this.activeWorkspaceIds
        for (let i = 0; i < this.workspaces.length; i++) {
          if (ids.indexOf(this.workspaces[i].id) !== -1) {
            result.push(this.workspaces[i])
          }
        }
        return result
      },
      tasks() {
        const result = []
        if (this.groups) {
          for (let i = 0; i < this.groups.length; i++) {
            if (this.groups[i].tasks) {
              for (let j = 0; j < this.groups[i].tasks.length; j++) {
                result.push(this.groups[i].tasks[j])
              }
            }
          }
        }
        return result
      },
      allocationPerAssignee() {
        const data = this.sumEstimatesBy('assigneeId')
        // todo: per workspace
        const multiplier = this.board.weeks || 1
        const cap = this.makeCapacity(this.assigneeCapacity, 'assigneeId', multiplier)
        return this.makeProgressItems(data, this.assignees, cap, true)
      },
      allocationPerWorkspace() {
        const data = this.sumEstimatesByWorkspace()
        const multiplier = this.board.weeks || 1
        return this.makeProgressItems(data, this.activeWorkspaces, this.getCapacitiesByWorkspace(multiplier), false)
      },
      allocationPerTrack() {
        let data = []
        let capacities = []
        const total = this.sumEstimatesTotal()
        if (!this.isTrackAllocationHours) {
          // Capacities are in percent, estimates are in hours,
          // if showing alloc-per-track as percent, keep capacities as-is:
          capacities = this.trackCapacity
          // and convert hours into percentages of total:
          const tmp = this.sumEstimatesBy('trackId')
          for (const key in tmp) {
            const item = {}
            Object.assign(item, tmp[key])
            item.value = Math.round((item.value / total) * 100)
            data[key] = item
          }
          // console.log('pct-mode - estimates:', data, 'caps:', capacities)
        } else {
          // Capacities are in percent, estimates are in hours,
          // if showing alloc-per-track as hours, keep estimates as-is:
          data = this.sumEstimatesBy('trackId')
          // and convert capacities to hours:
          const totalHours = this.sumAssigneeHoursTotal()
          for (let i = 0; i < this.trackCapacity.length; i++) {
            const cap = {}
            Object.assign(cap, this.trackCapacity[i])
            cap.capacity = Math.round((cap.capacity / 100) * totalHours)
            capacities.push(cap)
          }
          // console.log('hour-mode - estimates:', data, 'caps:', capacities)
        }
        // todo: per workspace
        const cap = this.makeCapacity(capacities, 'trackId', 1)
        return this.makeProgressItems(data, this.tracks, cap, false)
      },
      completePerAssignee() {
        const data = this.sumCompleteBy('assigneeId')
        return this.makeProgressItems(data, this.assignees, null, true)
      },
      hasProblems() {
        return this.problems.length > 0
      },
      problems() {
        const problems = {}
        const tasks = this.tasks
        for (let i = 0; i < tasks.length; i++) {
          const task = tasks[i]

          // Estimate
          if (!task.estimate) {
            this.addProblem(problems, 'EstimateMissing')
          } else {
            const estimate = parseEstimate(task.estimate)
            if (!estimate) {
              this.addProblem(problems, 'EstimateInvalid')
            }
          }

          // Assignee
          if (!task.assigneeId) {
            this.addProblem(problems, 'AssigneeMissing')
          }

          // Track
          if (!task.trackId) {
            this.addProblem(problems, 'TrackMissing')
          }

          // Customer
          if (!task.customerId) {
            this.addProblem(problems, 'CustomerMissing')
          }
        }

        // Sort & return

        const result = []
        for (const key in problems) {
          result.push({
            id: key,
            name: key,
            count: problems[key],
          })
        }

        result.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })

        return result
      },
    },
    methods: {
      addProblem(problems, key) {
        if (!problems[key]) {
          problems[key] = 0
        }
        problems[key]++
      },
      getCapacitiesByWorkspace(multiplier) {
        const result = []
        for (let i = 0; i < this.assigneeCapacity.length; i++) {
          const cap = this.assigneeCapacity[i]
          if (!result[cap.workspaceId]) {
            result[cap.workspaceId] = 0
          }
          result[cap.workspaceId] += cap.capacity * multiplier
        }
        return result
      },
      makeCapacity(data, field, multiplier) {
        const workspaceIds = this.activeWorkspaceIds
        const result = {}
        for (let i = 0; i < data.length; i++) {
          const workspaceId = data[i].workspaceId
          if (!workspaceId) {
            console.error('capacity-item has no workspaceId:', data[i])
            continue
          }
          if (workspaceIds.indexOf(workspaceId) === -1) {
            // consider only items from workspaces from which we have tasks in this board
            continue
          }
          const key = data[i][field]
          if (!result[key]) {
            result[key] = 0
          }
          result[key] += (parseFloat(data[i].capacity) || 0.0) * multiplier
        }
        return result
      },
      makeProgressItems(estimates, enums, capacities, hideUndef) {
        const result = []
        for (let i = 0; i < enums.length; i++) {
          const e = enums[i]
          const item = {
            id: e.id,
            name: e.name,
            value: 0,
            max: capacities && capacities[e.id] ? capacities[e.id] : 0,
          }
          let isUndef = true
          for (const key in estimates) {
            if (e.id === parseInt(key)) {
              item.value = estimates[key].value || 0
              if (!capacities) {
                item.max = estimates[key].max || 0
              }
              isUndef = false
              break
            }
          }
          if (!isUndef || !hideUndef) {
            result.push(item)
          }
        }
        return result
      },
      sumAssigneeHoursTotal() {
        let result = 0
        for (let i = 0; i < this.assigneeCapacity.length; i++) {
          result += this.assigneeCapacity[i].capacity
        }
        return result
      },
      sumEstimatesTotal() {
        let result = 0
        const tasks = this.tasks
        for (let i = 0; i < tasks.length; i++) {
          const task = tasks[i]
          result += parseEstimate(tasks[i].estimate)
        }
        return result
      },
      sumEstimatesByWorkspace() {
        const tasks = this.tasks
        const data = {}
        for (let i = 0; i < tasks.length; i++) {
          const task = tasks[i]
          const customer = this.getCustomer(task.customerId)
          if (!customer || !customer.workspaceId) {
            continue
          }
          const key = customer.workspaceId
          const estimate = parseEstimate(tasks[i].estimate)
          if (!estimate) {
            continue
          }
          if (!data[key]) {
            data[key] = { value: 0 }
          }
          data[key].value += estimate
        }
        return data
      },
      sumEstimatesBy(field) {
        const tasks = this.tasks
        const data = {}
        for (let i = 0; i < tasks.length; i++) {
          const task = tasks[i]
          const key = task[field]
          if (!key) {
            continue
          }
          const estimate = parseEstimate(tasks[i].estimate)
          if (!estimate) {
            continue
          }
          if (!data[key]) {
            data[key] = { value: 0 }
          }
          data[key].value += estimate
        }
        return data
      },
      sumCompleteBy(field) {
        const tasks = this.tasks
        const data = {}
        for (let i = 0; i < tasks.length; i++) {
          const task = tasks[i]
          const key = task[field]
          if (!key) {
            continue
          }
          const status = task.status ? task.status.toLowerCase() : ''
          const isDone = status === 'done' || status === 'skip' || status === 'closed'
          if (!data[key]) {
            data[key] = { value: 0, max: 0 }
          }
          data[key].max += 1
          data[key].value += isDone ? 1 : 0
        }
        return data
      },
      getCustomer(id) {
        if (!id) {
          return null
        }
        for (let i = 0; i < this.customers.length; i++) {
          if (this.customers[i].id === id) {
            return this.customers[i]
          }
        }
        return null
      },
    },
  }
</script>

<style lang="sass" scoped>
  .inner
    margin: 20px
  .problem
    display: flex
    color: red
    margin-bottom: 5px
    .name
      flex-grow: 1
    .count:
      width: 80px
</style>
