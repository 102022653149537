<template>
  <div class="container-fluid">
    <h1> Postings </h1>

    <div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Entity</th>
            <th>Filter</th>
            <th class="number">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entity in entities" :key="entity">
            <td>{{ entity.name }}</td>
            <td>
              <label>
                <input v-model="filterEntity" type="radio" name="filterEntity" :value="entity.name" />
                Filter
              </label>
            </td>
            <td class="number">{{ formatAmount(entity.amount) }}</td>
          </tr>
          <tr class="sum">
            <td>SUM</td>
            <td>
              <label>
                <input v-model="filterEntity" type="radio" name="filterEntity" value="" />
                No filter
              </label>
            </td>
            <td class="number">{{ formatAmount(getTotal()) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Account</th>
            <th>Date</th>
            <th>Voucher</th>
            <th>Entity</th>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in listData" :key="item">
            <td>
              {{ item.account }}
            </td>
            <td>
              {{ item.date }}
            </td>
            <td>
              {{ item.voucher_number }}
            </td>
            <td>
              {{ item.supplier_or_customer_name }}
            </td>
            <td>{{ item.description }}</td>
            <td class="number">{{ item.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .front-page container-fluid -->
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'FinancePostings',
    components: {},
    data() {
      return {
        data: [],
        filterEntity: '',
      }
    },
    computed: {
      entities() {
        if (!this.data || !this.data.length) {
          return []
        }
        const result = {}
        for (let i = 0; i < this.data.length; i++) {
          const item = this.data[i]
          const key = item.supplier_or_customer_name || 'Other'
          if (!result[key]) {
            result[key] = {
              name: key,
              amount: 0,
            }
          }
          result[key].amount += parseFloat(item.amount)
        }
        const response = []
        for (const key in result) {
          response.push(result[key])
        }
        return response
      },
      listData() {
        const result = []
        for (let i = 0; i < this.data.length; i++) {
          if (!this.filterEntity || this.filterEntity === this.data[i].supplier_or_customer_name) {
            const item = this.data[i]
            if (!item.supplier_or_customer_name) {
              item.supplier_or_customer_name = 'Other'
            }
            result.push(item)
          }
        }
        return result
      },
    },
    mounted() {
      const year = parseInt(this.$route.params.year)
      const month = parseInt(this.$route.params.month)
      const account = parseInt(this.$route.params.account)
      const url = '/finance/postings?year=' + year + '&month=' + month + '&account=' + account
      http
        .get(url)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          this.error = 'Error fetching data: ' + err
        })
    },
    methods: {
      getTotal() {
        let sum = 0
        for (let i = 0; i < this.data.length; i++) {
          sum += parseFloat(this.data[i].amount)
        }
        return sum
      },
      formatAmount(value) {
        return Math.round(value)
      },
    },
  }
</script>

<style scoped lang="sass">
  .number
    text-align: right

  tr.sum td
    border-top: 2px solid black
    border-bottom: 6px double black
</style>
