<template>
  <div class="container-fluid">
    <div v-show="error" class="alert alert-danger">
      {{ error }}
    </div>

    <h1>
      {{ query }}
    </h1>

    <div style="margin-bottom: 10px">
      Count: {{ count }}
      <span class="separator">|</span>
      Average: {{ average }}
      <span class="separator">|</span>
      Period: {{ startDate }} - {{ endDate }}
    </div>

    <div>
      <table class="table table-condensed">
        <thead>
          <tr>
            <th>ID</th>
            <th>Subject</th>
            <th>Created</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ticket in data" :key="ticket._id">
            <td>{{ ticket._id }}</td>
            <td>{{ ticket.subject }}</td>
            <td class="number">{{ ticket.created_at }}</td>
            <td class="number">{{ ticket.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .front-page container-fluid -->
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'TicketsList',
    components: {},
    data() {
      return {
        query: this.$route.params.query,
        start: this.$route.params.start,
        end: this.$route.params.end,
        data: [],
        error: '',
      }
    },
    computed: {
      count() {
        return this.data ? this.data.length : 0
      },
      average() {
        let sum = 0
        let count = 0
        if (this.data && this.data.length) {
          for (let i = 0; i < this.data.length; i++) {
            sum += this.data[i].value ? parseInt(this.data[i].value) : 0
            count++
          }
        }
        if (count === 0) {
          return 0
        }
        return Math.round(sum / count)
      },
      startDate() {
        return this.start.split(' ')[0]
      },
      endDate() {
        return this.end.split(' ')[0]
      },
    },
    mounted() {
      const url =
        '/tickets/stats/list/' +
        this.query +
        '?start=' +
        encodeURIComponent(this.start) +
        '&end=' +
        encodeURIComponent(this.end)
      http
        .get(url)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          this.error = 'Error fetching data: ' + err
        })
    },
  }
</script>

<style scoped>
  td.number {
    text-align: right;
    white-space: nowrap;
  }
  .separator {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    color: #999;
  }
</style>
