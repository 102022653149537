<template>
  <div class="front-page container-fluid">
    <div class="row">
      <div class="col col-lg-6">
        <gitlab-merge-requests></gitlab-merge-requests>
        <gitlab-todos></gitlab-todos>
      </div>
      <div class="col col-lg-6">
        <google-calendar-soon></google-calendar-soon>
        <gmail-mail-counters></gmail-mail-counters>

        <div class="card">
          <div class="card-header"> Links </div>
          <ul>
            <li>
              <a href="https://docs.google.com/document/d/1P8Y1ExncrRP-wS79buYhVqBATnsqzJNXQ0-HGTJ64fA/edit">
                Daily checklist
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/document/d/1qPMhy_dXcpSaviW6To4Ke6p6WQthuA6Jyx7gDruqvYk/edit">
                Monthly checklist
              </a>
            </li>
            <li>
              <a href="https://eon.groovehq.com"> Groove </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MergeRequests from '@/components/Gitlab/MergeRequests.vue'
  import Todos from '@/components/Gitlab/Todos.vue'
  import MailCounters from '@/components/Gmail/MailCounters.vue'
  import Soon from '@/components/GoogleCalendar/Soon.vue'

  export default {
    name: 'AttentionOverview',
    components: {
      'gitlab-merge-requests': MergeRequests,
      'gitlab-todos': Todos,
      'gmail-mail-counters': MailCounters,
      'google-calendar-soon': Soon,
    },
  }
</script>

<style scoped lang="sass">
  .card
    margin-bottom: 30px
    ul
      margin-top: 20px
</style>
