<template>
  <div class="card">
    <div class="card-header">
      <div class="card-actions">
        <a :class="isThreads ? { active: true } : {}" @click="setIsThreads(true)">threads</a>
        <a :class="!isThreads ? { active: true } : {}" @click="setIsThreads(false)">messages</a>
      </div>
      Mail counters
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col col-lg-2"> Today </div>
        <div class="col col-lg-3 number">
          <a :href="today.received_url" target="_gmail"> {{ getReceivedCount(today) }} received </a>
        </div>
        <div class="col col-lg-3 number">
          <a :href="today.replied_url" target="_gmail"> {{ getRepliedCount(today) }} replied </a>
        </div>
        <div class="col col-lg-4 number">
          <a :href="today.received_still_in_inbox_url" target="_gmail">
            {{ getReceivedStillInInboxCount(today) }} still in inbox
          </a>
        </div>
        <!--
        <div :class="getDiffClass(today)">
          {{ getDiffCount(today) }} diff
        </div>
-->
      </div>

      <div class="row">
        <div class="col col-lg-2"> Yesterday </div>
        <div class="col col-lg-3 number">
          <a :href="yesterday.received_url" target="_gmail"> {{ getReceivedCount(yesterday) }} received </a>
        </div>
        <div class="col col-lg-3 number">
          <a :href="yesterday.replied_url" target="_gmail"> {{ getRepliedCount(yesterday) }} replied </a>
        </div>
        <!--
        <div :class="getDiffClass(yesterday)">
          {{ getDiffCount(yesterday) }} diff
        </div>
-->
        <div class="col col-lg-4 number">
          <a :href="yesterday.received_still_in_inbox_url" target="_gmail">
            {{ getReceivedStillInInboxCount(yesterday) }} still in inbox
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-2"> INBOX </div>
        <div class="col col-lg-3 number">
          {{ isThreads ? today.inbox_threads_total_count : today.inbox_messages_total_count }} total
        </div>
        <div class="col col-lg-3 number">
          {{ isThreads ? today.inbox_threads_unread_count : today.inbox_messages_unread_count }} unread
        </div>
        <div class="col col-lg-4 number">
          <!-- Reminder that synapse script is removing INBOX label
               for messages in CATEGORY_XXXs except CATEGORY_PERSONAL
               (if age is 1-3 days depending on type of category).
               Should in future be a configurable and this display
               should reflect that config. -->
          Auto-skip inbox is ON
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'GmailMailCounters',
    data() {
      return {
        isThreads: false,
        today: {},
        yesterday: {},
      }
    },
    mounted() {
      http
        .get('/gmail/counters')
        .then((response) => {
          const data = response.data.data || {}
          this.today = data.today || {}
          this.yesterday = data.yesterday || {}
        })
        .catch((error) => {
          console.error('get counters', error)
        })
      this.isThreads = localStorage.getItem('synapse-mailcounters-isthreads') === '1'
    },
    methods: {
      getReceivedCount(data) {
        return this.isThreads ? data.received_threads_count : data.received_messages_count
      },
      getReceivedStillInInboxCount(data) {
        return this.isThreads ? data.received_still_in_inbox_threads_count : data.received_still_in_inbox_messages_count
      },
      getRepliedCount(data) {
        return this.isThreads ? data.replied_threads_count : data.replied_messages_count
      },
      getDiffCount(data) {
        const diff = this.getRepliedCount(data) - this.getReceivedCount(data)
        if (diff > 0) {
          return '+' + diff
        } else {
          return diff
        }
      },
      getDiffClass(data) {
        const classes = {
          col: true,
          'col-lg-4': true,
          number: true,
        }
        const diff = this.getRepliedCount(data) - this.getReceivedCount(data)
        if (diff < 0) {
          classes['diff-bad'] = true
        } else {
          classes['diff-good'] = true
        }
        return classes
      },
      setIsThreads(value) {
        this.isThreads = value
        localStorage.setItem('synapse-mailcounters-isthreads', this.isThreads ? '1' : '')
      },
    },
  }
</script>

<style scoped lang="sass">
  .number
    text-align: right
  .card-body
    padding: 10px
  .card-actions
    float: right
    a
      color: #007bff
      &.active
        text-decoration: underline
      &:hover
        color: #007bff
        cursor: pointer
  .diff-bad
    color: red
    font-weight: bold
  .diff-good
    color: green
    font-weight: bold
</style>
