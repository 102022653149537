<template>
  <div>
    <div class="row toolbar">
      <div class="col-md-3">
        <b-form-select v-model="sizeType" :options="sizeTypes" />
      </div>
      <div class="col-md-6">
        <period-selector v-model="period"></period-selector>
      </div>
    </div>

    <div class="row">
      <div v-for="author in authors" :key="author" class="col">
        <div class="card">
          <div class="card-header">
            <router-link :to="{ name: 'IssueActivityDetailsPerPerson', params: { author: author } }">
              {{ author }}
            </router-link>
          </div>
          <div class="card-body">
            <div
              v-for="item in getItemsByAuthor(author)"
              :key="item.issueNumber"
              :style="getSizeStyle(item)"
              class="item"
            >
              {{ item.issueTitle || item.issueNumber }}
              <span v-for="label in item.labels" :key="label" class="badge badge-secondary">
                {{ label }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from '@/utils/http'
  import PeriodSelector from '@/components/PeriodSelector'

  export default {
    components: {
      'period-selector': PeriodSelector,
    },
    data() {
      return {
        period: {
          from: this.$route.params.from,
          to: this.$route.params.to,
        },
        authors: [],
        items: [],
        sizeType: 'commitHours',
        sizeTypes: ['commits', 'commitHours', 'commitDates', 'changes'],
      }
    },
    watch: {
      period(_value) {
        this.updateRoute()
        this.load()
      },
    },
    mounted() {
      this.load()
    },
    methods: {
      load() {
        if (!this.period.from || !this.period.to) {
          this.items = []
          return
        }
        this.$store.commit('setLoading', true)
        const period = this.period ? '?from=' + this.period.from + '&to=' + this.period.to : ''
        http
          .get('/gitlab/issue-activity' + period)
          .then((response) => {
            this.authors = []
            this.items = response.data.data || []
            this.items.forEach((item) => {
              if (this.authors.indexOf(item.author) === -1) {
                this.authors.push(item.author)
              }
            })
            this.$store.commit('setLoading', false)
          })
          .catch((error) => {
            console.error('load:', error)
            this.$store.commit('setLoading', false)
          })
      },
      updateRoute() {
        const params = { author: this.author, from: this.period.from, to: this.period.to }
        this.$router.push({ name: 'IssueActivityCloudPerPerson', params })
      },
      getItemsByAuthor(author) {
        const result = []
        this.items.forEach((item) => {
          if (item.author === author) {
            result.push(item)
          }
        })
        result.sort((a, b) => {
          return this.getSizeNormalized(a) > this.getSizeNormalized(b) ? -1 : 1
        })
        return result
      },
      getSizeReal(item) {
        return item[this.sizeType] || 0
      },
      getSizeNormalized(item) {
        let size = this.getSizeReal(item)
        let divisor = 1
        let base = 12
        switch (this.sizeType) {
          case 'commits':
            divisor = 1.5
            break
          case 'commitHours':
            divisor = 1
            break
          case 'commitDates':
            divisor = 0.5
            break
          case 'changes':
            divisor = 40
            base = 8
            break
        }
        size = size / divisor + base
        if (size > 50) {
          size = 50
        }
        return size
      },
      getSizeStyle(item) {
        const size = this.getSizeNormalized(item)
        return { 'font-size': size + 'px' }
      },
    },
  }
</script>

<style lang="sass" scoped>
  .toolbar
    margin-bottom: 20px

  .item
    margin-bottom: 15px
    line-height: 1.1
</style>
