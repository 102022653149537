<template>
  <div class="dash-card-value" :class="sizeClass">
    <div class="value" :class="statusClass">{{ displayValue }}</div>
    <div class="subtext">{{ subtext || 'No subtext' }}</div>
  </div>
</template>

<script>

  import {numberFormat} from '@/utils/numberFormat'

  export default {
    name: 'DashCardValue',
    components: {},
    props: ['value', 'subtext', 'status', 'small', 'isTodo', 'thresholds', 'thresholdIncreasing', 'format'],
    data() {
      return {}
    },
    computed: {
      sizeClass() {
        const suffix = this.isTodo ? ' todo' : ''
        return (this.small ? 'size-small' : 'size-normal') + suffix
      },
      statusClass() {
        let status = this.status
        if (this.thresholds) {
          status = this.getThreshold(this.value, this.thresholds, this.thresholdIncreasing)
        }
        return 'status-' + status
      },
      displayValue() {
        switch (this.format) {
          case 'kr':
            return numberFormat(this.value, 0, '.', ' ')
          default:
            return this.value
        }
      },
    },
    methods: {
      getThreshold(value, thresholds, increasing) {
        if (increasing) {
          if (typeof thresholds === 'object') {
            if (value <= thresholds[0]) {
              return 'critical'
            } else if (value <= thresholds[1]) {
              return 'warning'
            } else {
              return 'ok'
            }
          } else {
            if (value >= thresholds) {
              return 'ok'
            } else {
              return 'critical'
            }
          }
        } else {
          if (typeof thresholds === 'object') {
            if (value <= thresholds[0]) {
              return 'ok'
            } else if (value <= thresholds[1]) {
              return 'warning'
            } else {
              return 'critical'
            }
          } else {
            if (value >= thresholds) {
              return 'critical'
            } else {
              return 'ok'
            }
          }
        }
      },
    }
  }
</script>

<style scoped lang="sass">
  .dash-card-value
    margin-top: 15px
    margin-bottom: 15px
    &.todo
      background: yellow

  .value
    font-size: 30px
    text-align: center

  .subtext
    font-size: 12px
    color: #888
    text-align: center

  .status-ok
    color: green

  .status-warning
    color: orange

  .status-critical
    color: red

  .size-small
    .value
      font-size: 22px
</style>
