<template>
  <div id="app">
    <div v-show="!loggedIn">
      <form @submit.prevent="login">
        Email:
        <input v-model="email" type="text" />
        Password:
        <input v-model="password" type="password" />
        <b-button type="submit">Submit</b-button>
        <span v-show="message">{{ message }}</span>
      </form>
    </div>

    <div v-show="loggedIn">
      <b-navbar toggleable="md" type="dark" variant="dark" fixed="top">

        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-navbar-brand :to="{ name: 'FrontPage' }">Synapse</b-navbar-brand>

        <b-collapse id="nav_collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item :to="{ name: 'Todo', params: { type: 'day', id: 'latest' } }"> Todo </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav>
            <b-nav-item-dropdown text="Tasks">
              <b-dropdown-item :to="{ name: 'TodoOld', params: { section: 'special:sprint', mode: 'todos' } }">
                Todo Old
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'TodoReasonCodes' }">Reason Codes</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav>
            <b-nav-item :to="{ name: 'IssueActivityCloudPerPerson', params: { from: 'none', to: 'none' } }">
              Activity
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav>
            <b-nav-item :to="{ name: 'Dashboard'}">
              Dashboard
            </b-nav-item>
            <b-nav-item :to="{ name: 'DashboardFull'}">
              DashboardFull
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav>
            <b-nav-item-dropdown text="Tickets">
              <b-dropdown-item :to="{ name: 'TicketsOverview' }">Overview</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'TicketsUser' }">User</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'FreshdeskDoc' }">FreshdeskDoc</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav>
            <b-nav-item-dropdown text="Finance">
              <b-dropdown-item :to="{ name: 'FinanceBudget' }">Budget</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'FinanceAccounts' }">Accounts</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav>
            <b-nav-item :to="{ name: 'Reports' }">Reports</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav>
            <b-nav-item @click="logout">Logout</b-nav-item>
          </b-navbar-nav>

          <!-- todo: right align spinner icon -->
          <b-navbar-nav class="mr-auto">
            <div v-show="loading" style="color: white">Loading...</div>
          </b-navbar-nav>

          <div class="remember-image">
            <img src="./assets/remember.png"/>
          </div>

        </b-collapse>
      </b-navbar>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'App',
    data() {
      return {
        loggedIn: false,
        email: '',
        password: '',
        message: '',
      }
    },
    computed: {
      loading() {
        return this.$store.state.loading
      },
    },
    mounted() {
      if (http.getUser()) {
        this.loggedIn = true
      }
    },
    methods: {
      login() {
        this.message = ''
        http
          .post('/user/login', { email: this.email, password: this.password })
          .then((response) => {
            const data = response.data.data
            if (data && data.token) {
              http.setUser(data)
              this.loggedIn = true
            }
            this.message = 'Logged in'
          })
          .catch((err) => {
            this.message =
              err && err.response && err.response.data && err.response.data.error
                ? err.response.data.error
                : 'Login failed'
          })
      },
      logout() {
        http.setUser(null)
        this.loggedIn = false
      },
    },
  }
</script>

<style lang="sass">
  #app
    padding-top: 80px
    font-size: 0.9rem
</style>
