<template>
  <div class="alloc-container">
    <div class="label">
      <div class="name">
        {{ data.name }}
      </div>
      <div class="value"> {{ data.value }} / {{ data.max }}{{ optionalSuffix }}</div>
    </div>
    <div class="progress">
      <div :style="progressStyle">&nbsp;</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['data', 'suffix', 'colorScheme'],
    computed: {
      optionalSuffix() {
        return this.suffix ? ' ' + this.suffix : ''
      },
      percentage() {
        return Math.round((parseFloat(this.data.value) / parseFloat(this.data.max)) * 100)
      },
      progressColor() {
        switch (this.colorScheme) {
          case 'allocation':
            if (this.percentage > 150) {
              return 'darkred'
            } else if (this.percentage > 101) {
              return 'red'
            } else if (this.percentage > 90) {
              return 'orange'
            } else if (this.percentage > 25) {
              return 'green'
            } else {
              return '#007bff'
            }
          case 'done':
            return 'green'
          default:
            return 'blue'
        }
      },
      progressStyle() {
        return 'background: ' + this.progressColor + '; width: ' + this.percentage + '%'
      },
    },
  }
</script>

<style lang="sass" scoped>
  .alloc-container
    margin-bottom: 20px
  .label
    display: flex
    margin-bottom: 5px
    .name
      flex-grow: 1
    .value
      width: 100px
      text-align: right
</style>
