<template>
  <div class="container-fluid">
    <h1> Transactions </h1>

    <div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Category</th>
            <th>Filter</th>
            <th class="number">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="category in categories" :key="category._id">
            <td>{{ category.name }}</td>
            <td>
              <label>
                <input v-model="filterCategory" type="radio" name="filterCategory" :value="category.name" />
                Filter
              </label>
            </td>
            <td class="number">{{ formatAmount(category.amount) }}</td>
          </tr>
          <tr class="sum">
            <td>SUM</td>
            <td>
              <label>
                <input v-model="filterCategory" type="radio" name="filterCategory" value="" />
                No filter
              </label>
            </td>
            <td class="number">{{ formatAmount(getTotal()) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>InvoiceDate</th>
            <th>InvoiceNumber</th>
            <th>Customer</th>
            <th>Subscription</th>
            <th>Category</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>UnitPrice</th>
            <th>Discount</th>
            <th>Sum</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in listData" :key="row.invoice_number">
            <td>
              {{ row.invoice_date }}
            </td>
            <td class="number">
              {{ row.invoice_number }}
            </td>
            <td class="nowrap"> {{ row.customer_name }} ({{ row.customer_id }}) </td>
            <td>
              {{ parseInt(row.is_subscription) ? 'Subscription' : 'Variable' }}
            </td>
            <td>
              {{ row.category }}
            </td>
            <td>
              {{ getText(row) }}
            </td>
            <td class="number">
              {{ row.quantity }}
            </td>
            <td class="number">
              {{ formatAmount(row.unit_price) }}
            </td>
            <td class="number">
              {{ formatAmount(row.discount_percent) }}
            </td>
            <td class="number">
              {{ formatAmount(row.amount) }}
            </td>
          </tr>
          <tr class="sum">
            <td>SUM</td>
            <td colspan="8">&nbsp;</td>
            <td class="number">{{ formatAmount(getListTotal()) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .front-page container-fluid -->
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'FinanceTransactionsIncome',
    components: {},
    data() {
      return {
        data: [],
        filterCategory: '',
      }
    },
    computed: {
      categories() {
        if (!this.data || !this.data.length) {
          return []
        }
        const result = {}
        for (let i = 0; i < this.data.length; i++) {
          const item = this.data[i]
          if (!result[item.category]) {
            result[item.category] = {
              name: item.category,
              amount: 0,
            }
          }
          result[item.category].amount += parseFloat(item.amount)
        }
        const response = []
        for (const key in result) {
          response.push(result[key])
        }
        return response
      },
      listData() {
        if (!this.filterCategory) {
          return this.data
        }
        const result = []
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].category === this.filterCategory) {
            result.push(this.data[i])
          }
        }
        return result
      },
    },
    mounted() {
      const year = parseInt(this.$route.params.year)
      const month = parseInt(this.$route.params.month)
      const url = '/finance/transactions?type=income&year=' + year + '&month= ' + month
      http
        .get(url)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          this.error = 'Error fetching data: ' + err
        })
    },
    methods: {
      getText(row) {
        let text = row.product_name
        if (row.description) {
          text += ' - ' + row.description
        }
        return text
      },
      getTotal() {
        let sum = 0
        for (let i = 0; i < this.data.length; i++) {
          sum += parseFloat(this.data[i].amount)
        }
        return sum
      },
      getListTotal() {
        let sum = 0
        for (let i = 0; i < this.listData.length; i++) {
          sum += parseFloat(this.listData[i].amount)
        }
        return sum
      },
      formatAmount(value) {
        return Math.round(value)
      },
    },
  }
</script>

<style scoped lang="sass">
  .number
    text-align: right
  tr.sum td
    border-top: 2px solid black
    border-bottom: 6px double black
  .nowrap
    white-space: nowrap
</style>
