<template>
  <div :class="{ 'show-breakdown': showBreakDown, colorized: isColorized }" class="container-fluid">
    <h1> Budget </h1>

    <div class="settings-bar">
      <label>
        <input v-model="showAmount" type="checkbox" />
        Amount
      </label>
      <label>
        <input v-model="showBudget" type="checkbox" />
        Budget
      </label>
      <label>
        <input v-model="showDiff" type="checkbox" />
        Diff
      </label>
      <label>
        <input v-model="showBreakDown" type="checkbox" />
        Breakdown
      </label>
      <label>
        <input v-model="isColorized" type="checkbox" />
        Colorized
      </label>
      <label>
        <input v-model="isThousands" type="checkbox" />
        Thousands
      </label>
      <label>
        <input v-model="editBudget" type="checkbox" />
        Edit budget
      </label>
      <span>
        Periods:
        <select v-model="period" class="form-control">
          <option value="all">All</option>
          <option value="year">Year</option>
          <option value="average">Average</option>
          <option value="yearAndAverage">Year + Average</option>
          <option value="monthsAndYear">Months + Year</option>
          <option value="monthsAndAverage">Months + Average</option>
          <option value="1">Jan</option>
          <option value="2">Feb</option>
          <option value="3">Mar</option>
          <option value="4">Apr</option>
          <option value="5">May</option>
          <option value="6">Jun</option>
          <option value="7">Jul</option>
          <option value="8">Aug</option>
          <option value="9">Sep</option>
          <option value="10">Oct</option>
          <option value="11">Nov</option>
          <option value="12">Dec</option>
        </select>
      </span>
      <span>
        Year:
        <input v-model="year" class="form-control year-input" type="text" />
      </span>
      <span>
        <select v-model="accountId" class="form-control">
          <option value="2">EONBIT</option>
          <option value="3">Haha</option>
        </select>
      </span>
      <span>
        Budget:
        <select v-model="budgetId" class="form-control">
          <option v-for="b in budgets" :key="b._id" :value="b._id">{{ b.name }}</option>
        </select>
      </span>
      <span>
        <button class="btn btn-primary" @click="clickUpdate">Update</button>
      </span>
      <span v-show="editBudget">
        BudgetName:
        <input v-model="budgetName" class="form-control" type="text" />
      </span>
      <span v-show="editBudget">
        <button class="btn btn-danger" @click="saveBudget">Save budget</button>
      </span>
      <span v-show="isLoading"> Loading... </span>
    </div>

    <table v-if="data" class="table table-bordered">
      <thead>
        <!-- Months -->
        <tr class="months">
          <th class="label">&nbsp;</th>
          <th v-for="month in visibleMonths()" :key="month.number" :colspan="colspan">
            {{ getMonthName(month.number) }}
          </th>
          <th v-if="isAverageVisible()" :colspan="colspan"> Average</th>
          <th v-if="isYearVisible()" :colspan="colspan"> Year</th>
        </tr>

        <!-- Sub headers for values -->
        <tr v-if="colspan > 1" class="values">
          <th class="label">&nbsp;</th>
          <template v-for="month in visibleMonths()">
            <th v-show="showAmount" :key="'th-amount-' + month.number" class="amount">Actual</th>
            <th v-show="showBudget" :key="'th-budget-' + month.number" class="budget">Budget</th>
            <th v-show="showDiff" :key="'th-diff-' + month.number" class="diff">Diff</th>
          </template>
          <template v-if="isAverageVisible()">
            <th v-show="showAmount" class="amount">Actual</th>
            <th v-show="showBudget" class="budget">Budget</th>
            <th v-show="showDiff" class="diff">Diff</th>
          </template>
          <template v-if="isYearVisible()">
            <th v-show="showAmount" class="amount">Actual</th>
            <th v-show="showBudget" class="budget">Budget</th>
            <th v-show="showDiff" class="diff">Diff</th>
          </template>
        </tr>
      </thead>

      <tbody>
        <!-- INCOME BREAKDOWN -->
        <tr v-for="group in getVisibleIncomeGroups()" :key="group.category" class="breakdown">
          <td class="label">{{ group.category }}</td>
          <template v-for="month in visibleMonths()">
            <td v-show="showAmount" :key="'td-amount-' + month.number" class="monthly income amount">
              {{ formatAmount(getMonthGroupValue(month, group, 'amount')) }}
            </td>
            <td v-show="showBudget" :key="'td-budget-' + month.number" class="monthly income budget">
              <input
                v-show="editBudget"
                v-model="getMonthGroup(month, group, true).budget"
                class="budget-value-input form-control"
                type="text"
              />
              <a
                v-show="editBudget && month.number === 1"
                class="copy-budget-link"
                tabindex="0"
                @click="copyBudget(month.number, group.category)"
              >
                Copy
              </a>
              <span v-show="!editBudget">{{ formatAmount(getMonthGroupValue(month, group, 'budget')) }}</span>
            </td>
            <td
              v-show="showDiff"
              :key="'td-diff-' + month.number"
              :class="getDiffClass(getMonthGroupBudgetDiff(month, group))"
              class="monthly income diff"
            >
              {{ formatAmount(getMonthGroupBudgetDiff(month, group)) }}
            </td>
          </template>
          <template v-if="isAverageVisible()">
            <td v-show="showAmount" class="monthly income amount">
              {{ formatAmount(getMonthGroupValue(average, group, 'amount')) }}
            </td>
            <td v-show="showBudget" class="monthly income budget">
              {{ formatAmount(getMonthGroupValue(average, group, 'budget')) }}
            </td>
            <td
              v-show="showDiff"
              :class="getDiffClass(getMonthGroupBudgetDiff(data.total, group))"
              class="monthly income diff"
            >
              {{ formatAmount(getMonthGroupBudgetDiff(average, group)) }}
            </td>
          </template>
          <template v-if="isYearVisible()">
            <td v-show="showAmount" class="monthly income amount">
              {{ formatAmount(getMonthGroupValue(data.total, group, 'amount')) }}
            </td>
            <td v-show="showBudget" class="monthly income budget">
              {{ formatAmount(getMonthGroupValue(data.total, group, 'budget')) }}
            </td>
            <td
              v-show="showDiff"
              :class="getDiffClass(getMonthGroupBudgetDiff(data.total, group))"
              class="monthly income diff"
            >
              {{ formatAmount(getMonthGroupBudgetDiff(data.total, group)) }}
            </td>
          </template>
        </tr>

        <!-- INCOME -->
        <tr class="sum">
          <td class="label">Income</td>
          <template v-for="month in visibleMonths()">
            <td v-show="showAmount" :key="'trs-amount-' + month.number" class="monthly income amount">
              <router-link :to="{ name: 'FinanceTransactionsIncome', params: { year: year, month: month.number } }">
                {{ formatAmount(month.incomeAmount) }}
              </router-link>
            </td>
            <td v-show="showBudget" :key="'trs-budget-' + month.number" class="monthly income budget">
              {{ formatAmount(month.incomeBudget) }}
            </td>
            <td
              v-show="showDiff"
              :key="'trs-diff-' + month.number"
              :class="getDiffClass(getIncomeBudgetDiff(month))"
              class="monthly income diff"
            >
              {{ formatAmount(getIncomeBudgetDiff(month)) }}
            </td>
          </template>
          <template v-if="isAverageVisible()">
            <td v-show="showAmount" class="total income amount">
              <router-link :to="{ name: 'FinanceTransactionsIncome', params: { year: year, month: '0' } }">
                {{ formatAmount(average.incomeAmount) }}
              </router-link>
            </td>
            <td v-show="showBudget" class="total income budget">
              {{ formatAmount(average.incomeBudget) }}
            </td>
            <td v-show="showDiff" :class="getDiffClass(getIncomeBudgetDiff(average))" class="total income diff">
              {{ formatAmount(getIncomeBudgetDiff(average)) }}
            </td>
          </template>
          <template v-if="isYearVisible()">
            <td v-show="showAmount" class="total income amount">
              <router-link :to="{ name: 'FinanceTransactionsIncome', params: { year: year, month: '0' } }">
                {{ formatAmount(data.total.incomeAmount) }}
              </router-link>
            </td>
            <td v-show="showBudget" class="total income budget">
              {{ formatAmount(data.total.incomeBudget) }}
            </td>
            <td v-show="showDiff" :class="getDiffClass(getIncomeBudgetDiff(data.total))" class="total income diff">
              {{ formatAmount(getIncomeBudgetDiff(data.total)) }}
            </td>
          </template>
        </tr>

        <!-- EXPENSE BREAKDOWN -->
        <tr v-for="group in getVisibleExpenseGroups()" :key="group.category" class="breakdown">
          <td class="label">{{ group.category }}</td>
          <template v-for="month in visibleMonths()">
            <td v-show="showAmount" :key="'trg-amount-' + month.number" class="monthly income amount">
              {{ formatAmount(getMonthGroupValue(month, group, 'amount')) }}
            </td>
            <td v-show="showBudget" :key="'trg-budget-' + month.number" class="monthly income budget">
              <input
                v-show="editBudget"
                v-model="getMonthGroup(month, group, true).budget"
                class="budget-value-input form-control"
                type="text"
              />
              <a
                v-show="editBudget && month.number === 1"
                class="copy-budget-link"
                tabindex="0"
                @click="copyBudget(month.number, group.category)"
              >
                Copy
              </a>
              <span v-show="!editBudget">{{ formatAmount(getMonthGroupValue(month, group, 'budget')) }}</span>
            </td>
            <td
              v-show="showDiff"
              :key="'trg-diff-' + month.number"
              :class="getDiffClass(getMonthGroupBudgetDiff(month, group))"
              class="monthly income diff"
            >
              {{ formatAmount(getMonthGroupBudgetDiff(month, group)) }}
            </td>
          </template>
          <template v-if="isAverageVisible()">
            <td v-show="showAmount" class="monthly income amount">
              {{ formatAmount(getMonthGroupValue(average, group, 'amount')) }}
            </td>
            <td v-show="showBudget" class="monthly income budget">
              {{ formatAmount(getMonthGroupValue(average, group, 'budget')) }}
            </td>
            <td
              v-show="showDiff"
              :class="getDiffClass(getMonthGroupBudgetDiff(average, group))"
              class="monthly income diff"
            >
              {{ formatAmount(getMonthGroupBudgetDiff(average, group)) }}
            </td>
          </template>
          <template v-if="isYearVisible()">
            <td v-show="showAmount" class="monthly income amount">
              {{ formatAmount(getMonthGroupValue(data.total, group, 'amount')) }}
            </td>
            <td v-show="showBudget" class="monthly income budget">
              {{ formatAmount(getMonthGroupValue(data.total, group, 'budget')) }}
            </td>
            <td
              v-show="showDiff"
              :class="getDiffClass(getMonthGroupBudgetDiff(data.total, group))"
              class="monthly income diff"
            >
              {{ formatAmount(getMonthGroupBudgetDiff(data.total, group)) }}
            </td>
          </template>
        </tr>

        <!-- EXPENSE -->
        <tr class="sum">
          <td class="label">Expense</td>
          <template v-for="month in visibleMonths()">
            <td v-show="showAmount" :key="'tre-amount-' + month.number" class="monthly expense amount">
              <router-link :to="{ name: 'FinanceTransactionsExpense', params: { year: year, month: month.number } }">
                {{ formatAmount(month.expenseAmount) }}
              </router-link>
            </td>
            <td v-show="showBudget" :key="'tre-budget-' + month.number" class="monthly expense budget">
              {{ formatAmount(month.expenseBudget) }}
            </td>
            <td
              v-show="showDiff"
              :key="'tre-diff-' + month.number"
              :class="getDiffClass(getExpenseBudgetDiff(month))"
              class="monthly expense diff"
            >
              {{ formatAmount(getExpenseBudgetDiff(month)) }}
            </td>
          </template>
          <template v-if="isAverageVisible()">
            <td v-show="showAmount" class="total expense amount">
              <router-link :to="{ name: 'FinanceTransactionsExpense', params: { year: year, month: '0' } }">
                {{ formatAmount(average.expenseAmount) }}
              </router-link>
            </td>
            <td v-show="showBudget" class="total expense budget">
              {{ formatAmount(average.expenseBudget) }}
            </td>
            <td v-show="showDiff" :class="getDiffClass(getExpenseBudgetDiff(average))" class="total expense diff">
              {{ formatAmount(getExpenseBudgetDiff(average)) }}
            </td>
          </template>
          <template v-if="isYearVisible()">
            <td v-show="showAmount" class="total expense amount">
              <router-link :to="{ name: 'FinanceTransactionsExpense', params: { year: year, month: '0' } }">
                {{ formatAmount(data.total.expenseAmount) }}
              </router-link>
            </td>
            <td v-show="showBudget" class="total expense budget">
              {{ formatAmount(data.total.expenseBudget) }}
            </td>
            <td v-show="showDiff" :class="getDiffClass(getExpenseBudgetDiff(data.total))" class="total expense diff">
              {{ formatAmount(getExpenseBudgetDiff(data.total)) }}
            </td>
          </template>
        </tr>

        <!-- RESULT -->
        <tr class="sum">
          <td class="label">Result</td>
          <template v-for="month in visibleMonths()">
            <td
              v-show="showAmount"
              :key="'tres-amount-' + month.number"
              :class="getDiffClass(getResultAmount(month))"
              class="monthly result amount"
            >
              {{ formatAmount(getResultAmount(month)) }}
            </td>
            <td v-show="showBudget" :key="'tres-budget-' + month.number" class="monthly result budget">
              {{ formatAmount(getResultBudget(month)) }}
            </td>
            <td
              v-show="showDiff"
              :key="'tres-diff-' + month.number"
              :class="getDiffClass(getResultBudgetDiff(month))"
              class="monthly result diff"
            >
              {{ formatAmount(getResultBudgetDiff(month)) }}
            </td>
          </template>
          <template v-if="isAverageVisible()">
            <td v-show="showAmount" :class="getDiffClass(getResultAmount(average))" class="total result amount">
              {{ formatAmount(getResultAmount(average)) }}
            </td>
            <td v-show="showBudget" class="total result budget">
              {{ formatAmount(getResultBudget(average)) }}
            </td>
            <td v-show="showDiff" :class="getDiffClass(getResultBudgetDiff(average))" class="total result diff">
              {{ formatAmount(getResultBudgetDiff(average)) }}
            </td>
          </template>
          <template v-if="isYearVisible()">
            <td v-show="showAmount" :class="getDiffClass(getResultAmount(data.total))" class="total result amount">
              {{ formatAmount(getResultAmount(data.total)) }}
            </td>
            <td v-show="showBudget" class="total result budget">
              {{ formatAmount(getResultBudget(data.total)) }}
            </td>
            <td v-show="showDiff" :class="getDiffClass(getResultBudgetDiff(data.total))" class="total result diff">
              {{ formatAmount(getResultBudgetDiff(data.total)) }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- .front-page container-fluid -->
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'FinanceBudget',
    components: {},
    data() {
      return {
        accountId: 2,
        data: {
          months: [],
          total: {},
        },
        budgets: [],
        showAmount: true,
        showBudget: true,
        showDiff: true,
        showBreakDown: false,
        editBudget: false,
        year: new Date().getFullYear(),
        budgetId: null,
        budgetName: '',
        period: 'all',
        isLoading: false,
        isColorized: false,
        isThousands: false,
      }
    },
    computed: {
      colspan() {
        const result = 0 + (this.showAmount ? 1 : 0) + (this.showBudget ? 1 : 0) + (this.showDiff ? 1 : 0)
        return result || 1
      },
      average() {
        if (!this.data || !this.data.months) {
          return {}
        }
        const months = this.data.months.length
        const result = {
          number: 0,
          expenseBudget: parseFloat(this.data.total.expenseBudget) / months,
          incomeBudget: parseFloat(this.data.total.incomeBudget) / months,
          expenseAmount: parseFloat(this.data.total.expenseAmount) / months,
          incomeAmount: parseFloat(this.data.total.incomeAmount) / months,
          groups: [],
        }
        if (this.data.total && this.data.total.groups) {
          for (let i = 0; i < this.data.total.groups.length; i++) {
            const groupA = this.data.total.groups[i]
            const groupB = {
              category: groupA.category,
              type: groupA.type,
              amount: parseFloat(groupA.amount) / months,
              budget: parseFloat(groupA.budget) / months,
            }
            result.groups.push(groupB)
          }
        }
        return result
      },
    },
    watch: {
      $route: {
        deep: true,
        options: { deep: true },
        handler() {
          this.onRouteChange()
        },
      },
      budgetId(value) {
        if (!this.budgets) {
          return
        }
        this.budgets.forEach((v) => {
          if (v._id === value) {
            this.budgetName = v.name
          }
        })
      },
    },
    mounted() {
      this.isLoading = true
      this.fetchBudgets(() => {
        this.onRouteChange()
      })
    },
    methods: {
      reload() {
        this.isLoading = true
        this.fetchBudgets(() => {
          this.fetchData()
        })
      },
      fetchBudgets(callback) {
        const url = '/finance/budgets'
        http
          .get(url)
          .then((response) => {
            this.budgets = response.data.data
            if (callback) {
              callback()
            }
          })
          .catch((err) => {
            this.error = 'Error fetching budgets: ' + err
          })
      },
      clickUpdate() {
        const settings = {
          showAmount: this.showAmount ? '1' : '',
          showBudget: this.showBudget ? '1' : '',
          showDiff: this.showDiff ? '1' : '',
          showBreakDown: this.showBreakDown ? '1' : '',
          editBudget: this.editBudget ? '1' : '',
          year: this.year || '',
          budgetId: this.budgetId || '',
          period: this.period || '',
          isColorized: this.isColorized ? '1' : '',
          isThousands: this.isThousands ? '1' : '',
          accountId: this.accountId || '',
        }
        this.navigate(settings)
      },
      navigate(query) {
        this.$router.push({
          name: 'FinanceBudget',
          query,
        })
      },
      onRouteChange() {
        const settings = this.$route.query || {}
        this.showAmount = settings.showAmount === '1' || settings.showAmount === undefined
        this.showBudget = settings.showBudget === '1'
        this.showDiff = settings.showDiff === '1'
        this.showBreakDown = settings.showBreakDown === '1'
        this.editBudget = settings.editBudget === '1'
        this.year = settings.year || this.getCurrentYear()
        this.budgetId = settings.budgetId || ''
        this.period = settings.period || 'monthsAndYear'
        this.isColorized = settings.isColorized === '1'
        this.isThousands = settings.isThousands === '1'
        this.accountId = parseInt(settings.accountId) || 2
        this.fetchData()
      },
      getCurrentYear() {
        const dt = new Date()
        return dt.getFullYear()
      },
      fetchData() {
        this.isLoading = true
        const url = '/finance/budget-result?year=' + this.year + '&budget=' + this.budgetId + '&accountId=' + this.accountId
        http
          .get(url)
          .then((response) => {
            this.isLoading = false
            this.data = response.data.data
            console.log('fetch', this.data)
          })
          .catch((err) => {
            this.isLoading = false
            this.error = 'Error fetching data: ' + err
          })
      },
      formatAmount(amount) {
        if (this.isThousands) {
          amount = amount / 1000
        }
        amount = Math.round(amount)
        const isNegative = amount < 0
        if (isNegative) {
          amount = 0 - amount
        }
        const rounded = '' + amount
        const chunks = []
        let chunk = ''
        for (let i = rounded.length - 1; i >= 0; i--) {
          chunk += rounded.substr(i, 1)
          if (chunk.length === 3) {
            chunks.push(chunk)
            chunk = ''
          }
        }
        if (chunk) {
          chunks.push(chunk)
        }
        const reversed = chunks.join(' ')
        let fixed = reversed.split('').reverse().join('')
        if (isNegative) {
          fixed = '- ' + fixed
        }
        return fixed
      },
      parseNumber(value) {
        return parseFloat(value) || 0
      },
      getIncomeBudgetDiff(month) {
        return this.parseNumber(month.incomeAmount) - this.parseNumber(month.incomeBudget)
      },
      getExpenseBudgetDiff(month) {
        return this.parseNumber(month.expenseAmount) - this.parseNumber(month.expenseBudget)
      },
      getResultAmount(month) {
        return this.parseNumber(month.incomeAmount) + this.parseNumber(month.expenseAmount)
      },
      getResultBudget(month) {
        return this.parseNumber(month.incomeBudget) + this.parseNumber(month.expenseBudget)
      },
      getResultBudgetDiff(month) {
        return this.getResultAmount(month) - this.getResultBudget(month)
      },
      getDiffClass(value) {
        if (this.isThousands) {
          value = Math.round(value / 1000)
        }
        if (value < 0) {
          return 'negative'
        } else {
          return 'positive'
        }
      },
      getMonthName(value) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        return months[parseInt(value) - 1]
      },
      visibleMonths() {
        const result = []
        for (let i = 0; i < this.data.months.length; i++) {
          if (this.isMonthVisible(this.data.months[i])) {
            result.push(this.data.months[i])
          }
        }
        return result
      },
      isMonthVisible(value) {
        console.log('per:', this.period)
        return (
          this.period === 'all' ||
          parseInt(this.period) === parseInt(value) ||
          this.period === 'monthsAndYear' ||
          this.period === 'monthsAndAverage'
        )
      },
      isYearVisible() {
        return (
          this.period === 'all' ||
          this.period === 'year' ||
          this.period === 'yearAndAverage' ||
          this.period === 'monthsAndYear'
        )
      },
      isAverageVisible() {
        return (
          this.period === 'all' ||
          this.period === 'average' ||
          this.period === 'yearAndAverage' ||
          this.period === 'monthsAndAverage'
        )
      },
      getVisibleIncomeGroups() {
        return this.showBreakDown ? this.getIncomeGroups() : []
      },
      getIncomeGroups() {
        const result = []
        if (this.data.total.groups) {
          for (let i = 0; i < this.data.total.groups.length; i++) {
            if (this.data.total.groups[i].type === 'Income') {
              result.push(this.data.total.groups[i])
            }
          }
        }
        return result
      },
      getVisibleExpenseGroups() {
        return this.showBreakDown ? this.getExpenseGroups() : []
      },
      getExpenseGroups() {
        const result = []
        if (this.data.total.groups) {
          for (let i = 0; i < this.data.total.groups.length; i++) {
            if (this.data.total.groups[i].type === 'Expense') {
              result.push(this.data.total.groups[i])
            }
          }
        }
        return result
      },
      getMonthGroup(month, group, ensure) {
        if (!month.groups) {
          if (ensure) {
            month.groups = []
          } else {
            return {}
          }
        }
        for (let i = 0; i < month.groups.length; i++) {
          if (month.groups[i].category === group.category) {
            return month.groups[i]
          }
        }
        if (ensure) {
          const g = {
            category: group.category,
            amount: '0',
            budget: '0',
            type: '',
          }
          month.groups.push(g)
          return g
        } else {
          return {}
        }
      },
      getMonthGroupValue(month, group, field) {
        return parseFloat(this.getMonthGroup(month, group)[field] || '0')
      },
      getMonthGroupBudgetDiff(month, group, _field) {
        const actual = this.getMonthGroupValue(month, group, 'amount')
        const budget = this.getMonthGroupValue(month, group, 'budget')
        return actual - budget
      },
      copyBudget(sourceMonthNumber, sourceGroupCategory) {
        let sourceMonth = null
        for (let i = 0; i < this.data.months.length; i++) {
          if (this.data.months[i].number === sourceMonthNumber) {
            sourceMonth = this.data.months[i]
            break
          }
        }
        if (!sourceMonth) {
          console.error('No such source month: ' + sourceMonthNumber)
          return
        }
        const sourceGroup = this.getMonthGroup(sourceMonth, { category: sourceGroupCategory })
        if (!sourceGroup) {
          console.error('No such source group: ' + sourceGroupCategory + ' for month: ' + sourceMonthNumber)
          return
        }
        for (let i = 0; i < this.data.months.length; i++) {
          if (this.data.months[i].number !== 1) {
            const targetGroup = this.getMonthGroup(this.data.months[i], sourceGroup, true)
            if (!targetGroup) {
              console.error('No target group for ' + this.data.months[i].number + ' : ' + sourceGroupCategory)
              continue
            }
            targetGroup.budget = sourceGroup.budget
          }
        }
      },
      saveBudget() {
        const params = {
          name: this.budgetName,
          months: [],
        }
        for (let i = 0; i < this.data.months.length; i++) {
          for (let j = 0; j < this.data.months[i].groups.length; j++) {
            const month = this.data.months[i].number
            const group = this.data.months[i].groups[j]
            params.months.push({
              number: month,
              category: group.category,
              budget: group.budget,
            })
          }
        }
        // console.log(JSON.stringify(params))
        this.isLoading = true
        http
          .post('/finance/budgets/' + this.budgetId + '/update', params)
          .then(() => {
            // this.isLoading = false
            this.reload()
          })
          .catch((err) => {
            this.isLoading = false
            this.error = 'Error saving budget: ' + err
          })
      },
    },
  }
</script>

<style lang="sass" scoped>
  a:hover
    cursor: pointer

  tr.months th
    text-align: center

  tr.values th
    text-align: right
    color: #666

  td
    text-align: right

  td.label
    text-align: left

  td.positive
    color: green

  td.negative
    color: red

  .colorized
    td.amount, th.amount
      background: #edf4f8

    td.budget, th.budget
      background: #fcfcda

    td.diff, th.diff
      background: #f4e9f1

  .result.amount
    font-weight: bold

  .show-breakdown tr.sum td
    font-weight: bold
    border-top: 2px solid #333
    border-bottom: 2px solid #333

  input.budget-value-input
    width: 100px
    font-size: 14px
    display: inline
    margin: 0

  a.copy-budget-link
    text-transform: uppercase
    font-size: 12px
    padding-left: 5px

  .settings-bar
    margin-bottom: 20px

    input[type=text]
      display: inline-block
      margin: 0
      width: 200px

    input[type=text].year-input
      width: 100px

    select
      display: inline-block
      width: 200px
      margin: 0

    > span
      margin-right: 10px

    > label
      margin-right: 10px
</style>
