<template>
  <div class="container-fluid">
    <h1> Transactions </h1>

    <div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Category</th>
            <th>Filter</th>
            <th class="number">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="category in categories" :key="category._id">
            <td>{{ category.name }}</td>
            <td>
              <label>
                <input v-model="filterCategory" type="radio" name="filterCategory" :value="category.name" />
                Filter
              </label>
            </td>
            <td class="number">{{ formatAmount(category.amount) }}</td>
          </tr>
          <tr class="sum">
            <td>SUM</td>
            <td>
              <label>
                <input v-model="filterCategory" type="radio" name="filterCategory" value="" />
                No filter
              </label>
            </td>
            <td class="number">{{ formatAmount(getTotal()) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Number</th>
            <th>Name</th>
            <th>Filter</th>
            <th class="number">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in accounts" :key="account.number">
            <td>
              <router-link
                :to="{ name: 'FinancePostings', params: { year: year, month: month, account: account.number } }"
              >
                {{ account.number }}
              </router-link>
            </td>
            <td>{{ account.name }}</td>
            <td>
              <label>
                <input v-model="filterAccount" type="radio" name="filterAccount" :value="account.number" />
                Filter
              </label>
            </td>
            <td class="number">{{ formatAmount(account.amount) }}</td>
          </tr>
          <tr class="sum">
            <td colspan="2">SUM</td>
            <td>
              <label>
                <input v-model="filterAccount" type="radio" name="filterAccount" value="" />
                No filter
              </label>
            </td>
            <td class="number">{{ formatAmount(getTotal()) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Month</th>
            <th>AccountNum</th>
            <th>Category</th>
            <th>AccountName</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in listData" :key="row">
            <td>
              {{ row.month }}
            </td>
            <td>
              <router-link
                :to="{ name: 'FinancePostings', params: { year: year, month: row.month, account: row.account_number } }"
              >
                {{ row.account_number }}
              </router-link>
            </td>
            <td>
              {{ row.category }}
            </td>
            <td>
              {{ row.account_name }}
            </td>
            <td class="number">
              {{ formatAmount(row.amount) }}
            </td>
          </tr>
          <tr class="sum">
            <td>SUM</td>
            <td colspan="3">&nbsp;</td>
            <td class="number">{{ formatAmount(getTotal()) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- .front-page container-fluid -->
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'FinanceTransactionsExpense',
    components: {},
    data() {
      return {
        data: [],
        filterAccount: '',
        filterCategory: '',
        year: 0,
        month: 0,
      }
    },
    computed: {
      categories() {
        if (!this.data || !this.data.length) {
          return []
        }
        const result = {}
        for (let i = 0; i < this.data.length; i++) {
          const item = this.data[i]
          if (!result[item.category]) {
            result[item.category] = {
              name: item.category,
              amount: 0,
            }
          }
          result[item.category].amount += parseFloat(item.amount)
        }
        const response = []
        for (const key in result) {
          response.push(result[key])
        }
        return response
      },
      accounts() {
        if (!this.data || !this.data.length) {
          return []
        }
        const result = {}
        for (let i = 0; i < this.data.length; i++) {
          const isCategoryMatch = !this.filterCategory || this.data[i].category === this.filterCategory
          if (!isCategoryMatch) {
            continue
          }
          const item = this.data[i]
          if (!result[item.account_number]) {
            result[item.account_number] = {
              number: item.account_number,
              name: item.account_name,
              amount: 0,
            }
          }
          result[item.account_number].amount += parseFloat(item.amount)
        }
        const response = []
        for (const key in result) {
          response.push(result[key])
        }
        return response
      },
      listData() {
        if (!this.filterAccount && !this.filterCategory) {
          return this.data
        }
        const result = []
        for (let i = 0; i < this.data.length; i++) {
          const isAccountMatch = !this.filterAccount || '' + this.data[i].account_number === this.filterAccount
          const isCategoryMatch = !this.filterCategory || this.data[i].category === this.filterCategory
          if (isAccountMatch && isCategoryMatch) {
            result.push(this.data[i])
          }
        }
        return result
      },
    },
    mounted() {
      const year = parseInt(this.$route.params.year)
      const month = parseInt(this.$route.params.month)
      this.year = year
      this.month = month
      const url = '/finance/transactions?type=expense&year=' + year + '&month= ' + month
      http
        .get(url)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          this.error = 'Error fetching data: ' + err
        })
    },
    methods: {
      getText(row) {
        let text = row.product_name
        if (row.description) {
          text += ' - ' + row.description
        }
        return text
      },
      getTotal() {
        let sum = 0
        for (let i = 0; i < this.data.length; i++) {
          sum += parseFloat(this.data[i].amount)
        }
        return sum
      },
      formatAmount(value) {
        return Math.round(value)
      },
    },
  }
</script>

<style scoped lang="sass">
  .number
    text-align: right
  tr.sum td
    border-top: 2px solid black
    border-bottom: 6px double black
</style>
