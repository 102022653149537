<template>
  <div class="row todo-overview-container">
    <!-- Sidebar toggles -->

    <a class="toggle-expand toggle-expand-left" @click="toggleLeftSideBar()">
      <font-awesome-icon :icon="settings.sidebars.left ? 'caret-left' : 'caret-right'"></font-awesome-icon>
    </a>
    <a class="toggle-expand toggle-expand-right" @click="toggleRightSideBar()">
      <font-awesome-icon :icon="settings.sidebars.right ? 'caret-right' : 'caret-left'"></font-awesome-icon>
    </a>

    <!-- Left -->

    <div v-show="settings.sidebars.left" class="col-2">
      <div class="todo-sidebar-left">
        <todo-side-bar-left :boards="boards" @update="onUpdate"></todo-side-bar-left>
        <pre>
          {{ settings }}
        </pre>
      </div>
    </div>

    <!-- Main -->

    <div
      :class="'col-' + (8 + (settings.sidebars.left ? 0 : 2) + (settings.sidebars.right ? 0 : 2))"
      style="overflow-x: auto"
    >
      <div class="todo-main">
        <div v-show="isLoading" class="loading">
          <font-awesome-icon icon="spinner" spin></font-awesome-icon>
        </div>
        <div v-show="error" class="error-message" @click="error = ''">
          {{ error }}
        </div>
        <div v-if="!isLoading">
          <div v-if="board">
            <task-list
              v-if="board"
              :fields="settings.fields"
              :actions="settings.actions"
              :assignees="assignees"
              :board-id="board.id"
              :buckets="buckets"
              :customers="customers"
              :groups="board.groups"
              :no-confirmations="noConfirmations"
              :priorities="priorities"
              :statuses="statuses"
              :tracks="tracks"
              :add-to-board-id="addToBoardId"
              :boards="boards"
              @update="onUpdate"
            ></task-list>
          </div>
          <div v-if="!board">
            <div style="margin-bottom: 20px; font-size: 18px"> No open board </div>
            <b-btn @click="openTypeBoard(type)"> Open new {{ type }} board </b-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- Right -->

    <div v-show="settings.sidebars.right" class="col-2">
      <div class="todo-sidebar-right">
        <todo-side-bar-sprint
          v-if="board && (board.type === 'sprint' || board.type === 'week')"
          :assignees="assignees"
          :tracks="tracks"
          :assignee-capacity="assigneeCapacity"
          :track-capacity="trackCapacity"
          :customers="customers"
          :workspaces="workspaces"
          :board="board"
        ></todo-side-bar-sprint>
        <todo-board-settings
          :board="board"
          :fields="settings.fields"
          :actions="settings.actions"
          :filters="settings.filters"
          :collapsed="settings.collapsed"
          :group-by="settings.groupBy"
          :order-by="settings.orderBy"
          :boards="boards"
          :no-confirmations="noConfirmations"
          :assignees="assignees"
          :buckets="buckets"
          :customers="customers"
          :priorities="priorities"
          :statuses="statuses"
          :tracks="tracks"
          :add-to-board-id="addToBoardId"
          @changeAddToBoardId="onChangeAddToBoardId"
          @changeFilters="onChangeFilters"
          @changeActions="onChangeActions"
          @changeFields="onChangeFields"
          @changeNoConfirmations="onChangeNoConfirmations"
          @changeCollapsed="onChangeCollapsed"
          @saveSettings="onSaveBoardSettings"
          @loadSettings="onLoadBoardSettings"
          @resetSettings="onResetBoardSettings"
          @changeMode="onChangeBoardMode"
          @changeName="onChangeBoardName"
          @changeGroupBy="onChangeBoardGroupBy"
          @changeOrderBy="onChangeBoardOrderBy"
          @deleteBoard="deleteBoard"
          @closeDoneTasks="closeDoneTasks"
          @closeBoard="closeBoard"
        ></todo-board-settings>
      </div>
    </div>
  </div>
</template>

<script>
  import TaskList from '../components/TaskList'
  import TodoSideBarLeft from '../components/TodoSideBarLeft'
  import TodoSideBarSprint from '../components/TodoSideBarSprint'
  import TodoBoardSettings from '../components/TodoBoardSettings'
  import backend from '@/utils/TaskBackend'
  import { Board, Filters, Group, BoardSettings } from '@/models/TaskModels'

  export default {
    name: 'TodoOverview',
    components: {
      TodoSideBarLeft,
      TodoSideBarSprint,
      TodoBoardSettings,
      TaskList,
    },
    data() {
      return {
        error: '',
        isLoading: false,
        type: '',
        id: '',
        board: null,
        workspaces: [],
        assignees: [],
        statuses: [],
        priorities: [],
        customers: [],
        tracks: [],
        buckets: [],
        boards: [],
        assigneeCapacity: [],
        trackCapacity: [],
        addToBoardId: null,
        noConfirmations: true,
        settings: new BoardSettings(),
        defaultFieldsForBoardMode: [
          'selector',
          'description',
          'status',
          'assigneeId',
          'estimate',
          'customerId',
          'trackId',
          'reference',
          'pings',
          'sprintIcon',
          'weekIcon',
          'actions',
        ],
        defaultFieldsForTaskMode: [
          'selector',
          'description',
          'status',
          'priority',
          'assigneeId',
          'estimate',
          'customerId',
          'trackId',
          'bucket',
          'reference',
          'pings',
          'createdAt',
          'sprintIcon',
          'weekIcon',
          'actions',
        ],
        defaultActionsForBoardMode: [
          'group-drag',
          'group-delete',
          'drag',
          'detach',
          'delete',
          'done',
          'backlog',
          'board',
          'incoming',
          'clone',
          'show',
          'ping',
        ],
        defaultCollapsed: {
          board: false,
          search: false,
          filters: true,
          fields: true,
          actions: true,
        },
        defaultActionsForTaskMode: ['delete', 'done', 'backlog', 'board', 'incoming', 'show', 'ping'],
      }
    },
    watch: {
      $route: {
        handler() {
          this.onChangeUrl()
        },
        deep: true,
      },
    },
    mounted() {
      this.loadMeta(() => {
        this.onChangeUrl()
      })
    },
    methods: {
      toggleLeftSideBar() {
        this.navigate({
          sidebars: {
            left: !this.settings.sidebars.left,
            right: this.settings.sidebars.right,
          },
        })
      },
      toggleRightSideBar() {
        this.navigate({
          sidebars: {
            left: this.settings.sidebars.left,
            right: !this.settings.sidebars.right,
          },
        })
      },

      // URL params - Passing settings between URL and State

      onChangeAddToBoardId(id) {
        this.addToBoardId = id
      },
      onChangeNoConfirmations(value) {
        this.noConfirmations = value
      },
      onChangeFilters(filters) {
        if (this.diffFilters(this.settings.filters, filters)) {
          this.navigate({
            filters,
          })
        } else {
        }
      },
      onChangeFields(fields) {
        if (this.diffArray(this.settings.fields, fields)) {
          this.navigate({
            fields,
          })
        }
      },
      onChangeActions(actions) {
        if (this.diffArray(this.settings.actions, actions)) {
          this.navigate({
            actions,
          })
        }
      },
      onChangeCollapsed(collapsed) {
        const isDiff = JSON.stringify(collapsed) !== JSON.stringify(this.settings.collapsed)
        if (isDiff) {
          this.navigate({
            collapsed,
          })
        }
      },
      onChangeBoardGroupBy(groupBy) {
        if (groupBy !== this.settings.groupBy) {
          this.navigate({
            groupBy,
          })
        }
      },
      onChangeBoardOrderBy(orderBy) {
        if (orderBy !== this.settings.orderBy) {
          this.navigate({
            orderBy,
          })
        }
      },
      onChangeBoardMode(mode) {
        this.isSaving = true
        backend.setBoardMode(this.board.id, mode).then(
          () => {
            this.onChangeUrl(true)
            this.isSaving = false
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      onChangeBoardName(name) {
        this.isSaving = true
        backend.setBoardName(this.board.id, name).then(
          () => {
            this.board.name = name
            for (let i = 0; i < this.boards.length; i++) {
              if (this.boards[i].id === this.board.id) {
                this.boards[i].name = this.board.name
              }
            }
            this.isSaving = false
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      onResetBoardSettings() {
        const isBoard = this.$route.params.type !== 'tasks'
        const settings = {
          fields: isBoard ? this.defaultFieldsForBoardMode : this.defaultFieldsForTaskMode,
          actions: isBoard ? this.defaultActionsForBoardMode : this.defaultActionsForTaskMode,
          sidebars: { left: true, right: true },
          filters: new Filters(),
          collapsed: this.defaultCollapsed,
          groupBy: 'group',
          orderBy: '',
        }
        this.navigate(settings)
      },
      onSaveBoardSettings() {
        this.isSaving = true
        console.log('saveBoardSettings: ' + JSON.stringify(this.settings))
        backend.saveBoardSettings(this.board.id, this.settings).then(
          () => {
            this.board.settings = this.settings
            this.isSaving = false
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      onLoadBoardSettings() {
        // When clicking the "Load settings" button on board,
        // i.e. we force the board's settings by navigating to a new URL with those settings,
        // leaving any unsaved in-URL changes to settings behind.
        if (this.board.settings) {
          console.log('loadBoardSettings: ' + JSON.stringify(this.board.settings))
          this.navigate(this.board.settings)
        } else {
          this.error = 'Board has no settings'
        }
      },
      applyBoardSettingsWithUrlOverrides() {
        // When having loaded a board via loadBoard(), and want settings from that board to apply,
        // but only if there are not explicit "runtime" settings from URL that should override the board's defaults
        if (!this.board.settings) {
          console.log('applyBoardSettingsWithUrlOverrides: board has no settings')
          return
        }
        console.log('applyBoardSettingsWithUrlOverrides: apply = ' + JSON.stringify(this.board.settings))

        const url = this.getSettingsFromUrl()

        if (!url.collapsed && this.board.settings.collapsed) {
          this.settings.collapsed = this.board.settings.collapsed
        }
        if (!url.fields && this.board.settings.fields) {
          this.settings.fields = this.board.settings.fields
        }
        if (!url.actions && this.board.settings.actions) {
          this.settings.actions = this.board.settings.actions
        }
        if (!url.filters && this.board.settings.filters) {
          this.settings.filters = this.board.settings.filters
        }
        if (!url.sidebars && this.board.settings.sidebars) {
          this.settings.sidebars = this.board.settings.sidebars
        }
        if (!url.groupBy && this.board.settings.groupBy) {
          this.settings.groupBy = this.board.settings.groupBy
        }
        if (!url.orderBy && this.board.settings.orderBy) {
          this.settings.orderBy = this.board.settings.orderBy
        }
      },
      diffArray(arr1, arr2) {
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return true
          }
        }
        for (let i = 0; i < arr2.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return true
          }
        }
        return false
      },
      isFilterText(key) {
        return key === 'sql' || key === 'search'
      },
      diffFilters(arr1, arr2) {
        for (const key in arr1) {
          const v1 = JSON.stringify(arr1[key] || (this.isFilterText(key) ? '' : []))
          const v2 = JSON.stringify(arr2[key] || (this.isFilterText(key) ? '' : []))
          if (v1 !== v2) {
            return true
          }
        }
        for (const key in arr2) {
          const v1 = JSON.stringify(arr1[key] || (this.isFilterText(key) ? '' : []))
          const v2 = JSON.stringify(arr2[key] || (this.isFilterText(key) ? '' : []))
          if (v1 !== v2) {
            return true
          }
        }
        return false
      },

      navigate(params) {
        console.log('Navigate because: ' + JSON.stringify(params))

        const query = {}
        const settings = {
          fields: params.fields || this.settings.fields,
          actions: params.actions || this.settings.actions,
          filters: params.filters || this.settings.filters,
          sidebars: params.sidebars || { left: this.settings.sidebars.left, right: this.settings.sidebars.right },
          collapsed: params.collapsed || this.settings.collapsed,
          groupBy: params.groupBy || this.settings.groupBy,
          orderBy: params.orderBy || this.settings.orderBy,
        }

        if (settings.fields) {
          query.fields = settings.fields.join(',')
        }
        if (settings.actions) {
          query.actions = settings.actions.join(',')
        }

        if (settings.filters) {
          query.filters = JSON.stringify(settings.filters)
          /*
          for (const key in settings.filters) {
            const isEmpty =
              !settings.filters[key] || (Array.isArray(settings.filters[key]) && settings.filters[key].length === 0)
            if (!isEmpty) {
              query[key] = JSON.stringify(settings.filters[key])
            }
          }
          */
        }

        query.sidebars = JSON.stringify(settings.sidebars)
        query.collapsed = JSON.stringify(settings.collapsed)
        query.groupBy = settings.groupBy
        query.orderBy = settings.orderBy

        console.log('Navigate to: ' + JSON.stringify(query))
        this.$router.push({
          name: 'Todo',
          params: { type: this.$route.params.type, id: this.$route.params.id },
          query,
        })
      },

      onChangeUrl(forceLoad = false) {
        // Gather new settings from URL

        const type = this.$route.params.type
        const id = this.$route.params.id
        const settings = this.getSettingsFromUrl()
        const isBoard = type !== 'tasks'

        console.log('onChangeUrl: ' + JSON.stringify(settings))

        // Update state (before must-load check)

        this.settings.fields =
          settings.fields || (isBoard ? this.defaultFieldsForBoardMode : this.defaultFieldsForTaskMode)
        this.settings.actions =
          settings.actions || (isBoard ? this.defaultActionsForBoardMode : this.defaultActionsForTaskMode)
        this.settings.sidebars = settings.sidebars || { left: true, right: true }
        this.settings.collapsed = settings.collapsed || this.defaultCollapsed
        const groupBy = settings.groupBy || 'group'
        const orderBy = settings.orderBy || ''
        const filters = settings.filters ? new Filters(settings.filters) : new Filters()

        // Decide if we need to fetch new data (only if something except fields/actions changed)

        const didFiltersChange = this.diffFilters(this.settings.filters, filters)
        const didGroupChange = this.settings.groupBy !== groupBy
        const didOrderChange = this.settings.orderBy !== orderBy
        const hasBoard = !!this.board
        const didTypeChange = this.type !== type
        const didIdChange = '' + this.id !== '' + id
        const mustLoad =
          forceLoad || didFiltersChange || !hasBoard || didTypeChange || didIdChange || didGroupChange || didOrderChange

        // Update state (after must-load check)

        this.settings.filters = filters
        this.type = type
        this.id = id
        this.settings.groupBy = groupBy
        this.settings.orderBy = orderBy

        // Load data

        if (mustLoad) {
          console.log('onChangeUrl: must load data')
          if (isBoard) {
            console.log('onChangeUrl: loadBoard type=' + type + ' id=' + id + ' filters =', this.settings.filters)
            this.loadBoard(type, id, settings.filters, settings.groupBy, settings.orderBy)
          } else {
            console.log('onChangeUrl: loadTasks')
            this.loadTasks(settings.filters, settings.orderBy)
          }
        } else {
          console.log('onChangeUrl: no need to load data')
        }
      },
      getSettingsFromUrl() {
        let fields = null
        if (this.$route.query.fields) {
          fields = this.$route.query.fields.split(',')
        }

        let actions = null
        if (this.$route.query.fields) {
          actions = this.$route.query.actions.split(',')
        }

        /*
        let gotFilters = false
        const filters = {}
        if (this.$route.query) {
          for (const key in this.settings.filters) {
            if (this.$route.query[key]) {
              const value = JSON.parse(this.$route.query[key])
              if (Array.isArray(value)) {
                if (value.length > 0) {
                  filters[key] = value
                }
              } else if (value) {
                if (this.isFilterText(key)) {
                  filters[key] = value
                } else {
                  filters[key] = [value]
                }
              }
              gotFilters = true
            }
          }
        }
        */

        let filters = null
        if (this.$route.query.filters) {
          filters = JSON.parse(this.$route.query.filters)
        }

        let collapsed = null
        if (this.$route.query.collapsed) {
          collapsed = JSON.parse(this.$route.query.collapsed)
        }

        let sidebars = null
        if (this.$route.query.sidebars) {
          sidebars = JSON.parse(this.$route.query.sidebars)
        }

        let groupBy = null
        if (this.$route.query.groupBy) {
          groupBy = this.$route.query.groupBy
        }

        let orderBy = null
        if (this.$route.query.orderBy) {
          orderBy = this.$route.query.orderBy
        }

        return {
          fields,
          actions,
          filters,
          sidebars,
          collapsed,
          groupBy,
          orderBy,
        }
      },
      loadMeta(callback) {
        this.isLoading = true
        backend.getMetaData().then(
          (data) => {
            this.workspaces = data.workspaces
            this.assignees = data.assignees
            this.statuses = data.statuses
            this.priorities = data.priorities
            this.customers = data.customers
            this.tracks = data.tracks
            this.buckets = data.buckets
            this.boards = data.boards
            this.assigneeCapacity = data.assigneeCapacity
            this.trackCapacity = data.trackCapacity
            this.isLoading = true
            callback()
          },
          (error) => {
            this.error = error
            this.isLoading = true
          },
        )
      },
      loadTasks(filters, orderBy) {
        this.error = ''
        this.isLoading = true
        backend.getTasks(filters, orderBy, this.settings.fields).then(
          (tasks) => {
            const g = new Group({
              id: 0,
              name: 'Tasks',
              isVirtual: true,
              tasks,
            })
            this.board = new Board({
              id: 0,
              name: 'Virtual: ' + this.$route.params.id,
              groups: [g],
            })
            this.isLoading = false
          },
          (error) => {
            this.error = error
            this.isLoading = false
          },
        )
      },
      loadBoard(type, id, filters, groupBy, orderBy) {
        this.error = ''
        this.isLoading = true
        backend.getBoard(type, id, filters, groupBy, orderBy, this.settings.fields).then(
          (board) => {
            this.board = board
            this.applyBoardSettingsWithUrlOverrides()
            this.isLoading = false
          },
          (data) => {
            if (data.status === 404) {
              this.board = null
            } else {
              this.error = data.error
            }
            this.isLoading = false
          },
        )
      },
      setError(msg, err) {
        if (err && err.response && err.response.data && err.response.data.error) {
          msg += ' : ' + err.response.data.error
        }
        this.error = msg
      },
      deleteById(items, id) {
        let index = -1
        for (let i = 0; i < items.length; i++) {
          const i1 = '' + items[i].id
          const i2 = '' + id
          if (i1 === i2) {
            index = i
            break
          }
        }
        if (index !== -1) {
          items.splice(index, 1)
        }
      },
      closeDoneTasks() {
        const ids = []
        const tasks = []
        for (let i = 0; i < this.board.groups.length; i++) {
          for (let j = 0; j < this.board.groups[i].tasks.length; j++) {
            const task = this.board.groups[i].tasks[j]
            if (task.status === 'Done' || task.status === 'Skip') {
              ids.push(task.id)
              tasks.push(task)
            }
          }
        }
        if (ids.length === 0) {
          this.error = 'No tasks to close'
          return
        }
        this.isSaving = true
        backend.closeDoneTasks(ids).then(
          () => {
            for (let i = 0; i < tasks.length; i++) {
              this.onUpdate({
                action: 'delete',
                type: 'task',
                data: tasks[i],
              })
            }
            this.isSaving = false
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      deleteBoard(board) {
        if (!this.noConfirmations) {
          if (!confirm('Tasks will be kept, groups and board will be deleted. Are you sure?')) {
            return
          }
        }
        this.isSaving = true
        backend.deleteBoard(board.id).then(
          () => {
            this.onUpdate({
              action: 'delete',
              type: 'board',
              data: board,
            })
            this.isSaving = false
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      closeBoard(board) {
        if (!this.noConfirmations) {
          if (!confirm('Board will be marked as closed. Are you sure?')) {
            return
          }
        }
        backend.closeBoard(board.id).then(
          () => {
            this.onUpdate({
              action: 'delete',
              type: 'board',
              data: board,
            })
          },
          (error) => {
            this.error = error
          },
        )
      },
      updateById(items, item) {
        if (!item || !item.id) {
          return
        }
        const id = item.id
        let index = -1
        for (let i = 0; i < items.length; i++) {
          const i1 = '' + items[i].id
          const i2 = '' + id
          if (i1 === i2) {
            index = i
            break
          }
        }
        if (index !== -1) {
          Object.assign(items[index], item)
        }
      },
      openTypeBoard(type) {
        backend.newTypeBoard(type).then(
          (board) => {
            this.onUpdate({
              action: 'new',
              type: 'board',
              data: board,
            })
            this.$router.push({
              name: 'Todo',
              params: { type, id: board.id },
            })
          },
          (error) => {
            this.error = error
          },
        )
      },
      onUpdate(ev) {
        const action = ev.action
        const type = ev.type
        const data = ev.data
        console.log('onUpdate: ' + action + ' : ' + type + ' : ' + JSON.stringify(data))
        switch (action) {
          // Action: Delete

          case 'delete':
            switch (type) {
              case 'group':
                this.deleteById(this.board.groups, data.id)
                break
              case 'board':
                this.deleteById(this.boards, data.id)
                if (this.board.id === data.id) {
                  this.board = null
                }
                break
              case 'task':
                for (let i = 0; i < this.board.groups.length; i++) {
                  this.deleteById(this.board.groups[i].tasks, data.id)
                }
                break
              default:
                console.error('onUpdate: invalid type: ' + type + ' for action: ' + action)
            }
            break

          // Action: Update

          case 'update':
            switch (type) {
              case 'groups':
                this.board.groups = data
                break
              case 'group':
                this.updateById(this.board.groups, data)
                break
              case 'task':
                for (let i = 0; i < this.board.groups.length; i++) {
                  this.updateById(this.board.groups[i].tasks, data)
                }
                break
            }
            break

          // Action: New

          case 'new':
            switch (type) {
              case 'assignee':
                this.assignees.push(data)
                break
              case 'customer':
                this.customers.push(data)
                break
              case 'group':
                this.board.groups.push(data)
                break
              case 'board':
                this.boards.push(data)
                break
              case 'task':
                for (let i = 0; i < this.board.groups.length; i++) {
                  if (this.board.groups[i].id === data.groupId) {
                    this.board.groups[i].tasks.push(data)
                  }
                }
                break
              default:
                console.error('onUpdate: invalid type: ' + type + ' for action: ' + action)
            }
            break

          // Action: Unknown

          default:
            console.error('onUpdate: invalid action: ' + action)
        }
        // console.log('force update')
        // this.$forceUpdate()
      },
    },
  }
</script>

<style lang="sass" scoped>

  .section
    margin-bottom: 50px

    a
      display: block
      padding-bottom: 5px

  .error-message
    border: 1px solid red
    padding: 10px
    margin-bottom: 20px
    color: red
    text-align: center

  .loading
    font-size: 30px
    position: absolute
    right: 30px
    z-index: 1000

  .toggle-expand
    position: fixed
    background: #2288aa
    color: white
    font-size: 18px
    line-height: 18px
    text-align: center
    width: 18px
    height: 18px
    border-radius: 15px
    display: block
    top: 46px
    z-index: 10000

  .toggle-expand-left
    left: 10px

  .toggle-expand-right
    right: 10px
</style>

<style lang="sass">
</style>
