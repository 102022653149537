<template>
  <div class="container-fluid">
    <h1>Reason Codes</h1>
    <div style="color: #888; font-style: italic; margin-bottom: 20px">
      * Time is sum of work_start_at to done_at for the tasks where those fields are set.
      <br />
      I.e. it may be misleading if not setting Working and Done status in a structured way to represent work, or if
      tasks linger open for a long time without actually being worked on.
      <br />
      <br />
      Future improvements: Option to delete/set-as-done (must now set deleted_at in db), and auto-link to note for
      description.
    </div>

    <div v-show="isLoading" class="loading-indicator"> Loading... </div>

    <div v-show="errorMessage" class="error-indicator" @click="errorMessage = ''">{{ errorMessage }}</div>

    <div class="filters row">
      <div class="col col-lg-8">
        <a :class="filterType === '' ? 'active' : ''" @click="filterType = ''">All</a>
        <a :class="filterType === 'recur' ? 'active' : ''" @click="filterType = 'recur'">Recur</a>
        <a :class="filterType === 'delegate' ? 'active' : ''" @click="filterType = 'delegate'">Delegate</a>
      </div>
      <div class="col col-lg-4" style="text-align: right">
        <b-checkbox v-model="showClosed">Show closed</b-checkbox>
      </div>
    </div>

    <div>
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Name</th>
            <th style="text-align: center">Count (total)</th>
            <th style="text-align: center">Count (6 months)</th>
            <th style="text-align: center">Count (2 months)</th>
            <th style="text-align: center">Time (total) *</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in getRows()" :key="item._id">
            <td>
              {{ item._id }}
            </td>
            <td>
              {{ item.type }}
            </td>
            <td>
              <a :href="getLink(item)">
                {{ item.name }}
              </a>
            </td>
            <td style="text-align: center">
              {{ item.countTotal }}
            </td>
            <td style="text-align: center">
              {{ item.count180days }}
            </td>
            <td style="text-align: center">
              {{ item.count60days }}
            </td>
            <td style="text-align: center">
              {{ Math.round(item.timeSpentTotal / 60 / 60) }}
            </td>
            <td>
              <a class="btn btn-secondary btn-sm" @click="toggleClosed(item)">
                {{ item.is_closed ? 'Re-open' : 'Close' }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'TodoReasonCodes',
    components: {},
    data() {
      return {
        isLoading: false,
        errorMessage: '',
        filterType: '',
        showClosed: false,
        data: [],
      }
    },
    mounted() {
      this.load()
    },
    methods: {
      load() {
        this.isLoading = true
        http
          .get('/todo/reason?stats=1')
          .then((response) => {
            this.data = response.data.data
            this.isLoading = false
          })
          .catch((err) => {
            this.setError('failed loading milestones', err)
            this.isLoading = false
          })
      },
      getLink(code) {
        if (code.note_id) {
          return '/#/notes/0/' + code.note_id
        } else {
          return ''
        }
      },
      getRows() {
        const result = []
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].is_closed && !this.showClosed) {
            continue
          }
          if (!this.filterType || this.data[i].type === this.filterType) {
            result.push(this.data[i])
          }
        }
        return result
      },
      setError(message, err) {
        const data = err && err.response && err.response.data ? err.response.data : {}
        const details = data.error || '' + err
        console.error(message, err, data)
        this.errorMessage = message + ': ' + details
      },
      toggleClosed(item) {
        this.isLoading = true
        http
          .post('/todo/reason/' + item._id + '/closed', { is_closed: !item.is_closed })
          .then((response) => {
            this.load()
          })
          .catch((err) => {
            this.setError('failed toggeling closed status', err)
            this.isLoading = false
          })
      },
    },
  }
</script>

<style lang="sass">
  .loading-indicator
    background: yellow
    padding: 10px
    position: absolute
    right: 20px
    top: 100px
    font-weight: bold
  .error-indicator
    background: red
    padding: 10px
    position: absolute
    top: 100px
    right: 120px
    font-weight: bold
    color: white
  .date-cell
    white-space: nowrap
  tr.status-closed td
    background: #efe
  .filters
    margin-bottom: 20px
    font-size: 16px
    a
      padding-left: 10px
      padding-right: 10px
    a.active
      text-decoration: underline
</style>
