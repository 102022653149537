<template>
  <div>
    <div class="filter" :class="collapsed ? 'collapsed' : 'expanded'">
      <div v-show="!isText" class="hint"> Ctrl = Multiple </div>
      <div v-show="title" class="label" @click="toggleCollapsed()">
        <font-awesome-icon :icon="collapsed ? 'caret-right' : 'caret-down'"></font-awesome-icon>
        {{ title }}
      </div>

      <!-- Normal -->

      <div v-if="!isText">
        <div v-show="!collapsed" class="values expanded">
          <div v-for="option in optionsOrEmpty" :key="option.id" class="option">
            <a :class="isSelected(option) ? 'selected' : ''" @click="(ev) => toggle(option, ev)">
              {{ option.name || '(No name)' }}
              <span class="count">{{ option.count }}</span>
            </a>
          </div>
        </div>
        <div v-show="collapsed" class="values collapsed">
          <div v-for="option in selectedOptions" :key="option.id" class="option">
            <span>
              {{ option.name || '(No name)' }}
            </span>
          </div>
          <div v-show="selectedOptions.length === 0" class="no-filter"> No filter </div>
        </div>
      </div>
    </div>

    <!-- Text: SQL / Search -->

    <div v-if="isText">
      <div v-show="!collapsed" class="values expanded">
        <b-form-textarea v-if="isTextArea" v-model="internal" @change="updateImmediately"></b-form-textarea>
        <b-form-input v-if="isTextField" v-model="internal" @change="updateImmediately"></b-form-input>
      </div>
      <div v-show="collapsed" class="values collapsed">
        <pre v-show="internal">{{ internal }}</pre>
        <div v-show="!internal" class="no-filter"> No filter </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: ['options', 'collapsed', 'value', 'title', 'hideZero', 'isTextField', 'isTextArea'],
    data() {
      return {
        internal: null,
        timeout: null,
      }
    },
    computed: {
      isText() {
        return this.isTextField || this.isTextArea
      },
      optionsOrEmpty() {
        const result = []
        if (this.options && this.options.length > 0) {
          for (let i = 0; i < this.options.length; i++) {
            if (!this.hideZero || this.options[i].count > 0) {
              result.push(this.options[i])
            }
          }
        }
        return result
      },
      selectedOptions() {
        const result = []
        const selected = this.internal || []
        if (!Array.isArray(selected)) {
          return []
        }
        for (let i = 0; i < this.options.length; i++) {
          if (selected.indexOf(this.options[i].id) !== -1) {
            result.push(this.options[i])
          }
        }
        return result
      },
    },
    watch: {
      value: {
        handler() {
          this.onExternalUpdate()
        },
        deep: true,
      },
    },
    mounted() {
      this.onExternalUpdate()
    },
    methods: {
      onExternalUpdate() {
        this.internal = this.value || (this.isText ? '' : [])
      },
      toggleCollapsed() {
        this.$emit('expand', !this.collapsed)
      },
      isSelected(option) {
        if (!this.internal || !Array.isArray(this.internal)) {
          return false
        }
        const index = this.internal.indexOf(option.id)
        return index !== -1
      },
      updateDebounced() {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.updateImmediately()
          this.timeout = null
        }, 800)
      },
      updateImmediately() {
        this.$emit('input', this.internal)
      },
      toggle(option, ev) {
        const isMultiSelect = ev.ctrlKey
        const index = this.internal.indexOf(option.id)
        const wasManySelected = this.selectedOptions.length > 1
        const isSelected = index !== -1

        if (isMultiSelect) {
          const index = this.internal.indexOf(option.id)
          if (index === -1) {
            this.internal.push(option.id)
          } else {
            if (index > -1) {
              this.internal.splice(index, 1)
            }
          }
        } else {
          this.internal = []
          if (!isSelected || wasManySelected) {
            this.internal.push(option.id)
          }
        }

        if (isMultiSelect) {
          this.updateDebounced()
        } else {
          this.updateImmediately()
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
  .filter
    margin-bottom: 20px
  .label
    margin-top: 5px
    margin-bottom: 10px
    font-size: 16px
    svg
      width: 15px
  .expanded .option
    margin-bottom: 5px
    a
      padding: 8px
      text-align: center
      background: white
      border-radius: 5px
      border: 1px solid #ccc
      display: block
      text-decoration: none
      color: black
      &.selected
        background: #55cc99
      &:hover
        background: #ffffaa
        text-decoration: none
  .collapsed .option
    margin-bottom: 5px
    margin-right: 5px
    display: inline-block
    span
      padding: 4px
      text-align: center
      border-radius: 5px
      text-decoration: none
      color: black
      background: #55cc99
  .option .count
    font-size: 11px
    line-height: 18px
    width: 25px
    height: 25px
    background: #ddd
    border-radius: 15px
    padding: 3px
    display: inline-block
    margin-left: 5px
    position: absolute
    right: 40px
  a, .label
    &:hover
      cursor: pointer
  .no-filter
    color: #aaa
  .hint
    display: none
    float: right
    font-size: 11px
    color: #666
  .filter.expanded:hover .hint
    display: block
  pre
    font-size: 11px
</style>
