import { render, staticRenderFns } from "./TodoAllocProgress.vue?vue&type=template&id=55922771&scoped=true&"
import script from "./TodoAllocProgress.vue?vue&type=script&lang=js&"
export * from "./TodoAllocProgress.vue?vue&type=script&lang=js&"
import style0 from "./TodoAllocProgress.vue?vue&type=style&index=0&id=55922771&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55922771",
  null
  
)

export default component.exports