<template>
  <canvas width="400" height="120"></canvas>
</template>

<script>
  import Chart from 'chart.js/dist/Chart.bundle.js'

  export default {
    name: 'BarChart',
    props: ['labels', 'values'],
    watch: {
      values(newData) {
        this._chart.destroy()
        this.renderChart()
      },
    },
    mounted() {
      this.renderChart()
    },
    methods: {
      renderChart() {
        this._chart = new Chart(this.$el, {
          type: 'horizontalBar',
          data: {
            legend: {
              display: false,
            },
            labels: this.labels,
            datasets: [
              {
                label: '',
                data: this.values,
                backgroundColor: 'rgba(54, 162, 235, 0.3)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 0.5,
              },
            ],
          },
          options: {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label(tooltipItem) {
                  return tooltipItem.yLabel
                },
              },
            },
          },
        })
      },
    },
  }
</script>
