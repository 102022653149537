import Vue from 'vue'
import Router from 'vue-router'
import TicketsOverview from '@/views/TicketsOverview'
import TicketsUser from '@/views/TicketsUser'
import TicketsList from '@/views/TicketsList'
import Reports from '@/views/Reports'
import TodoOverview from '@/views/TodoOverview'
import TodoReasonCodes from '@/views/TodoReasonCodes'
import TodoOld from '@/views/TodoOld'
import TodoBoards from '@/views/TodoBoards'
import AttentionOverview from '@/views/AttentionOverview'
import IssueActivityCloudPerPerson from '@/views/IssueActivityCloudPerPerson'
import IssueActivityDetailsPerPerson from '@/views/IssueActivityDetailsPerPerson'
import FinanceAccounts from '@/views/FinanceAccounts'
import FinanceBudget from '@/views/FinanceBudget'
import FinanceTransactionsIncome from '@/views/FinanceTransactionsIncome'
import FinanceTransactionsExpense from '@/views/FinanceTransactionsExpense'
import FinancePostings from '@/views/FinancePostings'
import Dashboard from '../views/Dashboard'
import DashboardFull from '../views/DashboardFull'
import FreshdeskDoc from '../views/FreshdeskDoc'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'FrontPage',
      component: AttentionOverview,
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: '/dashboard-full',
      name: 'DashboardFull',
      component: DashboardFull,
    },
    {
      path: '/todo-old/:section/:mode',
      name: 'TodoOld',
      component: TodoOld,
    },
    {
      path: '/todo/:type/:id',
      name: 'Todo',
      component: TodoOverview,
    },
    {
      path: '/boards',
      name: 'TodoBoards',
      component: TodoBoards,
    },
    {
      path: '/todo/reasons',
      name: 'TodoReasonCodes',
      component: TodoReasonCodes,
    },
    {
      path: '/activity/per-person-cloud/:from/:to',
      name: 'IssueActivityCloudPerPerson',
      component: IssueActivityCloudPerPerson,
    },
    {
      path: '/activity/per-person-details/:author/:from/:to',
      name: 'IssueActivityDetailsPerPerson',
      component: IssueActivityDetailsPerPerson,
    },
    {
      path: '/reports',
      name: 'Reports',
      component: Reports,
    },
    {
      path: '/tickets/overview',
      name: 'TicketsOverview',
      component: TicketsOverview,
    },
    {
      path: '/tickets/user',
      name: 'TicketsUser',
      component: TicketsUser,
    },
    {
      path: '/tickets/list/:query/:start/:end',
      name: 'TicketsList',
      component: TicketsList,
    },
    {
      path: '/finance/budget',
      name: 'FinanceBudget',
      component: FinanceBudget,
    },
    {
      path: '/finance/accounts',
      name: 'FinanceAccounts',
      component: FinanceAccounts,
    },
    {
      path: '/finance/transactions/income/:year/:month',
      name: 'FinanceTransactionsIncome',
      component: FinanceTransactionsIncome,
    },
    {
      path: '/finance/transactions/expense/:year/:month',
      name: 'FinanceTransactionsExpense',
      component: FinanceTransactionsExpense,
    },
    {
      path: '/finance/postings/:account/:year/:month',
      name: 'FinancePostings',
      component: FinancePostings,
    },
    {
      path: '/freshdesk-doc',
      name: 'FreshdeskDoc',
      component: FreshdeskDoc,
    },
  ],
})
