export class Board {
  constructor(data) {
    this.id = data.id || null
    this.name = data.name || ''
    this.type = data.type || ''
    this.status = data.status || ''
    this.createdAt = data.createdAt || ''
    this.settings = data.settings ? JSON.parse(data.settings) : null
    this.count = data.count || ''
    this.mode = data.mode || ''
    this.weeks = data.weeks || null
    // todo: more fields
    this.groups = []
    if (data.groups && data.groups.length > 0) {
      for (let i = 0; i < data.groups.length; i++) {
        this.groups.push(new Group(data.groups[i]))
      }
    }
  }

  static fromBackend(data) {
    const data2 = fromBackend(data)
    const groups = []
    if (data2.groups && data2.groups.length > 0) {
      for (let i = 0; i < data2.groups.length; i++) {
        groups.push(Group.fromBackend(data2.groups[i]))
      }
    }
    data2.groups = groups
    return new Board(data2)
  }

  toBackend() {
    const data2 = toBackend(this)
    delete data2.groups
  }
}

export class Group {
  constructor(data) {
    if (!data) {
      data = {}
    }
    this.id = data.id || null
    this.name = data.name || ''
    this.boardId = data.boardId || null
    this.collapsed = !!data.collapsed
    this.selector = false
    this.isVirtual = !!data.isVirtual
    this.tasks = []
    if (data.tasks && data.tasks.length > 0) {
      for (let i = 0; i < data.tasks.length; i++) {
        this.tasks.push(new Task(data.tasks[i]))
      }
    }
  }

  static fromBackend(data) {
    const data2 = fromBackend(data)
    const tasks = []
    if (data2.tasks) {
      for (let i = 0; i < data2.tasks.length; i++) {
        tasks.push(fromBackend(data2.tasks[i]))
      }
    }
    data2.tasks = tasks
    return data2
  }

  toBackend() {
    const data2 = toBackend(this)
    const tasks = []
    for (let i = 0; i < data2.tasks.length; i++) {
      tasks.push(toBackend(data2.tasks[i]))
    }
    data2.tasks = tasks
    return data2
  }
}

export class Task {
  constructor(data) {
    this.id = data.id || null
    this.description = data.description || ''
    this.assigneeId = data.assigneeId || ''
    this.customerId = data.customerId || ''
    this.estimate = data.estimate || ''
    this.status = data.status || ''
    if (data.status && data.status.toLowerCase() === 'closed') {
      this.status = 'Closed' // legacy entries with lowercase status=closed
    }
    this.priority = data.priority || ''
    this.trackId = data.trackId || ''
    this.reference = data.reference || ''
    this.doneAt = data.doneAt ? data.doneAt.split(' ')[0] : ''
    this.createAt = data.createAt ? data.createAt.split(' ')[0] : ''
    this.isTransient = !!data.isTransient
    this.isEmptyList = !!data.isEmptyList
    this.allBoardIds = this.parseBoardIds(data.allBoardIds)
    this.bucket = data.bucket || ''
    this.pings = parseInt(data.pings) || 0
    this.issue = data.issue || null
    this.inSprint = !!data.inSprint
    this.inWeek = !!data.inWeek
    this.inDay = !!data.inDay
  }

  parseBoardIds(data) {
    if (!data) {
      return []
    }
    if (Array.isArray(data)) {
      return data
    }
    const tmp = data.split(',')
    const result = []
    for (let i = 0; i < tmp.length; i++) {
      const id = parseInt(tmp[i])
      if (id && !isNaN(id)) {
        result.push(id)
      }
    }
    return result
  }

  static fromBackend(data) {
    return new Task(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

export class Assignee {
  constructor(data) {
    this.id = data.id || null
    this.name = data.name || ''
    this.count = parseInt(data.count) || 0
  }

  static fromBackend(data) {
    return new Assignee(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

export class Bucket {
  constructor(data) {
    this.id = data.id || null
    this.name = data.name || ''
    this.count = parseInt(data.count) || 0
  }

  static fromBackend(data) {
    return new Bucket(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

export class Customer {
  constructor(data) {
    this.id = data.id || null
    this.name = data.name || ''
    this.workspaceId = parseInt(data.workspaceId) || null
    this.count = parseInt(data.count) || 0
  }

  static fromBackend(data) {
    return new Customer(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

export class Track {
  constructor(data) {
    this.id = data.id || null
    this.name = data.name || ''
    this.count = parseInt(data.count) || 0
  }

  static fromBackend(data) {
    return new Track(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

export class Status {
  constructor(data) {
    this.id = data.id || null
    this.name = data.name || ''
    this.count = parseInt(data.count) || 0
  }

  static fromBackend(data) {
    return new Status(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

export class Priority {
  constructor(data) {
    this.id = data.id || null
    this.name = data.name || ''
    this.count = parseInt(data.count) || 0
  }

  static fromBackend(data) {
    return new Priority(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

export class Workspace {
  constructor(data) {
    this.id = data.id || null
    this.name = data.name || ''
    this.count = parseInt(data.count) || 0
  }

  static fromBackend(data) {
    return new Workspace(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

export class AssigneeCapacity {
  constructor(data) {
    this.assigneeId = data.assigneeId || null
    this.workspaceId = data.workspaceId || null
    this.capacity = data.capacity || null
  }

  static fromBackend(data) {
    return new AssigneeCapacity(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

export class TrackCapacity {
  constructor(data) {
    this.trackId = data.trackId || null
    this.workspaceId = data.workspaceId || null
    this.capacity = data.capacity || null
  }

  static fromBackend(data) {
    return new TrackCapacity(fromBackend(data))
  }

  toBackend() {
    return toBackend(this)
  }
}

// To and from backend

const fromBackend = (obj) => {
  const obj2 = {}
  for (const k in obj) {
    const k2 = k === '_id' ? 'id' : toCamelCase(k)
    obj2[k2] = obj[k]
  }
  return obj2
}
const toBackend = (obj) => {
  const obj2 = {}
  for (const k in obj) {
    const k2 = k === 'id' ? '_id' : toSnakeCase(k)
    obj2[k2] = obj[k]
  }
  return obj2
}

const toCamelCase = (k) => {
  return k.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))
}

export const toSnakeCase = (k) => {
  return k.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export class Filters {
  constructor(data) {
    if (!data) {
      data = {}
    }
    this.assignee = data.assignee || []
    this.status = data.status || []
    this.priority = data.priority || []
    this.track = data.track || []
    this.customer = data.customer || []
    this.bucket = data.bucket || []
    this.sql = data.sql || ''
    this.search = data.search || ''
    this.hasBoard = data.hasBoard || []
    this.includeClosed = data.includeClosed || []
  }
}

export class BoardSettings {
  constructor(data) {
    if (!data) {
      data = {}
    }
    this.actions = data.actions || []
    this.fields = data.fields || []
    this.filters = new Filters(data.filters || {})
    this.collapsed = data.collapsed || []
    this.groupBy = data.groupBy || 'group'
    this.orderBy = data.orderBy || ''
    const sidebars = data.sidebars || {}
    this.sidebars = {
      left: sidebars.left === undefined ? true : sidebars.left,
      right: sidebars.right === undefined ? true : sidebars.right,
    }
  }
}
