<template>
  <div class="todo-page">
    <div v-show="isLoading" class="loading-indicator"> Loading... </div>

    <div v-show="errorMessage" class="error-indicator" @click="errorMessage = ''">{{ errorMessage }}</div>

    <div class="toolbar">
      <div class="toolbar-section">
        <button class="btn btn-success" @click="closeDone"> Close </button>
        <button class="btn btn-secondary" @click="removeEmptyRows"> Seq </button>
        <button class="btn btn-secondary" @click="orderByPriorityAndStatus"> Pri </button>
      </div>

      <div class="toolbar-section">
        <input v-model="search" class="form-control" type="text" placeholder="Search..." @keyup.enter="onSearch" />
        <label>
          <input v-model="searchClosed" type="checkbox" />
          Inc. Closed
        </label>
      </div>

      <div class="pills toolbar-section">
        <ul class="nav nav-pills">
          <li v-for="mode in modes" :key="`mode-${mode.id}`" :class="'nav-item ' + mode['class']" :title="mode.label">
            <a :class="'nav-link ' + (mode.id === activeMode ? 'active' : '')" @click="onClickMode(mode)">{{
              mode.label
            }}</a>
          </li>
        </ul>
      </div>

      <div class="toolbar-section">
        New issue:
        <a
          class="btn btn-secondary"
          target="_blank"
          href="https://git.eonbit.com/eontyre/application/issues/new"
          title="New EONTYRE backend issue"
        >
          E
        </a>
        <a
          class="btn btn-secondary"
          target="_blank"
          href="https://git.eonbit.com/eontyre/vue-front-end/issues/new"
          title="New EONTYRE frontend issue"
        >
          F
        </a>
        <a
          class="btn btn-secondary"
          target="_blank"
          href="https://git.eonbit.com/eontyre/webshop/issues/new"
          title="New EONTYRE webshop issue"
        >
          W
        </a>
      </div>

      <div class="toolbar-section">
        <b-row>
          <b-col>
            <b-form-input
              id="milestoneNameInput"
              v-model="milestoneName"
              type="text"
              style="max-width: 300px"
              placeholder="Milestone name idea"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-btn @click="generateMilestoneName()">Generate</b-btn>
            <b-btn @click="copyMilestoneName()">Copy</b-btn>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="toolbar toolbar-estimates" :class="getOverbookedClass()">
      <div class="toolbar-section estimate" :class="sumEstimates.isTotalHoursOk ? 'ok' : 'problem'">
        {{ sumEstimates.total }} hours total
      </div>

      <div class="toolbar-section estimate">
        <div v-for="a in sumEstimates.assignees" :key="a.name" :class="getAssigneeEstimateClass(a)">
          {{ a.name }}: {{ a.hours }}
        </div>
      </div>

      <div class="toolbar-section estimate" :class="getTotalEstimateClass(sumEstimates.unestimated)">
        {{ sumEstimates.unestimated || '0' }} not estimated
      </div>

      <div class="toolbar-section estimate" :class="getTotalEstimateClass(sumEstimates.unassigned)">
        {{ sumEstimates.unassigned || '0' }} not assigned
      </div>

      <div class="toolbar-section estimate">
        For <strong>{{ getMaxHours().label }}</strong
        >: max <strong>{{ getMaxHours().hours }}</strong> hr/person, and
        <span v-show="getMaxHours().shouldEstimate"><strong>require</strong> assign/estimate</span>
        <span v-show="!getMaxHours().shouldEstimate">do <strong>not</strong> require assign/estimate</span>
        <span v-show="activeSection === 'special:sprint'">- tomorrow tasks will auto-close/move to Yesterday</span>
      </div>
    </div>

    <div v-show="activeMode !== 'search'" class="tabs">
      <ul class="nav nav-tabs">
        <li
          v-for="milestone in milestones"
          :key="`milestone-${milestone.id}`"
          :class="'nav-item ' + milestone['class']"
          :title="milestone"
        >
          <a
            :class="'nav-link ' + ('milestone:' + milestone.id === activeSection ? 'active' : '')"
            @click="onClickMilestone(milestone)"
          >
            {{ milestone.label }}
          </a>
        </li>
      </ul>
    </div>

    <div v-show="activeMode !== 'search'" class="tabs">
      <ul class="nav nav-pills">
        <li
          v-for="section in sections"
          :key="`section-${section.id}`"
          :class="'nav-item ' + section['class']"
          :title="section.label"
        >
          <a :class="'nav-link ' + (section.id === activeSection ? 'active' : '')" @click="onClickSection(section)">{{
            section.label
          }}</a>
        </li>
      </ul>
    </div>

    <div v-show="activeMode === 'todos'" class="mt-0">
      <hot-table ref="hot" :settings="hotSettings" :data="data" license-key="non-commercial-and-evaluation">
      </hot-table>
    </div>

    <div v-show="activeMode === 'search'" class="mt-0">
      <div v-for="item in searchResults" :key="item.data._id" class="search-result">
        <span class="badge badge-success">
          {{ item.data.section || '(NoSection)' }}
        </span>
        <span
          class="badge"
          :class="item.data.status === 'Closed' || item.data.close_at ? 'badge-danger' : 'badge-secondary'"
        >
          {{ item.data.close_at ? 'Closed' : item.data.status || '(NoStatus)' }}
        </span>
        <span class="badge badge-secondary">
          {{ item.data.priority || '(NoPriority)' }}
        </span>
        <span v-show="item.data.assignee" class="badge badge-secondary">
          {{ item.data.assignee }}
        </span>
        <span v-show="item.data.estimate" class="badge badge-secondary">
          {{ item.data.estimate }}
        </span>
        <span class="badge badge-secondary">
          {{ item.type ? item.type.substr(0, 1).toUpperCase() : '' }}{{ item.data._id }}
        </span>
        <font-awesome-icon v-show="item.data.star" icon="star" />
        <font-awesome-icon v-show="item.data.sprint" icon="running" />
        <div class="search-result-description">
          <router-link :to="getSearchResultLink(item)">
            {{ item.data.description || '(NoDescription)' }}
          </router-link>
        </div>
        <div class="search-result-references">
          <span v-html="item.data.reference ? renderReference(item.data.reference) : ''"></span>
          <span v-html="item.data.email ? renderEmail(item.data.email) : ''"></span>
        </div>
        <div>
          <a class="btn btn-primary btn-sm" style="margin-top: 10px" @click="moveToToday(item)">Move to today</a>
        </div>
      </div>
      <div v-if="searchResults.length === 0"> No search results... </div>
    </div>
  </div>
</template>

<script>
  import http from '@/utils/http'
  import { HotTable } from '@handsontable/vue'
  import Handsontable from 'handsontable'
  import references from '@/utils/references'

  const renderReference = (value) => {
    const data = references.format(value)
    let result = data.text
    if (data.link) {
      result =
        '' +
        '<span class="cell-link" onmouseover="SynapseCellLinkHover(this)" data-synapse-loaded="no" data-synapse-reference="' +
        data.link +
        '">' +
        ' <span class="cell-link-hover">' +
        '  <span class="cell-link-hover-inner">' +
        '   <a target="_blank" href="' +
        data.link +
        '">' +
        data.link +
        '</a>' +
        '   <span class="cell-link-hover-text">text</span>' +
        '  </span>' +
        ' </span>' +
        ' <span class="cell-link-text">' +
        data.text +
        '</span>' +
        '</span>'
    }
    return result
  }

  const referenceRenderer = (instance, td, row, col, prop, value, _cellProperties) => {
    const result = renderReference(value)
    td.innerHTML = Handsontable.helper.stringify(result)
    return td
  }

  const renderEmail = (value) => {
    // quickfix to make rfc822msgid:id and rfc822msgid:<id> and <id> into id
    // todo: should instead rewrite the incoming value
    if (value) {
      value = value.replace('rfc822msgid:', '')
      value = value.replace(/^</, '')
      value = value.replace(/>$/, '')
    }
    const data = {
      text: value ? value.substr(1, 5) : '',
      link: value ? 'https://mail.google.com/mail/u/0/#search/rfc822msgid%3A' + encodeURIComponent(value) : '',
    }
    let result = data.text
    if (data.link) {
      result =
        '' +
        '<span class="cell-link" onmouseover="SynapseCellLinkHover(this)" data-synapse-loaded="no" data-synapse-reference="' +
        data.link +
        '">' +
        ' <span class="cell-link-hover">' +
        '  <span class="cell-link-hover-inner">' +
        '   <a style="white-space: nowrap" target="_gmail" href="' +
        data.link +
        '">Open in gmail</a>' +
        '   <span class="cell-link-hover-text">text</span>' +
        '  </span>' +
        ' </span>' +
        ' <span class="cell-link-text">' +
        data.text +
        '</span>' +
        '</span>'
    }
    return result
  }

  const emailRenderer = (instance, td, row, col, prop, value, _cellProperties) => {
    const result = renderEmail(value)
    td.innerHTML = Handsontable.helper.stringify(result)
    return td
  }

  function upsertClass(element, prefix, name) {
    let classes = element.className.split(' ')
    const result = []
    for (let i = 0; i < classes.length; i++) {
      if (classes[i].match(new RegExp(prefix + '-'))) {
        continue
      }
      result.push(classes[i])
    }
    result.push(prefix + '-' + name)
    classes = result.join(' ')
    element.className = classes
  }

  function statusRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.DropdownRenderer.apply(this, arguments)
    upsertClass(td.parentNode, 'status', (value || '').toLowerCase())
  }

  function priorityRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.DropdownRenderer.apply(this, arguments)
    upsertClass(td.parentNode, 'priority', (value || '').toLowerCase())
  }

  const SetIdSource = 'internal-set-id'
  const AutoFillSource = 'internal-auto-fill'
  const LoadDataSource = 'loadData'
  const Statuses = ['Epic', 'Super', 'High', 'Normal', 'Low', '']

  export default {
    name: 'Todo',
    components: {
      HotTable,
    },
    data() {
      return {
        hot: null,
        search: '',
        searchClosed: false,
        searchResults: [],
        sections: [],
        milestones: [],
        milestoneName: '',
        modes: [
          { id: 'todos', label: 'Todos', class: 'normal' },
          { id: 'search', label: 'Search', class: 'normal' },
        ],
        reasons: [],
        errorMessage: '',
        updatableKeys: [
          '_id',
          'section',
          'reference',
          'email',
          'notelink',
          'assignee',
          'estimate',
          'priority',
          'status',
          'milestone',
          'description',
          'notes',
          'sprint',
          'star',
          'recur_reason',
          'delegate_reason',
        ],
        activeSection: this.$route.params.section,
        activeMode: this.$route.params.mode,
        isLoading: false,
        removingRows: [],
        noteText: '',
        hotSettings: {
          hiddenRows: {
            copyPasteEnabled: false,
            indicators: true,
          },
          columns: [
            { data: '_id', type: 'numeric', editor: false, className: 'id' },
            { data: 'section', type: 'text', className: 'section' },
            { data: 'reference', type: 'text', className: 'reference htCenter', renderer: referenceRenderer },
            { data: 'email', type: 'text', className: 'email htCenter', renderer: emailRenderer },
            { data: 'notelink', type: 'text', className: 'notelink htCenter', renderer: referenceRenderer },
            {
              data: 'assignee',
              type: 'dropdown',
              className: 'assignee htCenter',
              source: ['', 'Stian', 'Edonit', 'Mirna', 'Audun', 'Andreas', 'Sindre', 'Dennis', 'Halil'],
            },
            { data: 'estimate', type: 'text', className: 'htCenter' },
            {
              data: 'priority',
              type: 'dropdown',
              className: 'priority htCenter',
              renderer: priorityRenderer,
              source: Statuses,
            },
            {
              data: 'status',
              type: 'dropdown',
              className: 'status htCenter',
              renderer: statusRenderer,
              source: ['', 'Todo', 'Working', 'Pending', 'Done', 'Skip'],
            },
            {
              data: 'sprint',
              type: 'checkbox',
              uncheckedTemplate: null, // workaround for cut'ing
              className: 'sprint htCenter',
            },
            {
              data: 'star',
              type: 'checkbox',
              uncheckedTemplate: null, // workaround for cut'ing
              className: 'star htCenter',
            },
            { data: 'milestone', type: 'text', className: 'milestone' },
            { data: 'description', type: 'text', className: 'description' },
            {
              data: 'recur_reason',
              type: 'dropdown',
              className: 'recur-reason',
              source: [''],
            },
            {
              data: 'delegate_reason',
              type: 'dropdown',
              className: 'delegate-reason',
              source: [''],
            },
          ],
          stretchH: 'last', // fill width
          licenseKey: 'non-commercial-and-evaluation',
          colHeaders: [
            'ID',
            'Section',
            'Issue',
            'Mail',
            'Note',
            'Assignee',
            'Estimate',
            'Priority',
            'Status',
            'Today',
            'Yesterday',
            'Milestone',
            'Description',
            'Recur',
            'Delegate',
          ],
          rowHeaders: true,
          // startRows: 10,
          // startCols: 7,
          // minSpareRows: 1, // makes no rows show?
          manualRowResize: false,
          manualColumnResize: false,
          manualRowMove: true,
          manualColumnMove: false,
          filters: true,
          colWidths: [40, 150, 60, 60, 60, 90, 80, 90, 100, 60, 60, 150, 500, 150, 150, 150],
          beforeChange: (changes, _source) => {
            // [[row, prop, oldVal, newVal], ...]
            // changes[0] = null;
            let didEnableSprint = false
            let didEnableStar = false
            let row = null
            for (let i = 0; i < changes.length; i++) {
              const key = changes[i][1]
              const value = changes[i][3]
              if (key === 'sprint' && value) {
                didEnableSprint = true
                row = changes[i][0]
              } else if (key === 'star' && value) {
                didEnableStar = true
                row = changes[i][0]
              }
              if (key === 'reference') {
                const parsed = references.parse(value)
                if (parsed) {
                  changes[i][3] = parsed
                }
              }
            }
            if (didEnableSprint) {
              const assignee = this.hot.getDataAtRowProp(row, 'assignee') || ''
              const milestone = this.hot.getDataAtRowProp(row, 'milestone') || ''
              changes.push([row, 'star', true, null])
              if (!assignee) {
                changes.push([row, 'assignee', null, 'Stian'])
              }
              if (!milestone) {
                const firstMilestone = this.getFirstMilestoneName()
                if (firstMilestone) {
                  changes.push([row, 'milestone', null, firstMilestone])
                }
              } else if (didEnableStar) {
                changes.push([row, 'sprint', true, null])
              }
            }
          },
          afterChange: (changes, source) => {
            this.afterChange(changes, source)
          },
          afterRowMove: (rows, target) => {
            this.afterRowMove(rows, target)
          },
          beforeRemoveRow: (index, amount, physicalRows, source) => {
            this.beforeRemoveRow(index, amount, physicalRows, source)
          },
          afterRemoveRow: (index, amount, physicalRows, source) => {
            this.afterRemoveRow(index, amount, physicalRows, source)
          },
          afterCreateRow: (index, amount, source) => {
            this.afterCreateRow(index, amount, source)
          },
          contextMenu: {
            items: {
              row_above: {},
              row_below: {},
              remove_row: {},
              separator: Handsontable.plugins.ContextMenu.SEPARATOR,
              // 'alignment': {},
              // 'undo': {}, // ctrl-z
              // 'redo': {}, // ctrl-shift-z
              // 'cut': {}, // ctrl-x
              // 'copy': {}, // ctrl-c
              hidden_columns_show: {},
              hidden_columns_hide: {},
              clear_custom: {
                name: 'Test action',
                callback: () => {
                  alert('test')
                },
              },
            },
          },
        },
        data: [],
      }
    },
    computed: {
      sumEstimates() {
        const data = { total: 0, assignees: [], unestimated: 0, unassigned: 0 }
        if (!this.hot) {
          return data
        }
        const count = this.hot.countRows()
        const assignees = {}
        const pat = new RegExp('([0-9]+([.,][0-9]+)?) ?(h|d|w|m)?')
        for (let i = 0; i < count; i++) {
          const id = this.hot.getDataAtRowProp(i, '_id')
          if (!id) {
            continue
          }
          const assignee = this.hot.getDataAtRowProp(i, 'assignee')
          const estimate = this.hot.getDataAtRowProp(i, 'estimate')
          if (!assignee) {
            data.unassigned++
          }
          if (!estimate) {
            data.unestimated++
            continue
          }
          const match = estimate.match(pat)
          if (!match) {
            continue
          }
          let value = parseFloat(match[1])
          const suffix = match[3]
          switch (suffix) {
            case 'd':
              value = value * 8
              break
            case 'w':
              value = value * 8 * 5
              break
            case 'm':
              value = value * 8 * 5 * 4
              break
          }
          data.total += value
          if (assignee) {
            if (!assignees[assignee]) {
              assignees[assignee] = 0
            }
            assignees[assignee] += value
          }
        }
        data.isTotalHoursOk = true
        for (const key in assignees) {
          const a = { name: key, hours: assignees[key] }
          data.assignees.push(a)
          const ok = this.getAssigneeEstimateClass(a) === 'assignee-estimate assignee-estimate-ok'
          if (!ok) {
            data.isTotalHoursOk = false
          }
        }
        return data
      },
      sectionSettings: {
        get: () => {
          for (let i = 0; i < this.sections.length; i++) {
            if (this.sections[i].id === this.activeSection) {
              return this.sections[i].settings
            }
          }
          return {}
        },
        set: (data) => {
          for (let i = 0; i < this.sections.length; i++) {
            if (this.sections[i].id === this.activeSection) {
              this.sections[i].settings = data

              this.isLoading = true
              http
                .post('/todo/section/settings', { sheet: this.activeSection, settings: data })
                .then((_response) => {
                  this.isLoading = false
                })
                .catch((err) => {
                  this.setError('error saving sheet settings', err)
                  this.isLoading = false
                })
            }
          }
        },
      },
    },
    watch: {
      $route(to, from) {
        if (from.params.mode !== to.params.mode) {
          this.activeMode = to.params.mode
        }
        if (from.params.section !== to.params.section) {
          this.activeSection = to.params.section
          this.loadTasks()
        }
        if (
          (this.activeMode === 'search' && from.params.mode !== to.params.mode) ||
          from.params.searchQuery !== to.params.searchQuery
        ) {
          this.search = to.params.searchQuery
          this.executeSearch()
        }
        if (this.activeMode === 'todos' && to.params.highlightId && from.params.highlightId !== to.params.highlightId) {
          setTimeout(() => {
            this.selectRowWithId(to.params.highlightId)
          }, 300)
        }
      },
    },
    mounted() {
      window.SynapseCellLinkHover = (elem) => {
        const value = elem.getAttribute('data-synapse-reference')
        const loaded = elem.getAttribute('data-synapse-loaded') !== 'no'
        elem.setAttribute('data-synapse-loaded', 'loading')
        if (!loaded) {
          http
            .get('/todo/reference-info?reference=' + encodeURIComponent(value))
            .then((response) => {
              elem.getElementsByClassName('cell-link-hover-text')[0].innerHTML = response.data.data.text || ''
              elem.setAttribute('data-synapse-loaded', 'loaded')
            })
            .catch((err) => {
              console.error('SynapseCellLinkHover.error:', err)
              elem.getElementsByClassName('cell-link-hover-text')[0].innerHTML = 'error loading info'
              elem.setAttribute('data-synapse-loaded', 'no')
            })
        }
      }
      this.hot = this.$refs.hot.hotInstance
      // window.hot = this.$refs.hot.hotInstance // tmp
      this.loadSections(() => {
        this.loadTasks()
      })
    },
    methods: {
      moveToToday(item) {
        http
          .post('/todo/move-to-today', { id: item.data._id })
          .then((_response) => {
            this.loadTasks()
            this.$router.push({ name: 'TodoOld', params: { section: 'special:sprint', mode: 'todos' } })
          })
          .catch((err) => {
            console.error('Move to today failed:', err)
          })
      },
      generateMilestoneName() {
        this.isLoading = true
        http
          .get('/todo/milestone/generate-name')
          .then((response) => {
            this.milestoneName = response.data.data.name
            this.isLoading = false
          })
          .catch((err) => {
            this.setError('failed making milestone name', err)
            this.isLoading = false
          })
      },
      copyMilestoneName() {
        const copyText = document.getElementById('milestoneNameInput')
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        navigator.clipboard.writeText(copyText.value)
      },
      renderReference(value) {
        return renderReference(value)
      },
      renderEmail(value) {
        return renderEmail(value)
      },
      getSearchResultLink(item) {
        return {
          name: 'TodoHighlight',
          params: { section: item.data.section, mode: 'todos', highlightId: item.data._id },
        }
      },
      onSearch() {
        this.$router.push({
          name: 'TodoSearch',
          params: { section: this.activeSection, mode: 'search', searchQuery: this.search },
        })
      },
      executeSearch() {
        http
          .get('/todo/search?closed=' + (this.searchClosed ? '1' : '') + '&query=' + encodeURIComponent(this.search))
          .then((response) => {
            this.searchResults = response.data.data
          })
          .catch((err) => {
            this.setError('failed searching', err)
          })
      },
      selectRowWithId(taskId) {
        let foundRow = null
        const findId = parseInt(taskId)
        const count = this.hot.countRows()
        for (let i = 0; i < count; i++) {
          const id = parseInt(this.hot.getDataAtRowProp(i, '_id'))
          if (id === findId) {
            foundRow = i
            break
          }
        }
        if (foundRow !== null) {
          this.hot.selectCell(foundRow, 1, foundRow, 1, true)
        } else {
          this.setError('Could not find row with id ' + taskId + ' to highlight')
        }
      },
      getTotalEstimateClass(count) {
        if (count < 1) {
          return 'ok'
        }
        return this.getMaxHours().shouldEstimate ? 'problem' : ''
      },
      getAssigneeEstimateClass(a) {
        return (
          'assignee-estimate ' +
          (a.hours > this.getMaxHours().hours ? 'assignee-estimate-over' : 'assignee-estimate-ok')
        )
      },
      getMaxHours() {
        if (this.activeSection === 'special:sprint' || this.activeSection === 'special:star') {
          return {
            hours: 8,
            label: 'day',
            shouldEstimate: true,
          }
        }
        if (this.isActiveSectionCurrentMilestone()) {
          return {
            hours: 8 * 14,
            label: 'current milestone',
            shouldEstimate: true,
          }
        }
        if (this.activeSection.startsWith('milestone:')) {
          return {
            hours: 200,
            label: 'other milestone',
            shouldEstimate: false,
          }
        }
        return {
          hours: 200,
          label: 'other section',
          shouldEstimate: false,
        }
      },
      setError(message, err) {
        const data = err && err.response && err.response.data ? err.response.data : {}
        const details = data.error || '' + err
        console.error(message, err, data)
        this.errorMessage = message + ': ' + details
      },
      isActiveSectionCurrentMilestone() {
        if (!this.activeSection.startsWith('milestone:')) {
          return false
        }
        const m = this.activeSection.replace('milestone:', '')
        return m && m === this.getFirstMilestoneName()
      },
      getFirstMilestoneName() {
        return this.milestones && this.milestones.length > 0 ? this.milestones[0].id : ''
      },
      loadMilestones(callback) {
        this.isLoading = true
        http
          .get('/todo/milestone')
          .then((response) => {
            this.milestones = []
            for (let i = 0; i < response.data.data.length; i++) {
              const t = response.data.data[i]
              this.milestones.push({
                id: t.name, // _id,
                label: t.number + '-' + t.name,
                class: 'normal',
                // 'count': t.count
              })
            }
            if (callback) {
              callback()
            }
            this.isLoading = false
          })
          .catch((err) => {
            this.setError('error loading milestones', err)
          })
      },
      loadSections(callback) {
        this.isLoading = true
        http
          .get('/todo/section')
          .then((response) => {
            this.sections = []
            for (let i = 0; i < response.data.data.length; i++) {
              const t = response.data.data[i]
              this.sections.push({
                id: t.section,
                label: t.label,
                class: t.class,
                count: t.count,
                settings: t.settings,
              })
            }
            this.loadMilestones(callback)
            this.isLoading = false
          })
          .catch((err) => {
            this.setError('error loading sections', err)
          })
      },
      addNoteHeader() {
        const area = this.$refs.noteText
        const d = new Date()
        const dt = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2)
        const text = '# ' + dt + '\n\n'
        // IE support
        if (document.selection) {
          area.focus()
          const sel = document.selection.createRange()
          sel.text = text
          // MOZILLA and others
        } else if (area.selectionStart || area.selectionStart === '0' || area.selectionStart === 0) {
          const startPos = area.selectionStart
          const endPos = area.selectionEnd
          area.value = area.value.substring(0, startPos) + text + area.value.substring(endPos, area.value.length)
        } else {
          area.value += text
        }
        area.focus()
      },
      setReasons(col, names) {
        const source = this.hot.getCellMeta(1, col).source
        if (!source) {
          return
        }
        while (true) {
          if (source.shift() === undefined) {
            break
          }
        }
        source.push('')
        for (const name of names) {
          source.push(name)
        }
      },
      loadReasonCodes() {
        http
          .get('/todo/reason')
          .then((response) => {
            this.reasons = response.data.data
            const names = []
            if (this.reasons) {
              for (let i = 0; i < this.reasons.length; i++) {
                names.push(this.reasons[i].name)
              }
            }
            this.setReasons(12, names) // recur
            this.setReasons(13, names) // delegate
          })
          .catch((err) => {
            this.setError('error loading reason-codes', err)
          })
      },
      getOverbookedClass() {
        const sumEstimates = this.sumEstimates
        const isOverbooked =
          this.activeSection === 'special:sprint' &&
          (!sumEstimates.isTotalHoursOk || sumEstimates.unestimated > 0 || sumEstimates.unassigned > 0)
        return isOverbooked ? 'sprint-overbooked' : ''
      },
      loadTasks() {
        this.isLoading = true
        this.data = []
        http
          .get('/todo/task?sheet=' + this.activeSection)
          .then((response) => {
            const items = {}
            let maxRow = 0
            let duplicateDebug = ''
            response.data.data.forEach((task, _index) => {
              // handeling for duplicate or missing sequences..at least dupes should not be able to occur anymore.. not sure about missing
              if (task.sequence > maxRow) {
                maxRow = task.sequence
              }
              if (items[task.sequence]) {
                duplicateDebug += task.sequence + ' = ' + items[task.sequence]._id + ' -> ' + task._id + '\n'
                this.setError('Got duplicate seq (seq = prev-id -> cur-id):\n' + duplicateDebug)
              }
              items[task.sequence] = task
            })
            for (let i = 0; i <= maxRow; i++) {
              const item = items[i] || {}
              item.sprint = item.sprint ? true : null // workaround for cut'ing checkbox
              item.star = item.star ? true : null // workaround for cut'ing checkbox
              this.data.push(item)
            }
            // this.ensureBlankRows()
            this.data.push({})
            this.data.push({})
            this.data.push({})
            this.loadReasonCodes()
            this.isLoading = false
          })
          .catch((err) => {
            this.setError('error loading tasks', err)
            this.isLoading = false
          })
      },
      onClickMilestone(section) {
        this.$router.push({ name: 'TodoOld', params: { section: 'milestone:' + section.id, mode: this.activeMode } })
      },
      onClickSection(section) {
        this.$router.push({ name: 'TodoOld', params: { section: section.id, mode: this.activeMode } })
      },
      onClickMode(mode) {
        this.$router.push({ name: 'TodoOld', params: { section: this.activeSection, mode: mode.id } })
      },
      removeEmptyRows() {
        const count = this.hot.countRows()
        const removables = []
        for (let i = 0; i < count; i++) {
          const id = this.hot.getDataAtRowProp(i, '_id')
          if (!id) {
            removables.push([i, 1])
          }
        }
        this.hot.alter('remove_row', removables)
      },
      orderByPriorityAndStatus() {
        this.isLoading = true
        const count = this.hot.countRows()
        const open = []
        const closed = []
        for (let i = 0; i < count; i++) {
          const id = this.hot.getDataAtRowProp(i, '_id')
          if (!id) {
            continue
          }
          const status = this.hot.getDataAtRowProp(i, 'status')
          const priorityText = this.hot.getDataAtRowProp(i, 'priority') || ''
          let priorityInt = Statuses.length - 1
          for (let j = 0; j < Statuses.length; j++) {
            if (Statuses[j] === priorityText) {
              priorityInt = j
              break
            }
          }
          if (status && (status.toLowerCase() === 'done' || status.toLowerCase() === 'skip')) {
            closed.push({ id })
          } else {
            open.push({ id, priority: priorityInt })
          }
        }
        open.sort((a, b) => {
          if (a.priority === b.priority) {
            return a.id - b.id
          }
          return a.priority - b.priority
        })
        const result = []
        let sequence = 0
        for (let i = 0; i < open.length; i++) {
          result.push({ _id: open[i].id, sequence })
          sequence++
        }
        for (let i = 0; i < closed.length; i++) {
          result.push({ _id: closed[i].id, sequence })
          sequence++
        }
        const action = { action: 'resequence', data: result }
        this.executeActions([action], () => {
          this.loadTasks()
        })
      },
      closeDone() {
        this.isLoading = true
        const actions = []
        const count = this.hot.countRows()
        for (let i = 0; i < count; i++) {
          const status = this.hot.getDataAtRowProp(i, 'status')
          const isDone = status && (status.toLowerCase() === 'done' || status.toLowerCase() === 'skip')
          if (isDone) {
            const id = this.hot.getDataAtRowProp(i, '_id')
            actions.push({ action: 'write', _id: id, data: { status: 'Closed' } })
          }
        }
        http
          .post('/todo/batch', { sheet: this.activeSection, actions })
          .then((_res) => {
            this.loadTasks()
          })
          .catch((err) => {
            this.setError('error closing done tasks', err)
            this.isLoading = false
          })
      },
      isRowEmpty(row) {
        for (let k = 0; k < this.updatableKeys.length; k++) {
          if (row[this.updatableKeys[k]]) {
            return false
          }
        }
        return true
      },
      getResequenceAction() {
        const sequence = []
        const count = this.hot.countRows()
        for (let i = 0; i < count; i++) {
          const id = this.hot.getDataAtRowProp(i, '_id')
          if (id) {
            sequence.push({ _id: id, sequence: i })
          }
        }
        return { action: 'resequence', data: sequence }
      },
      afterRowMove(_rows, _target) {
        this.executeActions([this.getResequenceAction()])
      },
      beforeRemoveRow(index, amount, physicalRows, _source) {
        this.removingRows = []
        for (let i = 0; i < physicalRows.length; i++) {
          const id = this.hot.getDataAtRowProp(physicalRows[i], '_id')
          if (id) {
            this.removingRows.push(id)
          }
        }
      },
      afterRemoveRow(_index, _amount, _physicalRows, _source) {
        const actions = []
        for (let i = 0; i < this.removingRows.length; i++) {
          actions.push({ action: 'delete', _id: this.removingRows[i] })
        }
        actions.push(this.getResequenceAction())
        this.executeActions(actions)
      },
      afterCreateRow(index, amount, source) {
        if (source === AutoFillSource) {
          return
        }
        this.executeActions([this.getResequenceAction()])
      },
      afterChange(changes, source) {
        // Skip changes we don't care about
        if (!changes) {
          return
        }
        if (source === LoadDataSource || source === SetIdSource || source === AutoFillSource) {
          return
        }

        this.isLoading = true

        // Group changes into rows
        const rowChanges = {}
        for (let i = 0; i < changes.length; i++) {
          const rowIndex = changes[i][0]
          const field = changes[i][1]
          const oldValue = changes[i][2]
          const newValue = changes[i][3]
          if (!rowChanges[rowIndex]) {
            rowChanges[rowIndex] = {}
          }
          rowChanges[rowIndex][field] = { oldValue, newValue }
        }

        const actions = []

        // Process each row
        for (const i in rowChanges) {
          const fullRow = this.hot.getSourceDataAtRow(i)
          const idChange = rowChanges[i]._id
          // If the row is now empty, the task should be deleted
          if (this.isRowEmpty(fullRow)) {
            if (idChange && idChange.oldValue) {
              actions.push({ action: 'delete', _id: idChange.oldValue })
            }
            // If it is not empty, it should be upserted
          } else {
            const updates = { sequence: i }
            if (idChange && idChange.newValue !== idChange.oldValue) {
              if (idChange.oldValue) {
                actions.push({ action: 'delete', _id: idChange.oldValue })
              }
              // When id changed, we should update entire row, because f.ex. moving id=2 to a row that has id=3,
              // that will delete row with id=3, then create row with id 2, but only id was in changes, so the
              // "leftover values" from previous row with id=3 should be used to populate row with id 2 now.
              for (let k = 0; k < this.updatableKeys.length; k++) {
                updates[this.updatableKeys[k]] = fullRow[this.updatableKeys[k]]
              }
            } else {
              // If id did not change, we only need to update fields that changed
              for (let k = 0; k < this.updatableKeys.length; k++) {
                if (rowChanges[i][this.updatableKeys[k]]) {
                  updates[this.updatableKeys[k]] = rowChanges[i][this.updatableKeys[k]].newValue
                }
              }
            }
            actions.push({ action: 'write', _id: fullRow._id, data: updates })
          }
        }

        console.log('actions:', actions)

        // Save changes to backend
        this.executeActions(actions)
      },
      executeActions(actions, callback) {
        this.isLoading = true
        http
          .post('/todo/batch', { sheet: this.activeSection, actions })
          .then((response) => {
            const data = response.data.data || []
            data.forEach((action) => {
              if (action.action === 'create') {
                const rowIndex = parseInt(action.data.sequence)
                this.hot.setDataAtRowProp(rowIndex, '_id', action._id, SetIdSource)
              }
            })
            this.isLoading = false
            this.loadSections()
            this.ensureBlankRows()
            if (callback) {
              callback()
            }
          })
          .catch((err) => {
            this.setError('error executing actions', err)
            this.isLoading = false
          })
      },
      ensureBlankRows() {
        setTimeout(() => {
          const index = this.hot.countRows() - 1
          const id = this.hot.getDataAtRowProp(index, '_id')
          if (id) {
            this.hot.alter('insert_row', index + 1, 3, AutoFillSource, true)
          }
        }, 100)
      },
    },
  }
</script>

<style src="handsontable/dist/handsontable.full.css"></style>

<style lang="sass">
  .tabs
    margin-bottom: 15px
  a.nav-link
    cursor: pointer
  .nav-tabs .special a.nav-link
    color: #888
    font-weight: bold
  .nav-pills .special-sprint a.nav-link
    color: #194
    font-weight: bold
    &.active
      color: white
      background: #194
  .nav-pills .special-star a.nav-link
    color: #ea0
    font-weight: bold
    &.active
      color: white
      background: #ea0
  .cell-link
    color: #007bff
  .cell-link-hover
    position: absolute
    height: 20px
    color: #333
  .cell-link-hover-inner
    position: relative
    top: -5px
    left: 40px
    padding: 3px
    background: white
    border: 1px solid #aaa
    z-index: 10000
    display: none
    -webkit-box-shadow: 3px 3px 6px 1px rgba(0,0,0,0.37)
    -moz-box-shadow: 3px 3px 6px 1px rgba(0,0,0,0.37)
    box-shadow: 3px 3px 6px 1px rgba(0,0,0,0.37)
  .cell-link:hover .cell-link-hover-inner
    display: block
  .toolbar
    margin: 10px
    margin-bottom: 20px
    margin-top: 0
    display: flex

  .toolbar-section
    margin-right: 20px
    line-height: 20px
    .form-check-inline
      vertical-align: middle
      margin-top: 8px
  .loading-indicator
    position: fixed
    top: 10px
    right: 10px
    background: white
    color: green
    padding: 2px
    z-index: 10000
  .error-indicator
    white-space: pre
    position: fixed
    top: 10px
    right: 100px
    background: red
    color: white
    padding: 2px
    z-index: 10000
  .note-text
    width: 100%
    height: 800px
    font-size: 14px
  .handsontable th,
  .ht_master tr > td
    font-size: 14px
    &.notes
      font-size: 12px
      line-height: 16px
  .btn
    font-size: 14px

  tr.priority-low td
    color: #888

  tr.priority-high td
    color: red

  tr.priority-super td
    color: red
    font-weight: bold

  tr.priority-epic td
    color: white
    background: red
    font-weight: bold

  tr.status-done td,
  tr.status-skip td
    color: green
    background: #cec
    font-weight: bold

  .search-result
    width: 310px
    float: left
    padding: 10px
    margin: 10px
    border: 1px solid #aaa
    height: 160px
    display: block
    color: inherit

  .search-result-description
    margin-top: 10px
    margin-bottom: 10px
    overflow: hidden
    max-height: 60px

  .search-result-note
    font-size: 11px
    color: #666
    overflow: hidden
    max-height: 35px

  .search-result-references > span
    padding-right: 10px

  .fa-star
    color: #ea0
    height: 12px

  .problem
    color: red
    font-weight: bold

  .ok
    color: green

  .toolbar-estimates
    background: #f0f0f0
    padding: 10px
    &.sprint-overbooked
      background: #ffdddd

  .assignee-estimate
    display: inline-block
    padding-right: 10px

  .assignee-estimate-over
    color: red
    font-weight: bold

  .assignee-estimate-ok
    color: green
</style>
