// ==================================================
// Formatting
// ==================================================

const noteReferenceFormat = (value) => {
  const pat = new RegExp('http.*/#/notes/([0-9]+)/([0-9]+)')
  const match = value.match(pat)
  if (match) {
    return { link: value, text: 'N-' + match[2] }
  }
}

const gitlabReferenceFormat = (value) => {
  const pat = new RegExp('https://git.eonbit.com/(.+)/issues/([0-9]+)')
  const match = value.match(pat)
  if (match) {
    const repo = match[1]
    const issue = match[2]
    let prefix
    switch (repo) {
      case 'eontyre/application':
        prefix = 'E'
        break
      case 'eontyre/vue-front-end':
        prefix = 'F'
        break
      case 'eontyre/webshop':
        prefix = 'W'
        break
      default:
        prefix = 'X'
    }
    return { text: prefix + '-' + issue, link: value }
  }
}

const zendeskReferenceFormat = (value) => {
  const pat = new RegExp('https://eontyre.zendesk.com/agent/tickets/([0-9]+)')
  const match = value.match(pat)
  if (match) {
    return { link: value, text: 'Z-' + match[1] }
  }
}

const grooveReferenceFormat = (value) => {
  const pat = new RegExp('https://eon.groovehq.com/tickets/([0-9]+)')
  const match = value.match(pat)
  if (match) {
    return { link: value, text: 'G-' + match[1] }
  }
}

const referenceFormat = (value) => {
  // Init
  if (!value) {
    return { link: null, text: '' }
  }
  let data = null

  // Try extractors
  if (value.match(new RegExp('[a-z]+://.+'))) {
    data = gitlabReferenceFormat(value)
    if (!data) {
      data = zendeskReferenceFormat(value)
    }
    if (!data) {
      data = noteReferenceFormat(value)
    }
    if (!data) {
      data = grooveReferenceFormat(value)
    }
    if (!data) {
      data = { link: value, text: value }
    }
  }

  // Fallback
  if (!data) {
    data = { link: null, text: value }
  }

  return data
}

// ==================================================
// Parsing
// ==================================================

const getNoteLink = (number) => {
  const port = document.location.port === 80 || document.location.port === 443 ? '' : ':' + document.location.port
  return document.location.protocol + '//' + document.location.hostname + port + '/#/notes/0/' + number
}

const referenceParse = (value) => {
  const pat = new RegExp('([A-Z])-([0-9]+)')
  const match = value.match(pat)
  if (match) {
    const prefix = match[1]
    const number = match[2]
    const gitlabBase = 'https://git.eonbit.com'
    const zendeskBase = 'https://eontyre.zendesk.com/agent/tickets'
    const grooveBase = 'https://eon.groovehq.com/tickets'
    switch (prefix) {
      case 'E':
        return gitlabBase + '/eontyre/application/issues/' + number
      case 'F':
        return gitlabBase + '/eontyre/vue-front-end/issues/' + number
      case 'W':
        return gitlabBase + '/eontyre/webshop/issues/' + number
      case 'Z':
        return zendeskBase + '/' + number
      case 'G':
        return grooveBase + '/' + number
      case 'N':
        return getNoteLink(number)
    }
  }
  return null
}

export default {
  format: referenceFormat,
  parse: referenceParse,
}
