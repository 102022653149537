<template>
  <div class="card">
    <div class="card-header"> Merge requests </div>
    <ul class="list-group list-group-flush">
      <li v-for="mr in mergeRequests" :key="mr.project + '-' + mr.number" class="list-group-item">
        <a :href="getLink(mr)" target="_new">
          {{ mr.title }}
        </a>
        <div class="row extra-info">
          <div class="col-lg-4 project">
            {{ mr.project }}
          </div>
          <div class="col-lg-4 number">
            {{ mr.source_branch }}
            <!-- {{ mr.description }} -->
          </div>
          <div class="col-lg-4 author">
            {{ mr.author }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import http from '@/utils/http'

  export default {
    name: 'GitlabMergeRequests',
    data() {
      return {
        mergeRequests: [],
      }
    },
    mounted() {
      http
        .get('/gitlab/merge-requests')
        .then((response) => {
          this.mergeRequests = response.data.data || []
        })
        .catch((error) => {
          console.error('get /api/stats', error)
        })
    },
    methods: {
      getLink(item) {
        return 'https://git.eonbit.com/' + item.project + '/merge_requests/' + item.number
      },
    },
  }
</script>

<style scoped lang="sass">
  .extra-info
    font-size: 11px
    .project
      text-align: left
    .number
      text-align: center
    .author
      text-align: right
</style>
