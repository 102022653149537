<template>
  <div v-if="data && data.workhours" class="container-fluid">
    <h3>Accounting</h3>
    <div class="row">
      <!-- Missing vouchers EONBIT -->

      <div class="col col-lg-2">
        <missing-vouchers
          :data="data.missingVouchersEonbit"
          :thresholds="thresholds"
          title="Missing Vouchers (EONBIT)"
        ></missing-vouchers>
      </div>

      <!-- Missing vouchers Haha -->

      <div class="col col-lg-2">
        <missing-vouchers
          :data="data.missingVouchersHaha"
          :thresholds="thresholds"
          title="Missing Vouchers (Haha)"
        ></missing-vouchers>
      </div>

      <!-- Pending remittances EONBIT -->

      <div class="col col-lg-2">
        <pending-remittances
          :data="data.pendingRemittancesEonbit"
          :thresholds="thresholds"
          title="Pending Remittances (EONBIT)"
        ></pending-remittances>
      </div>

      <!-- Pending remittances Haha -->

      <div class="col col-lg-2">
        <pending-remittances
          :data="data.pendingRemittancesHaha"
          :thresholds="thresholds"
          title="Pending Remittances (Haha)"
        ></pending-remittances>
      </div>
    </div>

    <h3>Income</h3>

    <div class="row">
      <div class="col col-lg-2">
        <financials
          title="Financials (EONBIT)"
          :thresholds="thresholds.financialsEonbit"
          :data="data.financialsEonbit"
          :account-id="2"
        ></financials>
      </div>

      <div class="col col-lg-2">
        <financials
          title="Financials (Haha)"
          :thresholds="thresholds.financialsHaha"
          :data="data.financialsHaha"
          :account-id="3"
        ></financials>
      </div>

      <div class="col col-lg-2">
        <dash-card title="Workhours">
          <dash-card-value
            :threshold-increasing="true"
            :thresholds="thresholds.workHoursBilledMonthlyAverage"
            :value="data.workhours.billedMonthlyAverage"
            subtext="Invoiced hrs avg/mon this year"
          >
          </dash-card-value>
          <dash-card-value :small="true" :value="data.workhours.billableCurrentMonth" subtext="Billable hrs this month">
          </dash-card-value>
        </dash-card>
      </div>

      <div class="col col-lg-2">
        <dash-card title="Invoicing (since days)">
          <dash-card-value
            :small="true"
            :value="data.invoicingDates.eonbitDekktech"
            subtext="EONBIT Dekktech"
            :thresholds="thresholds.invoicingDates.eonbitDekktech"
          >
          </dash-card-value>
          <div class="row">
            <div class="col">
              <dash-card-value
                :small="true"
                :value="data.invoicingDates.eonbitHours"
                subtext="EONBIT Hours"
                :thresholds="thresholds.invoicingDates.eonbitHours"
              >
              </dash-card-value>
            </div>
            <div class="col">
              <dash-card-value
                :small="true"
                :value="data.invoicingDates.hahaHours"
                subtext="Haha Hours"
                :thresholds="thresholds.invoicingDates.hahaHours"
              >
              </dash-card-value>
            </div>
          </div>
        </dash-card>
      </div>
    </div>

    <h3>DEBUG</h3>
    <pre>{{ data }}</pre>
  </div>
</template>

<script>
  import DashCard from '../components/Dashboard/DashCard'
  import DashCardValue from '../components/Dashboard/DashCardValue'
  import http from '@/utils/http'
  import MissingVouchers from '@/components/Dashboard/MissingVouchers'
  import PendingRemittances from '@/components/Dashboard/PendingRemittances'
  import Financials from '@/components/Dashboard/Financials'

  export default {
    components: {
      MissingVouchers,
      PendingRemittances,
      DashCardValue,
      DashCard,
      Financials,
    },
    data() {
      return {
        // Example: myThreshold: [5, 10], // 0-5 = green, 5-10 = orange, >10 = red
        thresholds: {
          missingVouchersCount: [0, 10],
          missingVouchersAge: [14, 30],

          pendingRemittancesCount: [10, 20],
          pendingRemittancesAge: [7, 30],

          financialsEonbit: {
            currentResult: 100000,
            averageMonthlyIncome: 70000,
            averageMonthlyExpenses: 30000,
            bankAccountBalance: [300000, 600000],
          },

          financialsHaha: {
            currentResult: 60000,
            averageMonthlyIncome: 60000,
            averageMonthlyExpenses: 10000,
            bankAccountBalance: [150000, 250000],
          },

          invoicingDates: {
            // todo: tweak dates
            eonbitHours: [60, 90],
            hahaHours: [90, 120],
            eonbitDekktech: [45, 70],
          },

          workHoursBilledMonthlyAverage: [30, 40],
          emailsInInbox: [0, 20],
          unsolvedTicketsOnMe: [0, 5],
          gitMergeRequestsOpen: [0, 2],
          gitMergeRequestsOldestDays: [7, 14],
          gitTodosOpen: [0, 2],
          gitTodosOldestDays: [7, 14],
          eontyreErrors1h: [10, 30],
          eontyreTicketOpen: [10, 40],
          eontyreTicketsOldestDays: [10, 30],
          eontyreTicketsAvgReplyTimeHours: [1, 1],
          eontyreTicketsAvgSolveTimeHours: [1, 1],
        },
        data: {},
      }
    },
    mounted() {
      http
        .get('/dashboard')
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          console.error('Error fetching data:', err)
          // this.error = 'Error fetching data: ' + err
        })
    },
    methods: {
      formatKr(value) {
        return Math.round(value / 1000) + ' tKr'
      },
    },
  }
</script>

<style lang="sass" scoped>
  h3
    border: 1px solid #ccc
    padding: 10px
    font-size: 22px
    margin: 20px
</style>
