<template>
  <div class="container-fluid">
    <!-- Health -->

    <h3>Health</h3>

    <div class="row">
      <div class="col col-lg-3">
        <dash-card title="Workhours">
          <dash-card-value :is-todo="true"
            :value="workHoursMonthlyAverage + ' hours'"
            subtext="Invoiced monthly avg"
            :status="getThreshold(workHoursMonthlyAverage, thresholds.workHours, true)"
          >
          </dash-card-value>
          <dash-card-value :is-todo="true" :small="true" :value="workHoursThisMonth + ' hours'" subtext="Billable this month">
          </dash-card-value>
        </dash-card>
      </div>
      <div class="col col-lg-3">
        <dash-card title="Financials">
          <dash-card-value :is-todo="true"
            :value="formatKr(financeCurrentResult)"
            subtext="Current result"
            :status="getThreshold(financeCurrentResult, thresholds.financeCurrentResult, true)"
          >
          </dash-card-value>
          <div class="row">
            <div class="col">
              <dash-card-value :is-todo="true"
                :small="true"
                :value="formatKr(financeAverageMonthlyIncome)"
                subtext="In/month (avg)"
                :status="getThreshold(financeAverageMonthlyIncome, thresholds.financeAverageMonthlyIncome, true)"
              >
              </dash-card-value>
            </div>
            <div class="col">
              <dash-card-value :is-todo="true"
                :small="true"
                :value="formatKr(financeAverageMonthlyExpenses)"
                subtext="Out/month (avg)"
                :status="getThreshold(financeAverageMonthlyExpenses, thresholds.financeAverageMonthlyExpenses, false)"
              >
              </dash-card-value>
            </div>
          </div>
        </dash-card>
      </div>
      <div class="col col-lg-3">
        <dash-card title="EONTYRE">
          <dash-card-value :is-todo="true" :value="eontyreAlerts" subtext="Alerts" :status="eontyreAlerts > 0 ? 'critical' : 'ok'">
          </dash-card-value>
          <dash-card-value :is-todo="true"
            :small="true"
            :value="eontyreErrors1h"
            :status="getThreshold(eontyreErrors1h, thresholds.eontyreErrors1h, false)"
            subtext="Errors 1H"
          >
          </dash-card-value>
        </dash-card>
      </div>
      <div class="col col-lg-3">
        <dash-card title="EONBIT">
          <dash-card-value :is-todo="true" :value="eonbitAlerts" subtext="Alerts" :status="eonbitAlerts > 0 ? 'critical' : 'ok'">
          </dash-card-value>
        </dash-card>
      </div>
    </div>

    <!-- Todos -->

    <h3>Todos</h3>

    <div class="row">
      <div class="col col-lg-3">
        <dash-card title="Merge Requests">
          <dash-card-value :is-todo="true"
            :value="gitMergeRequestsOpen"
            subtext="Open"
            :status="getThreshold(gitMergeRequestsOpen, thresholds.gitMergeRequestsOpen, false)"
          >
          </dash-card-value>
          <dash-card-value :is-todo="true"
            :small="true"
            :value="gitMergeRequestsOldestDays + ' days'"
            :status="getThreshold(gitMergeRequestsOldestDays, thresholds.gitMergeRequestsOldestDays, false)"
          >
            subtext="Oldest">
          </dash-card-value>
        </dash-card>
      </div>
      <div class="col col-lg-3">
        <dash-card title="Git Todos">
          <dash-card-value :is-todo="true"
            :value="gitTodosOpen"
            subtext="Open"
            :status="getThreshold(gitTodosOpen, thresholds.gitTodosOpen, false)"
          >
          </dash-card-value>
          <dash-card-value :is-todo="true"
            :small="true"
            :value="gitTodosOldest + ' days'"
            :status="getThreshold(gitTodosOldest, thresholds.gitTodosOldest, false)"
          >
            subtext="Oldest">
          </dash-card-value>
        </dash-card>
      </div>
      <div class="col col-lg-3">
        <dash-card title="Tickets">
          <dash-card-value :is-todo="true"
            :value="unsolvedTicketsOnMe"
            subtext="Unsolved on me"
            :status="getThreshold(unsolvedTicketsOnMe, thresholds.unsolvedTicketsOnMe, false)"
          >
          </dash-card-value>
        </dash-card>
      </div>
      <div class="col col-lg-3">
        <dash-card title="Emails">
          <dash-card-value :is-todo="true"
            :value="emailsInInbox"
            subtext="In INBOX"
            :status="getThreshold(emailsInInbox, thresholds.emailsInInbox, false)"
          >
          </dash-card-value>
          <div class="row">
            <div class="col">
              <dash-card-value :is-todo="true" :small="true" :value="emailsReceivedThisWeek" subtext="Recv. this week">
              </dash-card-value>
            </div>
            <div class="col">
              <dash-card-value :is-todo="true"
                :small="true"
                :value="emailsRepliedThisWeek"
                subtext="Replied this week"
                :status="emailsRepliedThisWeek > emailsReceivedThisWeek ? 'ok' : 'warning'"
              >
              </dash-card-value>
            </div>
          </div>
        </dash-card>
      </div>
    </div>

    <!-- EONTYRE -->

    <h3>EONTYRE</h3>

    <div class="row">
      <div class="col col-lg-3">
        <dash-card title="Tickets Open">
          <dash-card-value :is-todo="true"
            :value="eontyreTicketsOpen"
            subtext="Unsolved"
            :status="getThreshold(eontyreTicketsOpen, thresholds.eontyreTicketsOpen, false)"
          >
          </dash-card-value>
          <div class="row">
            <div class="col">
              <dash-card-value :is-todo="true" :small="true" :value="eontyreTicketsCreatedThisMonth" subtext="New this month">
              </dash-card-value>
            </div>
            <div class="col">
              <dash-card-value :is-todo="true" :small="true" :value="eontyreTicketsSolvedThisMonth" subtext="Solved this month">
              </dash-card-value>
            </div>
          </div>
        </dash-card>
      </div>
      <div class="col col-lg-3">
        <dash-card title="Tickets Response">
          <dash-card-value :is-todo="true"
            :value="eontyreTicketsAvgSolveTimeHours + ' hr'"
            subtext="Avg. solve time"
            :status="getThreshold(eontyreTicketsAvgSolveTimeHours, thresholds.eontyreTicketsAvgSolveTimeHours, false)"
          >
          </dash-card-value>
          <div class="row">
            <div class="col">
              <dash-card-value :is-todo="true"
                :value="eontyreTicketsAvgReplyTimeHours + ' hr'"
                subtext="Avg. reply time"
                :status="
                  getThreshold(eontyreTicketsAvgReplyTimeHours, thresholds.eontyreTicketsAvgReplyTimeHours, false)
                "
              >
              </dash-card-value>
            </div>
            <div class="col">
              <dash-card-value :is-todo="true"
                :small="true"
                :value="eontyreTicketsOldestDays + ' days'"
                subtext="Oldest"
                :status="getThreshold(eontyreTicketsOldestDays, thresholds.eontyreTicketsOldestDays, false)"
              >
              </dash-card-value>
            </div>
          </div>
        </dash-card>
      </div>
      <div class="col col-lg-6">
        <dash-tickets-distribution-chart></dash-tickets-distribution-chart>
      </div>
    </div>

    <!-- MAYBE -->

    <h3>Maybe</h3>

    <div class="row">
      <div class="col col-lg-6">
        - eon customers: count, avg fixed monthly income, new 12mon, new 6mon (for SE and NO) - aka "growth"
        <br />
        - SPX / BTC? :)
        <br />
        - (BK response times and uptime)
      </div>
    </div>
  </div>
</template>

<script>
  import DashCard from '../components/Dashboard/DashCard'
  import DashCardValue from '../components/Dashboard/DashCardValue'
  import DashTicketsDistributionChart from '../components/Dashboard/DashTicketsDistributionChart'
  import http from '@/utils/http'

  export default {
    components: {
      DashCardValue,
      DashCard,
      DashTicketsDistributionChart,
    },
    data() {
      return {
        thresholds: {
          workHours: [30, 40],
          financeCurrentResult: 100000,
          financeAverageMonthlyIncome: 70000,
          financeAverageMonthlyExpenses: 30000,
          emailsInInbox: [0, 20],
          unsolvedTicketsOnMe: [0, 5],
          gitMergeRequestsOpen: [0, 2],
          gitMergeRequestsOldestDays: [7, 14],
          gitTodosOpen: [0, 2],
          gitTodosOldestDays: [7, 14],
          eontyreErrors1h: [10, 30],
          eontyreTicketOpen: [10, 40],
          eontyreTicketsOldestDays: [10, 30],
          eontyreTicketsAvgReplyTimeHours: [1, 1],
          eontyreTicketsAvgSolveTimeHours: [1, 1],
        },
        workHoursThisMonth: 32,
        workHoursMonthlyAverage: 41,
        financeCurrentResult: -20000,
        financeAverageMonthlyIncome: 80000,
        financeAverageMonthlyExpenses: 40000,
        emailsInInbox: 1700,
        emailsReceivedThisWeek: 3,
        emailsRepliedThisWeek: 4,
        unsolvedTicketsOnMe: 28,
        gitMergeRequestsOpen: 3,
        gitMergeRequestsOldestDays: 100,
        gitTodosOpen: 4,
        gitTodosOldest: 101,
        eontyreAlerts: 0,
        eontyreErrors1h: 133,
        eontyreTicketsOpen: 210,
        eontyreTicketsOldestDays: 78,
        eontyreTicketsAvgReplyTimeHours: 2,
        eontyreTicketsAvgSolveTimeHours: 48,
        eontyreTicketsCreatedThisMonth: 100,
        eontyreTicketsSolvedThisMonth: 50,
        eonbitAlerts: 0,
      }
    },
    mounted() {
      http
        .get('/dashboard')
        .then((response) => {
          // this.data = response.data.data
          console.log('resp:', response.data.data)
        })
        .catch((err) => {
          console.error('Error fetching data:', err)
          // this.error = 'Error fetching data: ' + err
        })
    },
    methods: {
      formatKr(value) {
        return Math.round(value / 1000) + ' tKr'
      },
      getThreshold(value, thresholds, increasing) {
        if (increasing) {
          if (typeof thresholds === 'object') {
            if (value <= thresholds[0]) {
              return 'critical'
            } else if (value <= thresholds[1]) {
              return 'warning'
            } else {
              return 'ok'
            }
          } else {
            if (value >= thresholds) {
              return 'ok'
            } else {
              return 'critical'
            }
          }
        } else {
          if (typeof thresholds === 'object') {
            if (value <= thresholds[0]) {
              return 'ok'
            } else if (value <= thresholds[1]) {
              return 'warning'
            } else {
              return 'critical'
            }
          } else {
            if (value >= thresholds) {
              return 'critical'
            } else {
              return 'ok'
            }
          }
        }
      },
    },
  }
</script>

<style scoped lang="sass">
  h3
    border: 1px solid #ccc
    padding: 10px
    font-size: 22px
    margin: 20px
</style>
