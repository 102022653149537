<template>
  <div>
    <div :class="classes" @click="onClick">
      <div class="title">{{ title || 'No title' }}</div>
      <div class="content">
        <slot>
          <div style="text-align: center">No content</div>
        </slot>
      </div>
    </div>
    <b-modal v-if="clickable" v-model="isDialogVisible" size="xl" hide-footer>
      <slot name="dialog">No dialog</slot>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'DashCard',
    components: {},
    props: ['title', 'clickable'],
    data() {
      return {
        isDialogVisible: false,
      }
    },
    computed: {
      classes() {
        const result = ['dash-card']
        if (this.clickable) {
          result.push('clickable')
        }
        return result.join(' ')
      },
    },
    methods: {
      onClick() {
        if (!this.clickable) {
          return
        }
        this.isDialogVisible = !this.isDialogVisible
      },
    },
  }
</script>

<style lang="sass" scoped>
  .dash-card
    margin: 20px
    border: 1px solid #ccc
    border-radius: 10px

    &.clickable
      cursor: pointer

    .title
      padding: 10px
      text-align: center
      background: #dddddd
      border-radius: 8px 8px 0 0

    .content
      border-top: 1px solid #ccc
      min-height: 180px
</style>
