<template>
  <div class="days">
    <div v-for="item in items" :key="item.day" class="day">
      <span class="value">{{ item.value }}</span>
      <span class="dayname">{{ item.day }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WeekChart',
    props: ['items'], // [ { date, value, day } ]
    mounted() {
      console.log('items:', this.items)
      // foo
    },
  }
</script>

<style scoped>
  .days {
  }
  .day {
    width: 45px;
    display: inline-block;
    margin: 10px;
    text-align: center;
  }
  .value {
    height: 45px;
    font-size: 20px;
    line-height: 45px;
    vertical-align: middle;
    font-weight: bold;
    display: block;
    background: #eee;
    border: 1px solid #ddd;
  }
  .dayname {
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    padding-top: 2px;
    color: #888;
  }
  .day:last-child .value {
    border: 1px solid #28a;
  }
</style>
