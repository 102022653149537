import axios from 'axios/dist/axios.js'

let base = ''
if (document.location.href.match('localhost')) {
  base = 'http://localhost:9600'
} else {
  base = 'https://api.synapse.eonbit.com'
}
const instance = axios.create({
  baseURL: base,
  timeout: 15000,
})

instance.interceptors.request.use((config) => {
  const user = instance.getUser()
  if (user) {
    config.headers.common.Authorization = user.token
  }
  return config
})

instance.setUser = (user) => {
  instance.user = user
  localStorage.synapseUser = JSON.stringify(user)
}

instance.getUser = () => {
  if (!instance.user) {
    if (localStorage.synapseUser) {
      instance.user = JSON.parse(localStorage.synapseUser)
    }
  }
  return instance.user
}

instance.getBaseUrl = () => {
  return base
}

export default instance
