<template>
  <div>
    <input
      ref="input"
      v-model="query"
      :class="'input ' + (loading ? 'loading' : '')"
      type="text"
      :disabled="loading"
      @focusin="setFocus(true)"
      @focusout="setFocus(false)"
      @keyup.enter.prevent="onEnter"
    />
    <div v-show="loading" style="position: absolute">
      <font-awesome-icon icon="spinner" spin style="position: relative; top: -25px; left: 5px"></font-awesome-icon>
    </div>
    <div v-show="suggestions.length > 0 && focused" class="suggestions">
      <a v-for="option in suggestions" :key="option.id" @click="clickOption(option)">
        {{ option.name }}
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AutoComplete',
    props: ['value', 'options', 'alwaysShowSuggestions', 'disallowNew'],
    data() {
      return {
        query: '',
        selectedId: null,
        focused: false,
        clickedOption: null,
        loading: false,
        instantBlur: false,
      }
    },
    computed: {
      suggestions() {
        const result = []
        const seed = (this.query || '').toLowerCase().trim()
        if (seed) {
          for (let i = 0; i < this.options.length; i++) {
            const haystack = (this.options[i].name || '').toLowerCase().trim()
            if (haystack.indexOf(seed) !== -1) {
              result.push(this.options[i])
            }
          }
        } else {
          if (this.alwaysShowSuggestions) {
            return this.options
          }
        }
        return result
      },
    },
    watch: {
      value() {
        this.onExternalChange()
      },
    },
    mounted() {
      this.onExternalChange()
    },
    methods: {
      onExternalChange() {
        this.loading = false
        this.selectedId = null
        this.query = ''
        if (this.value) {
          this.selectedId = this.value
          this.query = this.getNameByOptionId(this.selectedId)
        }
      },
      setFocus(value) {
        if (value) {
          this.instantBlur = false
          this.focused = true
          this.clickedOption = null
        } else {
          if (this.instantBlur) {
            this.focused = false
            this.onChange()
          } else {
            setTimeout(() => {
              this.focused = false
              this.onChange()
            }, 150)
          }
        }
      },
      clickOption(option) {
        this.clickedOption = option
      },
      onEnter() {
        this.instantBlur = true
        this.$refs.input.blur()
        this.$emit('enter', this.$refs.input)
      },
      setSelected(option) {
        this.query = option.name
        if (this.selectedId !== option.id) {
          this.selectedId = option.id
          this.$emit('input', this.selectedId)
        }
      },
      onChange() {
        // Did we click an explicit option?

        if (this.clickedOption) {
          this.setSelected(this.clickedOption)
          return
        }

        // If query was empty, then we want to set blank

        if (!this.query) {
          this.setSelected({ id: null, name: '' })
          return
        }

        // Did we type full name of an option?

        let option = this.getOptionByName(this.query)
        if (option) {
          this.setSelected(option)
          return
        }

        // Found a single match by partial name?

        option = this.getOptionByPartialName(this.query)
        if (option) {
          this.setSelected(option)
          return
        }

        // Else, we're trying to add a new option
        // Which may be disallowed

        if (this.disallowNew) {
          // Not allowed, revert to previous
          this.query = this.getNameByOptionId(this.selectedId)
          return
        }

        // Or may be allowed

        this.loading = true
        this.$emit('new', this.query)
      },
      getNameByOptionId(id) {
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].id === id) {
            return this.options[i].name
          }
        }
        return ''
      },
      getOptionByName(name) {
        name = (name || '').toLowerCase().trim()
        for (let i = 0; i < this.options.length; i++) {
          if ((this.options[i].name || '').toLowerCase().trim() === name) {
            return this.options[i]
          }
        }
        return null
      },
      getOptionByPartialName(name) {
        const result = []
        name = (name || '').toLowerCase().trim()
        for (let i = 0; i < this.options.length; i++) {
          const haystack = (this.options[i].name || '').toLowerCase().trim()
          if (haystack.startsWith(name)) {
            result.push(this.options[i])
          }
        }
        if (result.length === 1) {
          return result[0]
        }
      },
      // todo: use this for search / get option, but try exact first if multiple when getting option
      normalize(value) {
        if (!value) {
          value = ''
        } else {
          value = '' + value
        }
        value = value.toLowerCase().trim()
        value = value.replace('ä', 'a')
        value = value.replace('å', 'a')
        value = value.replace('æ', 'a')
        value = value.replace('ø', 'o')
        value = value.replace('ö', 'o')
        return value
      }
    },
  }
</script>

<style lang="sass" scoped>
  .input
    &.loading
      padding-left: 25px
      border: 1px solid #ccc

  .suggestions
    border: 1px solid #aaa
    background: white
    z-index: 1000
    position: absolute
    margin-top: 3px
    min-width: 150px

    > a
      display: block
      padding: 10px
      border-bottom: 1px solid #eee

      &:last-child
        border-bottom: none

      &:hover
        background: #adf
</style>
