<template>
  <dash-card v-if="data && data.vouchers" :title="title" :clickable="true">
    <dash-card-value :thresholds="thresholds.missingVouchersCount" :value="data.voucherCount || '0'" subtext="Vouchers">
    </dash-card-value>
    <dash-card-value
      :small="true"
      :thresholds="thresholds.missingVouchersAge"
      :value="data.maxAgeDays || '0'"
      subtext="Oldest days"
    >
    </dash-card-value>
    <template #dialog>
      <div style="margin-top: 10px; margin-bottom: 25px">
        <b-row>
          <b-col>
            <b-checkbox v-model="hideDone">Hide done</b-checkbox>
          </b-col>
          <b-col style="text-align: right">
            <a href="https://www.notion.so/Regnskap-bilag-b580a69a06714a1a86e51fa914ae38c4" target="_blank">
              Where to find receipts
            </a>
          </b-col>
        </b-row>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Date</th>
            <th>Supplier</th>
            <th>Description</th>
            <th style="text-align: right">Amount</th>
            <th>Done</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody v-for="supplierName in supplierNames" :key="supplierName">
          <tr class="supplier-header">
            <td colspan="7">{{ supplierName }}</td>
          </tr>
          <tr v-for="v in getSupplierVouchers(supplierName)" :key="v._id">
            <td style="white-space: nowrap">{{ getVoucherNumber(v) }}</td>
            <td style="white-space: nowrap">{{ v.date }}</td>
            <td>{{ v.supplier_or_customer_name }}</td>
            <td>
              {{ v.description }}
              <div v-show="v.internal_comment" class="voucher-comment">
                {{ getShortComment(v.internal_comment) }}
              </div>
            </td>
            <td style="text-align: right">{{ v.amount }}</td>
            <td>
              <b-checkbox v-model="v.did_send_attachment" @change="onUpdateDidSendAttachment(v)"></b-checkbox>
            </td>
            <td>
              <a :class="getCommentClass(v)" @click="openCommentDialog(v)">Comment</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="margin-top: 25px; margin-bottom: 10px">
        <b-row>
          <b-col>
            <b-checkbox v-model="hideDone">Hide done</b-checkbox>
          </b-col>
          <b-col style="text-align: right">
            <a href="https://www.notion.so/Regnskap-bilag-b580a69a06714a1a86e51fa914ae38c4" target="_blank">
              Where to find receipts
            </a>
          </b-col>
        </b-row>
      </div>
    </template>
    <b-modal v-model="commentDialog" hide-footer size="l">
      <div>{{ commentDialogItem.date }}</div>
      <div>{{ commentDialogItem.supplier_or_customer_name }}</div>
      <div>{{ commentDialogItem.description }}</div>
      <div style="margin-top: 20px; margin-bottom: 20px">
        <b-textarea v-model="commentDialogComment" style="height: 200px"></b-textarea>
      </div>
      <div v-show="commentDialogError" style="color: red; margin-bottom: 20px">{{ commentDialogError }}</div>
      <div>
        <b-btn @click="commentDialogSave" style="margin-right: 20px">Save</b-btn>
        <b-btn @click="commentDialogCancel">Cancel</b-btn>
      </div>
    </b-modal>
  </dash-card>
</template>

<script>
  import DashCard from './DashCard'
  import DashCardValue from './DashCardValue'
  import http from '@/utils/http'

  export default {
    components: { DashCard, DashCardValue },
    props: ['title', 'data', 'thresholds'],
    data() {
      return {
        isDialogVisible: false,
        hideDone: false,
        commentDialog: false,
        commentDialogComment: '',
        commentDialogItem: {},
        commentDialogError: '',
      }
    },
    computed: {
      supplierNames() {
        if (!this.data) {
          return []
        }
        const result = []
        for (let i = 0; i < this.data.vouchers.length; i++) {
          const v = this.data.vouchers[i]
          if (this.hideDone && v.did_send_attachment) {
            continue
          }
          const n = v.supplier_or_customer_name || ''
          if (result.indexOf(n) === -1) {
            result.push(n)
          }
        }
        return result
      },
    },
    methods: {
      getSupplierVouchers(supplierName) {
        const result = []
        for (let i = 0; i < this.data.vouchers.length; i++) {
          const v = this.data.vouchers[i]
          if (this.hideDone && v.did_send_attachment) {
            continue
          }
          if (v.supplier_or_customer_name === supplierName) {
            result.push(v)
          }
        }
        return result
      },
      onUpdateDidSendAttachment(item) {
        http
          .post('/finance/postings/' + item._id + '/did-send-attachment?value=' + (item.did_send_attachment ? '1' : ''))
          .then((response) => {
            console.log('updated did-send:', response.data)
          })
          .catch((err) => {
            console.error('Error updating did-send:', err)
          })
      },
      getShortComment(value) {
        if (!value) {
          return ''
        }
        const max = 40
        if (value.length < max) {
          return value
        } else {
          return value.substr(0, max - 3) + '...'
        }
      },
      getCommentClass(item) {
        if (item.internal_comment) {
          return 'has-comment'
        } else {
          return 'no-comment'
        }
      },
      openCommentDialog(item) {
        this.commentDialog = true
        this.commentDialogItem = item
        this.commentDialogComment = item.internal_comment || ''
      },
      commentDialogSave() {
        http
          .post('/finance/postings/' + this.commentDialogItem._id + '/update-comment', { comment: this.commentDialogComment })
          .then((response) => {
            this.commentDialogItem.internal_comment = this.commentDialogComment
            this.commentDialog = false
            this.commentDialogItem = {}
            this.commentDialogComment = ''
          })
          .catch((err) => {
            console.error('Error updating comment:', err)
            this.commentDialogError = 'Error saving comment'
          })
      },
      commentDialogCancel() {
        this.commentDialog = false
        this.commentDialogItem = {}
        this.commentDialogComment = ''
      },
      getVoucherNumber(item) {
        if (!item) {
          return ''
        }
        const date = item.date.split('-')
        return item.voucher_number + '-' + date[0]
      },
    },
  }
</script>

<style lang="sass" scoped>
  .dash-card
    margin: 20px
    border: 1px solid #ccc
    border-radius: 10px

    &.clickable
      cursor: pointer

    .title
      padding: 10px
      text-align: center
      background: #dddddd
      border-radius: 8px 8px 0 0

    .content
      border-top: 1px solid #ccc
      min-height: 180px

  .supplier-header td
    background: #e8f2ff
    font-weight: bold

  a.has-comment
    color: orange
  a.no-comment
    color: #888

  .voucher-comment
    font-style: italic
    color: orange
</style>
