export const parseEstimate = (value) => {
  if (!value) {
    return 0
  }
  const pat = new RegExp('^([0-9.]+)(h|m|d|w)?$')
  const match = value.match(pat)
  if (match) {
    const value = parseFloat(match[1])
    if (isNaN(value)) {
      console.error('invalid estimate number: ' + match[1] + ' - will skip entry: ' + value)
      return null
    }
    switch (match[2]) {
      case 'h':
        return value
      case 'm':
        return value / 60
      case 'd':
        return value * 5
      case 'w':
        return value * 5 * 4
      case '':
      case undefined:
        return value
      default:
        console.error('invalid estimate suffix: ' + match[2] + ' - will skip entry: ' + value)
        return null
    }
  } else {
    console.error('invalid estimate format, will skip entry: ' + value)
    return null
  }
}
