<template>
  <div>
    <!-- Default "boards" (routes to newest of that type of board) -->

    <div class="section">
      <router-link :to="{ name: 'Todo', params: { type: 'era', id: 'latest' } }">Long term</router-link>
      <router-link :to="{ name: 'Todo', params: { type: 'sprint', id: 'latest' } }">Sprint</router-link>
      <router-link :to="{ name: 'Todo', params: { type: 'week', id: 'latest' } }">Week</router-link>
      <router-link :to="{ name: 'Todo', params: { type: 'day', id: 'latest' } }">Day</router-link>
    </div>

    <div class="section">
      <router-link :to="allTasksLink">All tasks</router-link>
    </div>

    <!-- My views -->

    <div class="section">
      <router-link
        v-for="board in customBoards"
        :key="board.id"
        :to="{ name: 'Todo', params: { type: 'board', id: board.id } }"
      >
        {{ board.name }}
        <span class="count">{{ board.count }}</span>
      </router-link>
      <div style="margin-top: 10px">
        <b-btn @click="showNewBoard()">New board</b-btn>
      </div>
    </div>

    <!-- Various -->

    <div class="section">
      <router-link :to="{ name: 'TodoBoards', query: { type: 'sprint' } }"> All sprints </router-link>
      <router-link :to="{ name: 'TodoBoards', query: { type: 'week' } }"> All weeks </router-link>
      <router-link :to="{ name: 'TodoBoards', query: { type: 'era' } }"> All eras </router-link>
      <router-link :to="{ name: 'TodoBoards', query: { type: '' } }"> All boards </router-link>
      <!--
      <a>New task</a>
      <a>Settings</a>
      -->
    </div>

    <!-- Links -->

    <div class="section">
      <div>Routines:</div>
      <a>Status meeting</a>
      <a>Incoming task</a>
      <a>Planning day</a>
      <a>Work day</a>
      <a>Etc.</a>
    </div>

    <!-- New board -->
    <b-modal v-model="newBoardVisible" title="New board" hide-footer>
      <todo-new-board :key="newBoardId" @close="hideNewBoard" @saved="onSavedBoard"></todo-new-board>
    </b-modal>
  </div>
</template>

<script>
  import TodoNewBoard from '@/components/TodoNewBoard'

  export default {
    components: {
      TodoNewBoard,
    },
    props: ['boards'],
    data() {
      return {
        newBoardId: '',
      }
    },
    computed: {
      newBoardVisible() {
        return this.newBoardId !== ''
      },
      allTasksLink() {
        return {
          name: 'Todo',
          params: { type: 'tasks', id: 'tasks' },
        }
      },
      customBoards() {
        // In middle left section we show "user defined" boards, while at the very top
        // we show "Long term, Sprint, Week" which can have multiple instances over time,
        // but only one of each type for a given time. So those top 3 "specials" we do
        // not want to also repeat under the "user defined" section,
        // so filter out only those with type=board
        const result = []
        for (let i = 0; i < this.boards.length; i++) {
          const b = this.boards[i]
          if (b.type === 'board') {
            result.push(b)
          }
        }
        return result
      },
    },
    methods: {
      showNewBoard() {
        this.newBoardId = '' + new Date().getTime()
      },
      hideNewBoard() {
        this.newBoardId = ''
      },
      onSavedBoard(board) {
        this.hideNewBoard()
        this.$emit('update', {
          action: 'new',
          type: 'board',
          data: board,
        })
      },
    },
  }
</script>

<style lang="sass" scoped>
  .todo-main
    padding: 10px 10px 10px 0

  .section
    margin-bottom: 15px
    padding-bottom: 15px
    border-bottom: 1px solid #ccc

    a
      display: block
      padding-bottom: 5px

  .count
    font-size: 11px
    line-height: 18px
    text-align: center
    width: 25px
    height: 25px
    background: #ddd
    border-radius: 15px
    padding: 3px
    display: inline-block
    margin-left: 5px
    position: absolute
    right: 40px
</style>
