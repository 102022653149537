<template>
  <router-link :to="link" class="box-link">
    <dash-card :title="title">
      <div class="row">
        <div class="col">
          <dash-card-value
            :small="true"
            :threshold-increasing="true"
            :thresholds="thresholds.currentResult"
            :value="data.result"
            format="kr"
            subtext="Result"
          >
          </dash-card-value>
        </div>
        <div class="col">
          <dash-card-value
            :small="true"
            :threshold-increasing="true"
            :thresholds="thresholds.bankAccountBalance"
            :value="data.bankAccountBalance"
            format="kr"
            subtext="Bank"
          >
          </dash-card-value>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dash-card-value
            :small="true"
            :threshold-increasing="true"
            :thresholds="thresholds.averageMonthlyIncome"
            :value="data.averageMonthlyIncome"
            format="kr"
            subtext="In/month (avg)"
          >
          </dash-card-value>
        </div>
        <div class="col">
          <dash-card-value
            :small="true"
            :thresholds="thresholds.averageMonthlyExpenses"
            :value="data.averageMonthlyExpenses"
            format="kr"
            subtext="Out/month (avg)"
          >
          </dash-card-value>
        </div>
      </div>
    </dash-card>
  </router-link>
</template>

<script>
  import DashCard from './DashCard'
  import DashCardValue from './DashCardValue'

  export default {
    components: { DashCard, DashCardValue },
    props: ['title', 'data', 'thresholds', 'accountId'],
    computed: {
      link() {
        return {
          name: 'FinanceBudget',
          query: {
            accountId: this.accountId,
            showAmount: '1',
            period: 'all',
          },
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
  a.box-link
    text-decoration: none
    color: inherit
    &:hover
      text-decoration: none
      color: inherit
</style>
