<template>
  <div>
    <div v-show="error" style="border: 1px solid red; color: red; padding: 10px">
      {{ error }}
    </div>
    <div>
      <font-awesome-icon v-show="isLoading" icon="spinner" spin></font-awesome-icon>
      <pre>{{ data }}</pre>
    </div>
  </div>
</template>

<script>
  import backend from '@/utils/TaskBackend'
  export default {
    props: ['id'],
    data() {
      return {
        data: null,
        isLoading: false,
        error: '',
      }
    },
    mounted() {
      this.load()
    },
    methods: {
      load() {
        this.error = ''
        this.isLoading = true
        backend.getTask(this.id).then(
          (task) => {
            this.data = task
            this.isLoading = false
          },
          (error) => {
            this.error = error
            this.isLoading = false
          },
        )
      },
      close() {
        this.$emit('close')
      },
    },
  }
</script>

<style lang="sass" scoped>
</style>
