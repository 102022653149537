<template>
  <canvas width="400" height="120"></canvas>
</template>

<script>
  import Chart from 'chart.js/dist/Chart.bundle.js'

  export default {
    name: 'LineChart',
    props: ['labels', 'values', 'thresholds', 'query'],
    watch: {
      values(newData) {
        this._chart.destroy()
        this.renderChart()
      },
    },
    mounted() {
      this.renderChart()
    },
    methods: {
      renderChart() {
        const okLine = {
          label: 'OK threshold',
          data: [],
          borderWidth: 1,
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(0, 200, 0, 1)',
          borderDash: [5, 5],
          pointRadius: 0,
        }
        const warningLine = {
          label: 'Warning threshold',
          data: [],
          borderWidth: 1,
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(255, 50, 50, 1)',
          borderDash: [5, 5],
          pointRadius: 0,
        }
        if (this.thresholds.levels) {
          for (let i = 0; i < this.values.length; i++) {
            okLine.data[i] = this.thresholds.levels[0]
            warningLine.data[i] = this.thresholds.levels[1]
          }
        }
        this._chart = new Chart(this.$el, {
          type: 'line',
          data: {
            legend: {
              display: false,
            },
            labels: this.labels,
            datasets: [
              {
                label: 'Data',
                data: this.values,
                backgroundColor: 'rgba(54, 162, 235, 0.3)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
              },
              okLine,
              warningLine,
            ],
          },
          options: {
            onClick: (evt, elems) => {
              if (!elems || !elems.length) {
                return
              }
              const index = elems[0]._index
              const label = this.labels[index]
              const period = label.split('-')
              const year = parseInt(period[0])
              const month = parseInt(period[1])
              const start = new Date(year + '-' + month + '-01')
              let end
              if (start.getMonth() === 11) {
                end = new Date(start.getFullYear() + 1, 0, 1)
              } else {
                end = new Date(start.getFullYear(), start.getMonth() + 1, 1)
              }
              const formatDate = (dt) => {
                return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
              }
              const link = {
                name: 'TicketsList',
                params: {
                  query: this.query,
                  start: formatDate(start),
                  end: formatDate(end),
                },
              }
              this.$router.push(link)
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            legend: {
              display: false,
              /*
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel
              }
            }
*/
            },
          },
        })
      },
    },
  }

  /*
new Vue({
  el: '#app',
  data: {
    message: 'Vue.js & Chart.js',
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    votes: [12, 19, 3, 5, 2, 15]
  }
});
*/
</script>
