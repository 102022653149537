<template>
  <div>
    <div :class="'status-' + status">
      <input
        v-model="inputValue"
        class="input"
        type="text"
        @enter="(...args) => $emit('enter', ...args)"
        @focusin="focus = true"
        @focusout="update"
      />
      <a v-show="canShowLink" target="_blank" :href="realValue" class="link" :title="title">
        <font-awesome-icon icon="link"></font-awesome-icon>
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['value', 'issue'],
    data() {
      return {
        inputValue: '',
        realValue: '',
        focus: false,
      }
    },
    computed: {
      canShowLink() {
        return !this.focus && this.realValue
      },
      status() {
        if (this.issue) {
          if (this.issue.state === 'closed') {
            return 'done'
          } else {
            return 'todo'
          }
        }
        return ''
      },
      title() {
        if (this.issue) {
          return this.issue.title || ''
        }
        return ''
      },
    },
    watch: {
      value() {
        this.parseAndUpdate(this.value)
      },
    },
    mounted() {
      this.parseAndUpdate(this.value)
    },
    methods: {
      update() {
        this.parseAndUpdate(this.inputValue)
        if (this.realValue !== this.value) {
          this.$emit('input', this.realValue)
        }
        this.focus = false
      },
      parseAndUpdate(value) {
        const data = this.parse(value)
        this.inputValue = data.label
        this.realValue = data.link
      },
      parse(value) {
        // Empty

        if (!value) {
          return {
            link: '',
            value: '',
          }
        }

        // Prepare

        let match
        const pIssueLink = new RegExp('^https://git.eonbit.com/(.+)/issues/(.+)$')
        const pTicketLink = new RegExp('^https://eontyre.freshdesk.com/a/tickets/(.+)$')
        const pCode = new RegExp('^([BFWT])-([0-9]+)$')

        // Gitlab issue link

        match = value.match(pIssueLink)
        if (match) {
          let prefix = 'X'
          switch (match[1]) {
            case 'eontyre/application':
            case 'eontyre/application/-':
              prefix = 'B'
              break
            case 'eontyre/vue-front-end':
            case 'eontyre/vue-front-end/-':
              prefix = 'F'
              break
            case 'eontyre/webshop':
            case 'eontyre/webshop/-':
              prefix = 'W'
              break
          }
          return {
            link: value,
            label: prefix + '-' + match[2],
          }
        }

        // Freshdesk ticket link

        match = value.match(pTicketLink)
        if (match) {
          return {
            link: value,
            label: 'T-' + match[1],
          }
        }

        // Code

        match = value.match(pCode)
        if (match) {
          let project = ''
          switch (match[1]) {
            case 'T':
              return {
                link: 'https://eontyre.freshdesk.com/a/tickets/' + match[2],
                label: value,
              }
            case 'B':
              project = 'eontyre/application'
              break
            case 'F':
              project = 'eontyre/vue-front-end'
              break
            case 'W':
              project = 'eontyre/webshop'
              break
            default:
              console.error('Unexpected code prefix: ' + match[1])
          }
          if (project) {
            return {
              link: 'https://git.eonbit.com/' + project + '/-/issues/' + match[2],
              label: value,
            }
          }
        }

        // Other links

        if (value.indexOf('://') === -1) {
          value = 'https://' + value
        }

        return {
          link: value,
          label: value,
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
  input.input
    width: 60% !important

    &:focus-visible
      width: 100% !important

  .link
    width: 30%
    display: inline-block
    text-align: center
    border-radius: 3px

    &:hover
      background: #007bff
      color: white

  .status-done
    .link
      background: #55cc99
      color: white
</style>
