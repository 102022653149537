<template>
  <div class="task-list">
    <!-- Loading -->
    <div v-show="isSaving" class="loading">
      <font-awesome-icon icon="spinner" spin></font-awesome-icon>
    </div>

    <!-- Error -->
    <div v-show="error" class="error-message" @click="error = ''">
      {{ error }}
    </div>

    <!-- Groups -->

    <draggable v-bind="groupDragOptions" :list="groupsInternal" handle=".group-handle" @update="onDragGroupUpdate">
      <div v-for="group in groupsInternal" :id="'group-' + group.id" :key="group.id" class="group-container">
        <!-- Group header -->

        <div class="group-header">
          <a class="toggle-group-expand" @click="groupToggleCollapsed(group)">
            <font-awesome-icon :icon="group.collapsed ? 'caret-right' : 'caret-down'"></font-awesome-icon>
          </a>
          <input
            v-model="group.name"
            type="text"
            :disabled="group.isVirtual"
            @change="onGroupFieldChange('name', group)"
          />
          <span class="actions-container">
            <span v-show="!group.isVirtual" class="actions">
              <a v-show="isActionVisible('group-drag')" class="action-drag group-handle" title="Drag">
                <font-awesome-icon icon="grip-horizontal"></font-awesome-icon>
              </a>
              <a
                v-show="isActionVisible('group-delete')"
                class="action-delete"
                title="Delete"
                @click="deleteGroup(group.id)"
              >
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </a>
            </span>
          </span>
          <span class="selection-actions-container">
            <span v-show="hasSelection" class="selection-actions actions">
              <span class="prefix"> {{ checked.length }} items selected: </span>
              <a v-show="isActionVisible('delete')" class="action-delete" title="Delete" @click="selectionDelete()">
                <font-awesome-icon icon="trash"></font-awesome-icon>
              </a>
              <a
                v-show="isActionVisible('board')"
                class="action-board"
                title="Add to selected board"
                @click="selectionToBoard()"
              >
                <font-awesome-icon icon="list"></font-awesome-icon>
              </a>
              <a v-show="isActionVisible('done')" class="action-done" title="Set done" @click="selectionDone()">
                <font-awesome-icon icon="check-circle"></font-awesome-icon>
              </a>
            </span>
          </span>
        </div>

        <div v-show="group.collapsed" class="tr tr-collapsed"> {{ group.tasks.length }} tasks</div>

        <!-- Header row -->

        <div v-show="!group.collapsed" class="tr tr-header">
          <div v-for="field in activeFields" :key="field.id" :class="getHeaderClass(field.id)">
            <div v-if="field.is === 'selector'">
              <b-checkbox v-model="group.selector" @input="onGroupSelector(group)"></b-checkbox>
            </div>
            <div v-if="!field.is || field.is !== 'selector'">
              {{ field.name }}
            </div>
          </div>
        </div>

        <!-- Data rows -->

        <draggable
          v-show="!group.collapsed"
          v-bind="taskDragOptions"
          :list="group.tasks"
          handle=".task-handle"
          @add="onDragTaskAdd"
          @update="onDragTaskUpdate"
        >
          <transition-group name="flip-list" type="transition">
            <div v-for="task in group.tasks" :id="'task-' + task.id" :key="task.id" :class="getRowClass(task)">
              <div v-for="field in activeFields" :key="field.id" :class="getValueClass(field.id, task)">
                <div v-if="field.is === 'selector'">
                  <b-checkbox v-show="!task.isEmptyList" v-model="checked" :value="task.id"></b-checkbox>
                </div>
                <div v-if="field.is === 'TaskFieldDescription'">
                  <div v-show="showSprintIcon || showWeekIcon" style="position: relative">
                    <div class="sprint-week-icon-container">
                      <div v-show="showWeekIcon && task.inWeek" class="week-icon">
                        <font-awesome-icon icon="calendar-week"></font-awesome-icon>
                      </div>
                      <div v-show="showSprintIcon && task.inSprint" class="sprint-icon">
                        <font-awesome-icon icon="running"></font-awesome-icon>
                      </div>
                      <div v-show="showWeekIcon && task.inDay" class="day-icon">
                        <font-awesome-icon icon="hammer"></font-awesome-icon>
                      </div>
                    </div>
                  </div>
                  <div :style="'padding-right: ' + ((showSprintIcon ? 35 : 0) + (showWeekIcon ? 55 : 0)) + 'px'">
                    <div v-show="task.isEmptyList">
                      {{ task.description }}
                    </div>
                    <task-field-description
                      v-show="!task.isEmptyList"
                      v-model="task[field.id]"
                      @enter="focusNextInput"
                      @input="onTaskFieldChange(task, 'description')"
                    ></task-field-description>
                  </div>
                </div>
                <div v-if="field.is === 'TaskFieldAssignee'">
                  <task-field-assignee
                    v-show="!task.isEmptyList"
                    v-model="task[field.id]"
                    :options="assignees"
                    @enter="focusNextInput"
                    @input="onTaskFieldChange(task, 'assigneeId')"
                    @new="(ev) => newAssignee(ev, task)"
                  ></task-field-assignee>
                </div>
                <div v-if="field.is === 'TaskFieldStatus'">
                  <task-field-status
                    v-show="!task.isEmptyList"
                    v-model="task[field.id]"
                    :options="statuses"
                    @enter="focusNextInput"
                    @input="onTaskFieldChange(task, 'status')"
                  ></task-field-status>
                </div>
                <div v-if="field.is === 'TaskFieldEstimate'">
                  <task-field-estimate
                    v-show="!task.isEmptyList"
                    v-model="task[field.id]"
                    @enter="focusNextInput"
                    @input="onTaskFieldChange(task, 'estimate')"
                  ></task-field-estimate>
                </div>
                <div v-if="field.is === 'TaskFieldPriority'">
                  <task-field-priority
                    v-show="!task.isEmptyList"
                    v-model="task[field.id]"
                    :options="priorities"
                    @enter="focusNextInput"
                    @input="onTaskFieldChange(task, 'priority')"
                  ></task-field-priority>
                </div>
                <div v-if="field.is === 'TaskFieldTrack'">
                  <task-field-track
                    v-show="!task.isEmptyList"
                    v-model="task[field.id]"
                    :options="tracks"
                    @enter="focusNextInput"
                    @input="onTaskFieldChange(task, 'trackId')"
                  ></task-field-track>
                </div>
                <div v-if="field.is === 'TaskFieldCustomer'">
                  <task-field-customer
                    v-show="!task.isEmptyList"
                    v-model="task[field.id]"
                    :options="customers"
                    @enter="focusNextInput"
                    @input="onTaskFieldChange(task, 'customerId')"
                    @new="(ev) => newCustomer(ev, task)"
                  ></task-field-customer>
                </div>
                <div v-if="field.is === 'TaskFieldLink'">
                  <task-field-link
                    v-show="!task.isEmptyList"
                    v-model="task[field.id]"
                    :issue="task.issue"
                    @enter="focusNextInput"
                    @input="onTaskFieldChange(task, 'reference')"
                  ></task-field-link>
                </div>
                <div v-if="field.is === 'TaskFieldGroup'">
                  <task-field-group
                    v-show="!task.isEmptyList"
                    v-model="task[field.id]"
                    @enter="focusNextInput"
                    @input="onTaskFieldChange(task, 'groupId')"
                  ></task-field-group>
                </div>
                <div v-if="field.is === 'TaskFieldBucket'">
                  <task-field-bucket
                    v-show="!task.isEmptyList"
                    v-model="task[field.id]"
                    :options="buckets"
                    @enter="focusNextInput"
                    @input="onTaskFieldChange(task, 'bucket')"
                  ></task-field-bucket>
                </div>
                <div v-if="field.is === 'boards'" class="read-only-value">
                  <div v-for="id in task.allBoardIds" :key="id">{{ getBoardName(id) }}</div>
                </div>
                <div v-if="!field.is" class="read-only-value">
                  {{ task[field.id] }}
                </div>
                <div v-if="field.is === 'actions'">
                  <div v-show="!task.isEmptyList">
                    <a v-show="isActionVisible('drag')" class="action-drag task-handle" title="Drag">
                      <font-awesome-icon icon="grip-horizontal"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('detach')"
                      class="action-detach"
                      title="Detach"
                      @click="deleteTask(task, group.id, true)"
                    >
                      <font-awesome-icon icon="unlink"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('delete')"
                      class="action-delete"
                      title="Delete"
                      @click="deleteTask(task, group.id, false)"
                    >
                      <font-awesome-icon icon="trash"></font-awesome-icon>
                    </a>
                    <a v-show="isActionVisible('done')" class="action-done" title="Set done" @click="doneTask(task)">
                      <font-awesome-icon icon="check-circle"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('clone')"
                      class="action-clone"
                      title="Clone"
                      @click="cloneTask(task, group.id)"
                    >
                      <font-awesome-icon icon="copy"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('board')"
                      class="action-board"
                      title="Add to selected board"
                      @click="toSelectedBoard(task)"
                    >
                      <font-awesome-icon icon="list"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('backlog')"
                      class="action-backlog"
                      title="Move to backlog"
                      @click="toBacklog(task, group.id)"
                    >
                      <font-awesome-icon icon="folder"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('incoming')"
                      class="action-incoming"
                      title="Move to incoming"
                      @click="toIncoming(task, group.id)"
                    >
                      <font-awesome-icon icon="inbox"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('sprint')"
                      class="action-sprint"
                      title="Toggle in sprint"
                      @click="toggleTaskInBoardType(task, 'sprint')"
                    >
                      <font-awesome-icon icon="running"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('week')"
                      class="action-week"
                      title="Toggle in week"
                      @click="toggleTaskInBoardType(task, 'week')"
                    >
                      <font-awesome-icon icon="calendar-week"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('day')"
                      class="action-day"
                      title="Toggle in day"
                      @click="toggleTaskInBoardType(task, 'day')"
                    >
                      <font-awesome-icon icon="hammer"></font-awesome-icon>
                    </a>
                    <a
                      v-show="isActionVisible('show')"
                      class="action-show"
                      title="Show task details"
                      @click="showTaskModal(task)"
                    >
                      <font-awesome-icon icon="eye"></font-awesome-icon>
                    </a>
                    <a v-show="isActionVisible('ping')" class="action-ping" title="Ping task" @click="pingTask(task)">
                      <font-awesome-icon icon="flag"></font-awesome-icon>
                    </a>
                  </div>
                  &nbsp;
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>

        <!-- New / sum -->

        <div v-show="!group.collapsed" class="tr tr-add">
          <div v-for="field in activeFields" :key="field.id" :class="getValueClass(field.id)">
            <div v-if="field.is === 'selector'"> &nbsp;</div>
            <div v-if="field.is === 'TaskFieldDescription'">
              <task-field-description
                :clear-on-blur="true"
                placeholder="+ New task"
                @input="(ev, ctrl) => newTask(ev, group.id, ctrl)"
              ></task-field-description>
            </div>
            <div v-if="field.is === 'TaskFieldAssignee'"> &nbsp;</div>
            <div v-if="field.is === 'TaskFieldStatus'">
              <div class="status-summary">
                <div :style="'width: ' + getGroupStatusPercent(group.id) + '%'" class="status-summary-progress"></div>
              </div>
            </div>
            <div v-if="field.is === 'TaskFieldEstimate'">
              <div class="estimate-summary">
                {{ getGroupEstimateSummary(group.id) }}
              </div>
            </div>
            <div v-if="field.is === 'TaskFieldPriority'"> &nbsp;</div>
            <div v-if="field.is === 'TaskFieldTrack'"> &nbsp;</div>
            <div v-if="field.is === 'TaskFieldCustomer'"> &nbsp;</div>
            <div v-if="field.is === 'TaskFieldLink'"> &nbsp;</div>
            <div v-if="field.is === 'TaskFieldGroup'"> &nbsp;</div>
            <div v-if="field.is === 'actions'"> &nbsp;</div>
            <div v-if="!field.is"> &nbsp;</div>
          </div>
        </div>
      </div>
    </draggable>

    <!-- New group -->

    <div>
      <b-btn variant="outline-primary" @click="newGroup">
        <font-awesome-icon icon="plus" size="xs"></font-awesome-icon>
        New group
      </b-btn>
    </div>

    <!-- Task dialog -->
    <b-modal :key="taskModal.windowId" v-model="taskModal.visible" size="xl" title="Task details">
      <todo-task-details :id="taskModal.taskId"></todo-task-details>
    </b-modal>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'

  import TaskFieldDescription from '@/components/Task/TaskFieldDescription'
  import TaskFieldAssignee from '@/components/Task/TaskFieldAssignee'
  import TaskFieldStatus from '@/components/Task/TaskFieldStatus'
  import TaskFieldPriority from '@/components/Task/TaskFieldPriority'
  import TaskFieldEstimate from '@/components/Task/TaskFieldEstimate'
  import TaskFieldTrack from '@/components/Task/TaskFieldTrack'
  import TaskFieldCustomer from '@/components/Task/TaskFieldCustomer'
  import TaskFieldLink from '@/components/Task/TaskFieldLink'
  import TaskFieldGroup from '@/components/Task/TaskFieldGroup'
  import TaskFieldBucket from '@/components/Task/TaskFieldBucket'
  import TodoTaskDetails from '@/components/TodoTaskDetails'
  import { Assignee, Customer, Group, Task } from '@/models/TaskModels'
  import backend from '@/utils/TaskBackend'
  import { parseEstimate } from '@/utils/estimate'

  export default {
    name: 'TaskList',
    components: {
      draggable,
      TaskFieldDescription,
      TaskFieldAssignee,
      TaskFieldStatus,
      TaskFieldPriority,
      TaskFieldEstimate,
      TaskFieldTrack,
      TaskFieldCustomer,
      TaskFieldLink,
      TaskFieldGroup,
      TaskFieldBucket,
      TodoTaskDetails,
    },

    // Props

    props: [
      'boardId',
      'groups',
      'fields',
      'actions',
      'assignees',
      'customers',
      'statuses',
      'priorities',
      'tracks',
      'buckets',
      'boards',
      'addToBoardId',
      'noConfirmations',
    ],

    // Data

    data() {
      return {
        error: '',
        isSaving: false,
        taskModal: {
          visible: false,
          windowId: 0,
          taskId: null,
        },
        defaults: {
          fields: [
            'selector',
            'description',
            'assigneeId',
            'status',
            'estimate',
            'priority',
            'trackId',
            'customerId',
            'bucket',
            'boards',
            'reference',
            'createAt',
            'actions',
          ],
          // tuba
          actions: ['group-drag', 'group-delete', 'drag', 'detach', 'done', 'day', 'week', 'ping'],
        },
        nextNewId: 1,
        configuredFields: [
          { id: 'selector', is: 'selector' },
          { id: 'description', name: 'Description', is: 'TaskFieldDescription' },
          { id: 'assigneeId', name: 'Assignee', is: 'TaskFieldAssignee' },
          { id: 'status', name: 'Status', is: 'TaskFieldStatus' },
          { id: 'priority', name: 'Priority', is: 'TaskFieldPriority' },
          { id: 'estimate', name: 'Estimate', is: 'TaskFieldEstimate' },
          { id: 'customerId', name: 'Customer', is: 'TaskFieldCustomer' },
          { id: 'reference', name: 'Link', is: 'TaskFieldLink' },
          { id: 'trackId', name: 'Track', is: 'TaskFieldTrack' },
          { id: 'bucket', name: 'Bucket', is: 'TaskFieldBucket' },
          { id: 'group', name: 'Group', is: 'TaskFieldGroup' },
          { id: 'boards', name: 'Boards', is: 'boards' },
          { id: 'pings', name: 'Pings' },
          { id: 'doneAt', name: 'Done' },
          { id: 'createAt', name: 'Created' },
          { id: 'actions', name: '', is: 'actions' },
        ],
        checked: [],
        groupsInternal: [],
      }
    },

    // Computed

    computed: {
      // Visible fields

      activeFields() {
        const result = []
        const fields = this.fields && this.fields.length > 0 ? this.fields : this.defaults.fields
        for (let i = 0; i < fields.length; i++) {
          const f1 = fields[i]
          for (let j = 0; j < this.configuredFields.length; j++) {
            const f2 = this.configuredFields[j]
            if (f1 === f2.id) {
              result.push(f2)
            }
          }
        }
        return result
      },

      showSprintIcon() {
        return this.fields.indexOf('sprintIcon') !== -1
      },

      showWeekIcon() {
        return this.fields.indexOf('weekIcon') !== -1
      },

      // Selection

      hasSelection() {
        return this.checked.length > 0
      },

      // Drag'n'drop options

      taskDragOptions() {
        return {
          animation: 200,
          group: 'tasks',
          disabled: false,
          ghostClass: 'ghost',
        }
      },
      groupDragOptions() {
        return {
          animation: 200,
          group: 'groups',
          disabled: false,
          ghostClass: 'ghost',
        }
      },
    },

    // Watchers

    watch: {
      groups: {
        handler() {
          this.onGroupsInput()
        },
        deep: true,
      },
    },

    // Mounted

    mounted() {
      this.onGroupsInput()
      this.updatePlaceholderTasks()
    },

    // Methods

    methods: {
      // Groups & tasks data

      onGroupsInput() {
        // Groups prop changed from outside, so update internal model
        this.groupsInternal = []
        if (this.groups && this.groups.length > 0) {
          for (let i = 0; i < this.groups.length; i++) {
            this.groupsInternal.push(new Group(this.groups[i]))
          }
        }
      },

      getGroup(id) {
        if (!id) {
          return null
        }
        for (let i = 0; i < this.groupsInternal.length; i++) {
          if (this.groupsInternal[i].id === id) {
            return this.groupsInternal[i]
          }
        }
        return null
      },
      getTask(id) {
        if (!id) {
          return null
        }
        id = '' + id
        for (let i = 0; i < this.groupsInternal.length; i++) {
          for (let j = 0; j < this.groupsInternal[i].tasks.length; j++) {
            const t = this.groupsInternal[i].tasks[j]
            if ('' + t.id === id) {
              return t
            }
          }
        }
        return null
      },
      getGroupTaskIsIn(id) {
        if (!id) {
          return null
        }
        id = '' + id
        for (let i = 0; i < this.groupsInternal.length; i++) {
          for (let j = 0; j < this.groupsInternal[i].tasks.length; j++) {
            const t = this.groupsInternal[i].tasks[j]
            if ('' + t.id === id) {
              return this.groupsInternal[i]
            }
          }
        }
        return null
      },

      // Write data

      emitUpdate(action, type, data, onNextTick) {
        // Let external know when we modified data from props that must be updated
        this.$emit('update', {
          action,
          type,
          data,
        })
        if (onNextTick) {
          this.$nextTick(() => {
            onNextTick()
          })
        }
      },

      newAssignee(name, task) {
        this.isSaving = true
        backend.newAssignee(new Assignee({ name })).then(
          (assignee) => {
            this.emitUpdate('new', 'assignee', assignee, () => {
              task.assigneeId = assignee.id
              this.onTaskFieldChange(task, 'assigneeId')
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      newCustomer(name, task) {
        this.isSaving = true
        backend.newCustomer(new Customer({ name })).then(
          (customer) => {
            this.emitUpdate('new', 'customer', customer, () => {
              task.customerId = customer.id
              this.onTaskFieldChange(task, 'customerId')
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      newGroup() {
        this.isSaving = true
        const payload = {
          name: 'New group',
          boardId: this.boardId,
        }
        backend.newGroup(new Group(payload)).then(
          (group) => {
            this.emitUpdate('new', 'group', group, () => {
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      deleteGroup(groupId) {
        const g = this.getGroup(groupId)
        if (!g) {
          return
        }
        if (!this.noConfirmations) {
          if (!confirm('Tasks will be kept, but disconnected from group (and hence from board). Are you sure?')) {
            return
          }
        }
        this.isSaving = true
        backend.deleteGroup(groupId).then(
          () => {
            this.emitUpdate('delete', 'group', g, () => {
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      newTask(description, groupId, isAddMoreModifierPressed) {
        this.isSaving = true
        backend.newTask(new Task({ description }), groupId).then(
          (task) => {
            task.groupId = groupId // needed by emitUpdate => onUpdate
            this.emitUpdate('new', 'task', task, () => {
              this.focusAfterNewTask(task.id, isAddMoreModifierPressed)
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      cloneTask(task, groupId) {
        this.isSaving = true
        const task2 = new Task(task)
        task2.description += ' (copy)'
        backend.newTask(task2, groupId).then(
          (task) => {
            task.groupId = groupId // needed by emitUpdate => onUpdate
            this.emitUpdate('new', 'task', task, () => {
              this.focusAfterNewTask(task.id, false)
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      deleteTask(task, groupId, isDetach, cb = null) {
        if (!this.noConfirmations) {
          if (!confirm('Are you sure?')) {
            return
          }
        }
        this.isSaving = true
        backend.deleteTask(task.id, groupId, isDetach).then(
          () => {
            this.emitUpdate('delete', 'task', task, () => {
              if (cb) {
                cb()
              } else {
                this.isSaving = false
              }
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      doneTask(task, cb = null) {
        this.isSaving = true
        backend.updateTaskField(task.id, 'status', 'Done').then(
          (_task) => {
            task.status = _task.status
            task.done_at = _task.done_at
            task.close_at = _task.close_at
            this.emitUpdate('update', 'task', _task, () => {
              if (cb) {
                cb()
              } else {
              }
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      pingTask(task) {
        this.isSaving = true
        backend.pingTask(task.id).then(
          (pings) => {
            task.pings = pings
            this.emitUpdate('update', 'task', task, () => {
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },

      toIncoming(task, groupId) {
        this.isSaving = true
        backend.moveTaskToBucket(task.id, groupId, 'Incoming').then(
          (_response) => {
            this.emitUpdate('delete', 'task', task, () => {
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      toBacklog(task, groupId) {
        this.isSaving = true
        backend.moveTaskToBucket(task.id, groupId, 'Backlog').then(
          (_response) => {
            this.emitUpdate('delete', 'task', task, () => {
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      toggleTaskInBoardType(task, boardType) {
        this.isSaving = true
        backend.toggleTaskInBoardType(task.id, boardType).then(
          (task) => {
            this.emitUpdate('update', 'task', task, () => {
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      toSelectedBoard(task, cb = null) {
        if (!this.addToBoardId) {
          alert('Must select a board to add to first')
          return
        }
        this.isSaving = true
        backend.addTaskToBoard(task.id, this.addToBoardId).then(
          (task) => {
            console.log('task:', task)
            this.emitUpdate('update', 'task', task)
            if (cb) {
              cb()
            } else {
              this.isSaving = false
            }
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },

      selectionDelete() {
        this.forEachTaskCall(
          this.checked,
          (task, cb) => {
            const g = this.getGroupTaskIsIn(task.id)
            this.deleteTask(task, g.id, false, () => {
              cb()
            })
          },
          () => {
            this.checked = []
            this.isSaving = false
          },
        )
      },
      selectionToBoard() {
        this.forEachTaskCall(
          this.checked,
          (task, cb) => {
            this.toSelectedBoard(task, () => {
              cb()
            })
          },
          () => {
            this.checked = []
            this.isSaving = false
          },
        )
      },
      selectionDone() {
        this.forEachTaskCall(
          this.checked,
          (task, cb) => {
            this.doneTask(task, () => {
              cb()
            })
          },
          () => {
            this.checked = []
            this.isSaving = false
          },
        )
      },
      forEachTaskCall(ids, action, final) {
        let index = 0
        const fun = () => {
          const id = ids[index]
          if (!id) {
            if (final) {
              final()
            }
            return
          }
          const task = this.getTask(id)
          action(task, () => {
            index++
            fun()
          })
        }
        fun()
      },

      groupToggleCollapsed(group) {
        group.collapsed = !group.collapsed
        this.onGroupFieldChange('collapsed', group)
      },

      onGroupFieldChange(field, group) {
        this.isSaving = true
        backend.updateGroupField(group.id, field, group[field]).then(
          (_unusedGroup) => {
            this.emitUpdate('update', 'group', group, () => {
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },

      onTaskFieldChange(task, field) {
        this.isSaving = true
        backend.updateTaskField(task.id, field, task[field]).then(
          (_unusedTask) => {
            this.emitUpdate('update', 'task', task, () => {
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },

      // CSS classes

      getHeaderClass(fieldId) {
        return 'th field-' + fieldId
      },
      getValueClass(fieldId, task) {
        return 'td field-' + fieldId + ' ' + this.getValueClassSuffix(fieldId, task)
      },
      getValueClassSuffix(fieldId, task) {
        if (!task) {
          return ''
        }
        let value = task[fieldId] ? '' + task[fieldId] : ''
        value = value.toLowerCase().trim()
        if (value) {
          switch (fieldId) {
            case 'status':
            case 'priority':
              return 'value-' + value
          }
        }
        return ''
      },
      getRowClass(task) {
        const result = ['tr']
        if (task.isEmptyList) {
          result.push('is-empty-list')
        }
        result.push('tr-status-' + task.status.toLowerCase())
        return result.join(' ')
      },

      showTaskModal(task) {
        this.taskModal.windowId = new Date().getTime()
        this.taskModal.taskId = task.id
        this.taskModal.visible = true
      },

      // Selection

      onGroupSelector(group) {
        const taskIdsInGroup = []
        const taskIdsChecked = []

        if (group.tasks) {
          for (let j = 0; j < group.tasks.length; j++) {
            const id = group.tasks[j].id
            taskIdsInGroup.push(id)
            if (group.selector) {
              taskIdsChecked.push(id)
            }
          }
        }

        const newChecked = []
        for (let i = 0; i < this.checked.length; i++) {
          const id = this.checked[i]
          if (taskIdsInGroup.indexOf(id) === -1) {
            newChecked.push(id)
          }
        }
        for (let i = 0; i < taskIdsChecked.length; i++) {
          newChecked.push(taskIdsChecked[i])
        }

        this.checked = newChecked
      },

      // Drag'n'drop

      onDraggedTask(taskId, fromGroupId, toGroupId) {
        // A task can be dragged inside a group (fromGroupId == toGroupId),
        // or between groups (fromGroupId != toGroupId).

        // For each affected group, we build a list of that groups' task ids,
        // and pass this to backend for re-ordering
        // (and or removal from / addition to groups).

        const data = []
        for (let i = 0; i < this.groupsInternal.length; i++) {
          const g = this.groupsInternal[i]
          if (g.id !== fromGroupId && g.id !== toGroupId) {
            continue
          }
          const r = {
            group: g.id,
            tasks: [],
          }
          for (let j = 0; j < g.tasks.length; j++) {
            const t = g.tasks[j]
            if (!t.isEmptyList) {
              r.tasks.push(t.id)
            }
          }
          data.push(r)
        }

        // Then save to backend, and emit update to parent about changed groups

        this.isSaving = true
        backend.updateTasksOrderingAndGroups(this.boardId, data).then(
          (_response) => {
            this.emitUpdate('update', 'groups', this.groupsInternal, () => {
              this.isSaving = false
            })
          },
          (error) => {
            this.error = error
            this.isSaving = false
          },
        )
      },
      onDragTaskUpdate(ev) {
        const taskId = this.dragGetTaskIdFromElement(ev.item)
        const toGroup = taskId ? this.getGroupTaskIsIn(taskId) : null
        this.onDraggedTask(taskId, toGroup.id, toGroup.id)
      },
      onDragTaskAdd(ev) {
        const taskId = this.dragGetTaskIdFromElement(ev.item)
        const fromGroupId = this.dragGroupIdFromElement(ev.from)
        const toGroup = taskId ? this.getGroupTaskIsIn(taskId) : null
        this.onDraggedTask(taskId, fromGroupId, toGroup.id)
      },
      dragGetTaskIdFromElement(elem) {
        const idParts = elem.id.split('-')
        const prefix = idParts.shift()
        return prefix === 'task' ? idParts.join('-') : ''
      },
      dragGroupIdFromElement(elem) {
        const g = elem.closest('.group-container')
        if (g) {
          const parts = g.id.split('-')
          if (parts[0] === 'group') {
            return parseInt(parts[1])
          }
        }
        return null
      },

      onDragGroupUpdate(ev) {
        const data = []
        for (let i = 0; i < this.groupsInternal.length; i++) {
          data.push(this.groupsInternal[i].id)
        }
        backend.updateGroupsOrdering(this.boardId, data)
      },

      // todo: is broken some places, like add/delete task
      updatePlaceholderTasks() {
        // To be able to drop on an empty group with drag-n-drop we must have a dummy-item.
        // This is because the container need to fill some space that we can drop into.
        // So we use a "Empty list" dummy item
        for (let i = 0; i < this.groupsInternal.length; i++) {
          const g = this.groupsInternal[i]
          if (g.tasks.length === 0) {
            // Empty group, add a placeholder
            g.tasks.push(this.makePlaceholderTask(g.id))
          } else if (g.tasks.length > 1) {
            // Group has more than 1 item, if one is a placeholder them remove it
            const remove = []
            for (let i = 0; i < g.tasks.length; i++) {
              const t = g.tasks[i]
              if (t.isEmptyList) {
                remove.push(t)
              }
            }
            for (let i = 0; i < remove.length; i++) {
              const index = g.tasks.indexOf(remove[i])
              if (index > -1) {
                g.tasks.splice(index, 1)
              }
            }
          }
        }
      },

      makePlaceholderTask(groupId) {
        this.nextNewId++
        const id = 'new-task:' + this.nextNewId
        return new Task({
          id,
          description: 'Empty group...',
          isEmptyList: true,
          group: groupId,
        })
      },

      // Actions

      isActionVisible(action) {
        const actions = this.actions || this.defaults.actions
        return actions.indexOf(action) !== -1
      },

      // Input focus

      focusNextInput(elem) {
        const siblings = elem.closest('.tr').querySelectorAll('.input')
        let next = false
        for (let i = 0; i < siblings.length; i++) {
          if (next) {
            siblings[i].focus()
            break
          }
          if (siblings[i] === elem) {
            next = true
          }
        }
      },

      focusAfterNewTask(id, isAddMoreModifierPressed) {
        this.$nextTick(() => {
          let focusElement = null
          const elem = document.getElementById('task-' + id)
          if (isAddMoreModifierPressed) {
            const group = elem ? elem.closest('.group-container') : null
            focusElement = group ? group.querySelector('.tr-add .field-description .input') : null
          } else {
            focusElement = elem ? elem.querySelector('.field-description .input') : null
          }
          if (focusElement) {
            focusElement.focus()
          }
        })
      },

      // Group summary getters

      getGroupStatusSummary(groupId) {
        const stats = this.getGroupStats(groupId)
        if (stats.totalCount === 0) {
          return ''
        }
        return stats.completedCount + ' / ' + stats.totalCount
      },
      getGroupStatusPercent(groupId) {
        const stats = this.getGroupStats(groupId)
        if (stats.totalCount === 0) {
          return 0
        }
        return Math.round((stats.completedCount / stats.totalCount) * 100)
      },
      getGroupEstimateSummary(groupId) {
        const stats = this.getGroupStats(groupId)
        if (stats.totalCount === 0) {
          return ''
        }
        if (!stats.estimateValid) {
          return '(Invalid)'
        }
        return stats.estimateHours + ' h'
      },
      getGroupStats(groupId) {
        let estimate = 0
        let completed = 0
        let total = 0
        let estimateValid = true
        const g = this.getGroup(groupId)
        if (g && g.tasks && g.tasks.length > 0) {
          const tasks = g.tasks
          for (let i = 0; i < tasks.length; i++) {
            const t = tasks[i]
            const status = t.status ? t.status.toLowerCase() : ''
            const e = parseEstimate(t.estimate)
            if (e === null) {
              estimateValid = false
            } else {
              estimate += e
            }
            if (status === 'done' || status === 'skip' || status === 'closed') {
              completed++
            }
            total++
          }
        }
        return {
          completedCount: completed,
          totalCount: total,
          estimateHours: estimate,
          estimateValid,
        }
      },

      // Lookups

      getBoardName(id) {
        for (let i = 0; i < this.boards.length; i++) {
          if (parseInt(this.boards[i].id) === id) {
            return this.boards[i].name
          }
        }
        return ''
      },

      // Utils

      setError(msg, err) {
        if (err && err.response && err.response.data && err.response.data.error) {
          msg += ' : ' + err.response.data.error
        }
        this.error = msg
      },
    },
  }
</script>

<style lang="sass" scoped>

  .group-container
    margin-bottom: 35px

  .group-header
    margin-top: 10px
    margin-bottom: 10px
    line-height: 35px

    .toggle-group-expand
      border-radius: 5px
      display: inline-block
      width: 30px
      text-align: center
      line-height: 30px

      &:hover
        color: white
        background: #007bff

    input
      font-size: 18px
      border: 1px solid transparent
      padding: 3px
      border-radius: 5px

      &:hover
        border: 1px solid #ccc

      &:focus-visible
        border: 1px solid #a5a !important
        outline: none

    .actions-container
      width: 100px
      display: inline-block

      .actions
        display: none

    &:hover .actions-container .actions
      display: inline-block

      a
        display: inline-block
        vertical-align: middle

    .selection-actions-container
      .selection-actions
        border: 1px solid #ddd
        border-radius: 5px
        display: inline-block
        padding-left: 10px
        padding-right: 10px
      .actions
        display: inline-block
      .prefix
        margin-right: 15px
        display: inline-block
      a
        display: inline-block

  .tr
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: flex-start
    align-items: flex-start
    border: 1px solid #ddd
    border-bottom: none

  .tr-add
    border-bottom: 1px solid #ddd
    border-bottom-left-radius: 5px
    border-bottom-right-radius: 5px

  .tr-header
    border-top-left-radius: 5px
    border-top-right-radius: 5px

  .tr-add
    border-color: #eee !important
    background: #fafafa

    .td
      border-color: #eee

  .tr.is-empty-list
    .td
      border: none

    .field-description
      color: #aaa
      line-height: 30px
      vertical-align: middle
      font-style: italic
      padding-left: 10px

  .tr-collapsed
    border: 1px solid #ddd
    border-radius: 5px
    padding: 10px 20px

  .tr:hover
    background: #cceeff

  .th, .td
    width: 100px
    min-width: 100px

  .th
    font-size: 12px
    text-align: center
    line-height: 35px
    vertical-align: middle
    height: 35px
    border-left: 1px solid #ddd

    &:first-child
      border-left: none

  .td
    height: 40px
    font-size: 14px
    border-left: 1px solid #ddd
    padding: 5px

    &:first-child
      border-left: none

  .field-selector
    padding: 5px
    text-align: center
    width: 28px
    min-width: 28px

  .field-createAt
    width: 90px
    min-width: 90px
    text-align: center

  .read-only-value
    line-height: 32px

  .field-description
    width: 200px
    min-width: 200px
    flex-grow: 1

  .field-new-description
    width: 400px
    min-width: 400px

  .field-actions
    min-width: 250px

  .td.field-actions
    vertical-align: middle
    line-height: 30px

    a
      display: none

    &:hover a
      display: inline-block

  .td.field-actions,
  .actions
    a
      padding-left: 10px
      padding-right: 10px
      color: #007bff
      border-radius: 3px

      &:hover
        background: #007bff
        color: white
        cursor: pointer
        text-decoration: none

    a.action-drag:hover
      color: black
      background: #ffea7f

    a.action-delete:hover
      background: #ff0000

    a.action-detach:hover
      background: #ff9900

    a.action-done:hover
      background: #00dd00

    a.action-backlog:hover,
    a.action-board:hover,
    a.action-sprint:hover,
    a.action-week:hover,
    a.action-incoming:hover
      background: #eeccff

  .ghost
    opacity: 0.5
    background: #fffabf

  .error-message
    border: 1px solid red
    padding: 10px
    margin-bottom: 20px
    color: red
    text-align: center

  .loading
    font-size: 16px
    position: absolute
    right: 20px
    top: 10px

  .sprint-week-icon-container
    position: absolute
    top: 4px
    right: 1px
    width: 91px
    text-align: right

  .day-icon,
  .sprint-icon,
  .week-icon
    display: inline-block
    background: #ffea7f
    margin-left: 5px
    width: 25px
    border-radius: 20px
    text-align: center
</style>

<style lang="sass">
  .tr-status-done,
  .tr-status-closed,
  .tr-status-skip
    background: #d5ffe9

  .field-status
    input
      text-align: center

    &.value-todo input
      background: #ffea7f

    &.value-done input,
    &.value-skip input
      background: #55cc99

    &.value-closed input
      background: #159c59
      color: white

    &.value-working input
      background: #aaddff

    &.value-pending input
      background: #eeccff

  .field-priority
    input
      text-align: center

    &.value-low input
      background: #f5f5f5
      color: #aaa

    &.value-normal input
      background: #eee

    &.value-high input
      background: #ff9999

    &.value-super input
      background: #ff0000
      color: white

    &.value-epic input
      background: #cc0000
      color: white
      font-weight: bold
      text-transform: uppercase

  .field-estimate input
    text-align: right

  .status-summary
    background: #ccc

  .status-summary-progress
    background: #55cc99
    height: 25px

  .estimate-summary
    text-align: right
    font-weight: bold
</style>
