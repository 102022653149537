import Vue from 'vue'
import Vuex from 'vuex'

// import createPersistedState from 'vuex-persistedstate'
// import user from './modules/user'

Vue.use(Vuex)

const state = {
  loading: false,
  now: new Date(),
}

const mutations = {
  updateNow(state) {
    state.now = new Date()
  },
  setLoading(state, loadingState) {
    state.loading = loadingState
  },
}

const actions = {}

const getters = {}

const modules = {}

const store = new Vuex.Store({
  /*
  plugins: [
    createPersistedState({
      key: 'state',
      paths: [
        'queue.starredStation',
        'user.token'
      ]
    }),
    addSubscriptions({modules})
  ],
*/
  state,
  getters,
  actions,
  mutations,
  modules,
})

export default store
