import http from '@/utils/http'
import {
  Board,
  Task,
  Group,
  Assignee,
  Customer,
  Status,
  Priority,
  Track,
  Bucket,
  Workspace,
  AssigneeCapacity,
  TrackCapacity,
  toSnakeCase,
} from '@/models/TaskModels'

export default {
  makeErrorMessage(prefix, err) {
    console.error(prefix, err)
    if (err && err.response && err.response.data && err.response.data.error) {
      prefix += ' : ' + err.response.data.error
    }
    return prefix
  },
  makeFiltersUrl(filters) {
    if (filters) {
      return 'filters=' + encodeURIComponent(JSON.stringify(filters))
    } else {
      return ''
    }
  },
  getBoard(type, id, filters, groupBy, orderBy, fields) {
    const url =
      '/todo/board?type=' +
      type +
      '&id=' +
      id +
      '&' +
      this.makeFiltersUrl(filters) +
      (groupBy ? '&groupBy=' + groupBy : '') +
      (orderBy ? '&orderBy=' + orderBy : '') +
      (fields ? '&fields=' + JSON.stringify(fields) : '')
    return new Promise((resolve, reject) => {
      http
        .get(url)
        .then((response) => {
          if (response.data.error) {
            reject({ error: response.data.error, status: 200 })
          } else {
            resolve(Board.fromBackend(response.data.data))
          }
        })
        .catch((err) => {
          const status = err && err.response && err.response.status ? err.response.status : 0
          reject({ error: this.makeErrorMessage('Failed to fetch board data', err), status })
        })
    })
  },
  getBoards(type = null) {
    const url = '/todo/boards?type=' + (type || '')
    return new Promise((resolve, reject) => {
      http
        .get(url)
        .then((response) => {
          const result = []
          for (let i = 0; i < response.data.data.length; i++) {
            result.push(Board.fromBackend(response.data.data[i]))
          }
          resolve(result)
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed to fetch boards', err))
        })
    })
  },
  newTypeBoard(type) {
    const url = '/todo/board/new'
    const name = 'Current ' + type.substr(0, 1).toUpperCase() + type.substr(1)
    return new Promise((resolve, reject) => {
      http
        .post(url, { type, name })
        .then((response) => {
          resolve(Board.fromBackend(response.data.data))
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed to create board by type', err))
        })
    })
  },
  newBoard(payload) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/board/new', payload)
        .then((response) => {
          const board = Board.fromBackend(response.data.data)
          resolve(board)
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed adding board', err))
        })
    })
  },
  deleteBoard(id) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/board/' + id + '/delete')
        .then((_response) => {
          resolve()
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed deleting board', err))
        })
    })
  },
  closeBoard(id) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/board/' + id + '/close')
        .then((_response) => {
          resolve()
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed closing board', err))
        })
    })
  },
  saveBoardSettings(id, settings) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/board/' + id + '/settings', settings)
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed saving board settings', err))
        })
    })
  },
  setBoardMode(id, mode) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/board/' + id + '/set-mode', { mode })
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed setting board mode', err))
        })
    })
  },
  setBoardName(id, name) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/board/' + id + '/set-name', { name })
        .then((response) => {
          resolve(response.data.data)
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed setting board mode', err))
        })
    })
  },
  closeDoneTasks(taskIds) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/close-done-tasks', { tasks: taskIds })
        .then((_response) => {
          resolve()
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed closing done tasks', err))
        })
    })
  },
  getTasks(filters, orderBy, fields) {
    const url =
      '/todo/task/list?' +
      this.makeFiltersUrl(filters) +
      (fields ? '&fields=' + JSON.stringify(fields) : '') +
      (orderBy ? '&orderBy=' + orderBy : '')
    return new Promise((resolve, reject) => {
      http
        .get(url)
        .then((response) => {
          if (response.data.error) {
            reject(response.data.error)
          } else {
            const result = []
            for (let i = 0; i < response.data.data.length; i++) {
              result.push(Task.fromBackend(response.data.data[i]))
            }
            resolve(result)
          }
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed to fetch tasks data', err))
        })
    })
  },
  getTask(id) {
    const url = '/todo/task/' + id
    return new Promise((resolve, reject) => {
      http
        .get(url)
        .then((response) => {
          const task = Task.fromBackend(response.data.data)
          resolve(task)
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed to load task', err))
        })
    })
  },
  pingTask(id) {
    const url = '/todo/task/' + id + '/ping'
    return new Promise((resolve, reject) => {
      http
        .post(url)
        .then((response) => {
          resolve(response.data.data.pings)
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed to ping task', err))
        })
    })
  },
  getMetaData() {
    const url = '/todo/board/metadata'
    return new Promise((resolve, reject) => {
      http
        .get(url)
        .then((response) => {
          if (response.data.error) {
            reject(response.data.error)
          } else {
            const data = {
              workspaces: response.data.data.workspaces.map((v) => Workspace.fromBackend(v)),
              assignees: response.data.data.assignees.map((v) => Assignee.fromBackend(v)),
              statuses: response.data.data.statuses.map((v) => Status.fromBackend(v)),
              priorities: response.data.data.priorities.map((v) => Priority.fromBackend(v)),
              customers: response.data.data.customers.map((v) => Customer.fromBackend(v)),
              tracks: response.data.data.tracks.map((v) => Track.fromBackend(v)),
              buckets: response.data.data.buckets.map((v) => Bucket.fromBackend(v)),
              boards: response.data.data.boards.map((v) => Board.fromBackend(v)),
              assigneeCapacity: response.data.data.assignee_capacity.map((v) => AssigneeCapacity.fromBackend(v)),
              trackCapacity: response.data.data.track_capacity.map((v) => TrackCapacity.fromBackend(v)),
            }
            resolve(data)
          }
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed to fetch metadata', err))
        })
    })
  },
  newAssignee(data) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/assignee/new', data.toBackend())
        .then((response) => {
          resolve(Assignee.fromBackend(response.data.data))
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed saving assignee', err))
        })
    })
  },
  newCustomer(data) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/customer/new', data.toBackend())
        .then((response) => {
          resolve(Customer.fromBackend(response.data.data))
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed saving customer', err))
        })
    })
  },
  newGroup(data) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/group/new', data.toBackend())
        .then((response) => {
          resolve(Group.fromBackend(response.data.data))
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed saving group', err))
        })
    })
  },
  updateGroupField(id, field, value) {
    const payload = {}
    payload[field] = value
    return new Promise((resolve, reject) => {
      http
        .post('/todo/group/' + id + '/update', payload)
        .then((response) => {
          resolve(Group.fromBackend(response.data.data))
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed saving group', err))
        })
    })
  },
  deleteGroup(id) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/group/' + id + '/delete')
        .then((_response) => {
          resolve()
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed deleting group', err))
        })
    })
  },
  updateGroupsOrdering(boardId, groupIds) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/groups-re-order', { board: boardId, groups: groupIds })
        .then((_response) => {
          resolve()
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed re-ordering groups', err))
        })
    })
  },
  newTask(data, groupId) {
    return new Promise((resolve, reject) => {
      const payload = data.toBackend()
      if (groupId) {
        payload.group_id = groupId
      }
      http
        .post('/todo/task/new', payload)
        .then((response) => {
          resolve(Task.fromBackend(response.data.data))
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed saving task', err))
        })
    })
  },
  deleteTask(id, groupId, isDetach) {
    const url = isDetach ? '/todo/task/' + id + '/detach?group=' + groupId : '/todo/task/' + id + '/delete'
    return new Promise((resolve, reject) => {
      http
        .post(url)
        .then((_response) => {
          resolve()
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed deleting task', err))
        })
    })
  },
  updateTaskField(id, field, value) {
    const payload = {}
    payload[toSnakeCase(field)] = value
    return new Promise((resolve, reject) => {
      http
        .post('/todo/task/' + id + '/update', payload)
        .then((response) => {
          resolve(Task.fromBackend(response.data.data))
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed updating task field', err))
        })
    })
  },
  updateTasksOrderingAndGroups(boardId, data) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/tasks-re-order', { board: boardId, groups: data })
        .then((_response) => {
          resolve()
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed re-ordering tasks', err))
        })
    })
  },
  addTaskToBoard(taskId, boardId) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/add-task-to-board', { board: boardId, task: taskId })
        .then((response) => {
          resolve(Task.fromBackend(response.data.data))
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed adding task to board', err))
        })
    })
  },
  toggleTaskInBoardType(taskId, boardType) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/toggle-task-in-board-type', { type: boardType, task: taskId })
        .then((response) => {
          resolve(Task.fromBackend(response.data.data))
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed toggling board-type for task', err))
        })
    })
  },
  moveTaskToBucket(taskId, removeFromGroupId, addToBucket) {
    return new Promise((resolve, reject) => {
      http
        .post('/todo/move-task-to-bucket', { task: taskId, group: removeFromGroupId, bucket: addToBucket })
        .then((_response) => {
          resolve()
        })
        .catch((err) => {
          reject(this.makeErrorMessage('Failed moving task to bucket', err))
        })
    })
  },
}
