<template>
  <div class="container-fluid">
    <div class="row filter-container">
      <div class="col col-lg-12">
        <a @click="showFilter = !showFilter">
          <font-awesome-icon :icon="showFilter ? 'caret-up' : 'caret-down'"></font-awesome-icon>
          Filter
        </a>

        <div v-show="showFilter">
          drop

          <typeahead v-model="filter.customer" :data="['Canada', 'USA', 'Mexico']" />
        </div>
      </div>
    </div>

    <div class="row">
      <!-- LEFT -->
      <div class="col col-lg-8">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <h4>
                Total
                <span class="comment">all tickets, all time</span>
              </h4>
            </div>
          </div>

          <!-- STATS: OPEN -->
          <div class="row">
            <div class="col col-lg-6">
              <stats-card
                :value="openCount.value"
                :label="openCount.label"
                :chart="openCount.chart"
                :suffix="openCount.suffix"
                :thresholds="openCount.thresholds"
                :query="openCount.query"
                :period="openCount.period"
              >
              </stats-card>
            </div>
            <div class="col col-lg-6">
              <!--
              10 new
              190 replied
              80 escalated (open)
              ----
              600 solved
              500 escalated (closed)
              -->
            </div>
          </div>

          <div class="row">
            <div class="col">
              <h4>
                Week
                <span class="comment">counts for last 7 days, graphs for last 6 months</span>
              </h4>
            </div>
          </div>

          <!-- STATS: NEW COUNT / REPLY TIME -->
          <div class="row">
            <div class="col col-lg-6">
              <stats-card
                :value="newCount.value"
                :label="newCount.label"
                :chart="newCount.chart"
                :suffix="newCount.suffix"
                :thresholds="newCount.thresholds"
                :query="newCount.query"
                :period="newCount.period"
              >
              </stats-card>
            </div>
            <div class="col col-lg-6">
              <stats-card
                :value="replyTime.value"
                :label="replyTime.label"
                :chart="replyTime.chart"
                :suffix="replyTime.suffix"
                :thresholds="replyTime.thresholds"
                :query="replyTime.query"
                :period="replyTime.period"
              >
              </stats-card>
            </div>
          </div>

          <!-- STATS: SOLVE COUNT / SOLVE TIME -->
          <div class="row">
            <div class="col col-lg-6">
              <stats-card
                :value="solveCount.value"
                :label="solveCount.label"
                :chart="solveCount.chart"
                :suffix="solveCount.suffix"
                :thresholds="solveCount.thresholds"
                :query="solveCount.query"
                :period="solveCount.period"
              >
              </stats-card>
            </div>
            <div class="col col-lg-6">
              <stats-card
                :value="solveTimeUnescalated.value"
                :label="solveTimeUnescalated.label"
                :chart="solveTimeUnescalated.chart"
                :suffix="solveTimeUnescalated.suffix"
                :thresholds="solveTimeUnescalated.thresholds"
                :query="solveTimeUnescalated.query"
                :period="solveTimeUnescalated.period"
              >
              </stats-card>
            </div>
          </div>

          <!-- STATS: ESCALATE COUNT / SOLVE TIME ESCALATED OR NOT -->
          <div class="row">
            <div class="col col-lg-6">
              <stats-card
                :value="escalateCount.value"
                :label="escalateCount.label"
                :chart="escalateCount.chart"
                :suffix="escalateCount.suffix"
                :thresholds="escalateCount.thresholds"
                :query="escalateCount.query"
                :period="escalateCount.period"
              >
              </stats-card>
            </div>
            <div class="col col-lg-6">
              <stats-card
                :value="solveTimeEscalated.value"
                :label="solveTimeEscalated.label"
                :chart="solveTimeEscalated.chart"
                :suffix="solveTimeEscalated.suffix"
                :thresholds="solveTimeEscalated.thresholds"
                :query="solveTimeEscalated.query"
                :period="solveTimeEscalated.period"
              >
              </stats-card>
            </div>
          </div>
        </div>
        <!-- .container-fluid -->
      </div>
      <!-- left -->

      <!-- RIGHT -->
      <div class="col col-lg-4">
        <h4>
          {{ mostTimeSpentTypes.title }}
          <span class="comment">{{ mostTimeSpentTypes.comment }}</span>
        </h4>
        <bar-chart :labels="mostTimeSpentTypes.labels" :values="mostTimeSpentTypes.values"></bar-chart>

        <h4>
          {{ mostCommonTypes.title }}
          <span class="comment">{{ mostCommonTypes.comment }}</span>
        </h4>
        <bar-chart :labels="mostCommonTypes.labels" :values="mostCommonTypes.values"></bar-chart>

        <h4>
          {{ worstSolveTimeTypes.title }}
          <span class="comment">{{ worstSolveTimeTypes.comment }}</span>
        </h4>
        <bar-chart :labels="worstSolveTimeTypes.labels" :values="worstSolveTimeTypes.values"></bar-chart>

        <h4>
          {{ mostEscalatedTypes.title }}
          <span class="comment">{{ mostEscalatedTypes.comment }}</span>
        </h4>
        <bar-chart :labels="mostEscalatedTypes.labels" :values="mostEscalatedTypes.values"></bar-chart>

        <h4>
          {{ mostCommonClasses.title }}
          <span class="comment">{{ mostCommonClasses.comment }}</span>
        </h4>
        <bar-chart :labels="mostCommonClasses.labels" :values="mostCommonClasses.values"></bar-chart>

        <h4>
          {{ mostCommonCustomers.title }}
          <span class="comment">{{ mostCommonCustomers.comment }}</span>
        </h4>
        <bar-chart :labels="mostCommonCustomers.labels" :values="mostCommonCustomers.values"></bar-chart>
      </div>
      <!-- right -->
    </div>
    <!-- .row -->
  </div>
  <!-- .front-page container-fluid -->
</template>

<script>
  import StatsCard from '@/components/StatsCard.vue'
  import BarChart from '@/components/BarChart.vue'
  // import axios from 'axios/dist/axios.js'
  import http from '@/utils/http'

  export default {
    name: 'TicketsOverview',
    components: { StatsCard, BarChart },
    data() {
      return {
        showFilter: true,
        filter: {
          customer: '',
        },
        openCount: {
          value: 0,
          suffix: 'tickets',
          label: 'open',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        newCount: {
          value: 0,
          suffix: 'tickets',
          label: 'new',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
          query: null,
        },
        solveCount: {
          value: 0,
          suffix: 'tickets',
          label: 'solved',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        escalateCount: {
          value: 0,
          suffix: 'tickets',
          label: 'escalated',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        replyTime: {
          value: 0,
          suffix: 'min',
          label: 'reply time',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        solveTimeUnescalated: {
          value: 0,
          suffix: 'days',
          label: 'solve time unescalated',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        solveTimeEscalated: {
          value: 0,
          suffix: 'days',
          label: 'solve time escalated',
          chart: {
            labels: [],
            values: [],
          },
          thresholds: { levels: null, ascending: null },
        },
        mostCommonTypes: {
          title: 'Most common types',
          comment: 'num of tickets, last 3 months',
          labels: [],
          values: [],
        },
        worstSolveTimeTypes: {
          title: 'Worst solve times',
          comment: 'average solve time days, last 3 months',
          labels: [],
          values: [],
        },
        mostEscalatedTypes: {
          title: 'Most escalated types',
          comment: 'num of tickets, last 3 months',
          labels: [],
          values: [],
        },
        mostTimeSpentTypes: {
          title: 'Most time spent',
          comment: 'sum solve time days, last 3 months',
          labels: [],
          values: [],
        },
        mostCommonClasses: {
          title: 'Most common classes',
          comment: 'num of tickets, last 3 months',
          labels: [],
          values: [],
        },
        mostCommonCustomers: {
          title: 'Most common customers',
          comment: 'num of tickets, last 3 months',
          labels: [],
          values: [],
        },
      }
    },
    mounted() {
      http
        .get('/tickets/stats')
        .then((response) => {
          const data = response.data.data
          for (const key in data) {
            if (this[key].value !== undefined) {
              // Count-ish stat (open tickets, new tickets, solve time, etc)
              this[key].value = data[key].value
              const labels = []
              const values = []
              for (let i = 0; i < data[key].chart.length; i++) {
                labels[i] = data[key].chart[i].label
                values[i] = data[key].chart[i].value
              }
              this[key].chart.labels = labels
              this[key].chart.values = values
              this[key].suffix = data[key].suffix
              this[key].thresholds.levels = data[key].thresholds
              this[key].thresholds.ascending = data[key].thresholdAscending
              this[key].query = data[key].query
              this[key].period = data[key].period
            } else {
              // Type-ish stat (most common types, classes, etc)
              const labels = []
              const values = []
              for (let i = 0; i < data[key].length; i++) {
                labels[i] = data[key][i].label
                values[i] = data[key][i].value
              }
              this[key].labels = labels
              this[key].values = values
            }
          }
        })
        .catch((error) => {
          console.error('get /api/stats', error)
        })
    },
  }
</script>

<style scoped>
  h4 {
    background: #666;
    color: white;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
  }
  h4 .comment {
    font-size: 12px;
    float: right;
    color: #eee;
  }
  a {
    cursor: pointer;
  }
  .filter-container {
    margin-left: 0px;
    margin-bottom: 20px;
  }
</style>
