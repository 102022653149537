<template>
  <div>
    <!-- Board name and actions -->

    <div v-if="board" class="section">
      <todo-side-bar-header v-model="internal.collapsed.board" :title="board.name"></todo-side-bar-header>
      <div class="inner">
        <div class="actions">
          <b-btn v-show="!isBoardVirtual" size="small" variant="success" @click="$emit('closeDoneTasks')">
            Close tasks
          </b-btn>
          <b-btn v-show="!isBoardVirtual" size="small" variant="primary" @click="$emit('saveSettings')">
            Save settings
          </b-btn>
        </div>
        <div v-show="!internal.collapsed.board" class="actions" style="margin-top: 15px">
          <b-btn v-show="board.status === 'open'" size="small" variant="primary" @click="$emit('closeBoard', board)">
            Close board
          </b-btn>
          <b-btn v-show="!isBoardVirtual" size="small" variant="danger" @click="$emit('deleteBoard', board)">
            Delete
          </b-btn>
          <span v-show="!isBoardVirtual"> #{{ board.id }} </span>
        </div>
        <div v-show="!internal.collapsed.board" class="actions" style="margin-top: 15px">
          <b-btn v-show="!isBoardVirtual" size="small" variant="primary" @click="$emit('loadSettings')">
            Load settings
          </b-btn>
          <b-btn v-show="!isBoardVirtual" size="small" variant="primary" @click="$emit('resetSettings')"> Reset</b-btn>
        </div>
        <div v-show="!isBoardVirtual && !internal.collapsed.board" style="margin-top: 15px">
          <div>Name:</div>
          <b-form-input v-model="internal.name" @change="changeBoardName"></b-form-input>
          <div>Mode:</div>
          <b-form-select
            v-model="internal.mode"
            :options="modes"
            text-field="name"
            value-field="id"
            @change="changeBoardMode"
          >
          </b-form-select>
        </div>
        <div v-show="!isBoardVirtual && !internal.collapsed.board" style="margin-top: 15px">
          <div>GroupBy:</div>
          <b-form-select
            v-model="internal.groupBy"
            :options="groupBys"
            text-field="name"
            value-field="id"
            @change="changeBoardGroupBy"
          >
          </b-form-select>
        </div>
        <div v-show="!internal.collapsed.board" style="margin-top: 15px">
          <label style="display: block">
            <input v-model="internal.noConfirmations" type="checkbox" />
            No confirmations
          </label>
        </div>
        <div style="margin-top: 15px">
          <div>Add to board:</div>
          <b-form-select v-model="internal.addToBoardId" :options="boards" text-field="name" value-field="id">
          </b-form-select>
        </div>
      </div>
    </div>

    <!-- Filters -->

    <div v-if="filters !== null && filters !== undefined">
      <!-- Search -->

      <div class="section">
        <todo-side-bar-header v-model="internal.collapsed.search" title="Search"></todo-side-bar-header>

        <div v-show="!internal.collapsed.search" class="inner">
          <todo-filter
            v-model="internal.filters.data.search"
            :collapsed="internal.filters.collapsed.search"
            :is-text-field="true"
            @expand="internal.filters.collapsed.search = !internal.filters.collapsed.search"
            @input="filterUpdate"
          ></todo-filter>
        </div>
      </div>

      <div class="section">
        <todo-side-bar-header v-model="internal.collapsed.filters" title="Filters"></todo-side-bar-header>

        <div v-show="internal.collapsed.filters" class="inner">
          {{ filtersText }}
        </div>

        <div v-show="!internal.collapsed.filters" class="inner">
          <!-- Customer -->

          <todo-filter
            v-model="internal.filters.data.customer"
            :collapsed="internal.filters.collapsed.customer"
            :hide-zero="true"
            :options="customers"
            title="Customer"
            @expand="internal.filters.collapsed.customer = !internal.filters.collapsed.customer"
            @input="filterUpdate"
          ></todo-filter>

          <!-- Assignee -->

          <todo-filter
            v-model="internal.filters.data.assignee"
            :collapsed="internal.filters.collapsed.assignee"
            :hide-zero="true"
            :options="assignees"
            title="Assignee"
            @expand="internal.filters.collapsed.assignee = !internal.filters.collapsed.assignee"
            @input="filterUpdate"
          ></todo-filter>

          <!-- Status -->

          <todo-filter
            v-model="internal.filters.data.status"
            :collapsed="internal.filters.collapsed.status"
            :options="statuses"
            title="Status"
            @expand="internal.filters.collapsed.status = !internal.filters.collapsed.status"
            @input="filterUpdate"
          ></todo-filter>

          <!-- Priority -->

          <todo-filter
            v-model="internal.filters.data.priority"
            :collapsed="internal.filters.collapsed.priority"
            :options="priorities"
            title="Priority"
            @expand="internal.filters.collapsed.priority = !internal.filters.collapsed.priority"
            @input="filterUpdate"
          ></todo-filter>

          <!-- Track -->

          <todo-filter
            v-model="internal.filters.data.track"
            :collapsed="internal.filters.collapsed.track"
            :options="tracks"
            title="Track"
            @expand="internal.filters.collapsed.track = !internal.filters.collapsed.track"
            @input="filterUpdate"
          ></todo-filter>

          <!-- HasBoard -->

          <todo-filter
            v-model="internal.filters.data.hasBoard"
            :collapsed="internal.filters.collapsed.hasBoard"
            :hide-zero="false"
            :options="hasBoards"
            title="In board"
            @expand="internal.filters.collapsed.hasBoard = !internal.filters.collapsed.hasBoard"
            @input="filterUpdate"
          ></todo-filter>

          <!-- Bucket -->

          <todo-filter
            v-model="internal.filters.data.bucket"
            :collapsed="internal.filters.collapsed.bucket"
            :hide-zero="false"
            :options="buckets"
            title="Bucket"
            @expand="internal.filters.collapsed.bucket = !internal.filters.collapsed.bucket"
            @input="filterUpdate"
          ></todo-filter>

          <!-- IncludeClosed -->

          <todo-filter
            v-model="internal.filters.data.includeClosed"
            :collapsed="internal.filters.collapsed.includeClosed"
            :hide-zero="false"
            :options="includeCloseds"
            title="Include closed"
            @expand="internal.filters.collapsed.includeClosed = !internal.filters.collapsed.includeClosed"
            @input="filterUpdate"
          ></todo-filter>

          <!-- SQL -->

          <todo-filter
            v-model="internal.filters.data.sql"
            :collapsed="internal.filters.collapsed.sql"
            :is-text-area="true"
            title="SQL"
            @expand="internal.filters.collapsed.sql = !internal.filters.collapsed.sql"
            @input="filterUpdate"
          ></todo-filter>
        </div>
      </div>
    </div>

    <!-- Order by -->

    <div class="section">
      <todo-side-bar-header v-model="internal.collapsed.orderBy" title="Order By"></todo-side-bar-header>
      <div v-show="!internal.collapsed.orderBy" class="inner">
        <b-form-input v-model="internal.orderBy" @change="changeBoardOrderBy"></b-form-input>
      </div>
    </div>

    <!-- Fields -->

    <div class="section">
      <todo-side-bar-header v-model="internal.collapsed.fields" title="Fields"></todo-side-bar-header>
      <div v-show="!internal.collapsed.fields" class="inner">
        <div v-for="field in allFields" :key="field">
          <b-checkbox v-model="internal.fields" :value="field">{{ field }}</b-checkbox>
        </div>
      </div>
    </div>

    <!-- Actions -->

    <div class="section">
      <todo-side-bar-header v-model="internal.collapsed.actions" title="Actions"></todo-side-bar-header>
      <div v-show="!internal.collapsed.actions" class="inner">
        <div v-for="action in allActions" :key="action">
          <b-checkbox v-model="internal.actions" :value="action">{{ action }}</b-checkbox>
        </div>
        <div style="margin-top: 20px">
          <a @click="resetActions()">Use defaults (and save)</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TodoFilter from '../components/TodoFilter'
  import TodoSideBarHeader from '../components/TodoSideBarHeader'

  export default {
    components: {
      TodoFilter,
      TodoSideBarHeader,
    },
    props: [
      'board',
      'boards',
      'assignees',
      'buckets',
      'customers',
      'priorities',
      'statuses',
      'tracks',
      'noConfirmations',
      'addToBoardId',
      'filters',
      'fields',
      'actions',
      'collapsed',
      'groupBy',
      'orderBy',
    ],
    data() {
      return {
        internal: {
          fields: [],
          actions: [],
          addToBoardId: null,
          mode: '',
          name: '',
          groupBy: '',
          orderBy: '',
          filters: {
            visible: {
              search: true,
              assignee: true,
              status: true,
              priority: true,
              track: true,
              customer: true,
              bucket: true,
              sql: true,
              hasBoard: true,
              includeClosed: true,
            },
            collapsed: {
              search: false,
              assignee: true,
              status: true,
              priority: true,
              track: true,
              customer: true,
              bucket: true,
              sql: false,
              hasBoard: true,
              includeClosed: true,
            },
            data: {
              search: '',
              assignee: [],
              status: [],
              priority: [],
              track: [],
              customer: [],
              bucket: [],
              sql: '',
              hasBoard: [],
              includeClosed: [],
            },
          },
          noConfirmations: null,
          collapsed: {
            // will be overridden from TodoOverride
            board: false,
            search: false,
            filters: false,
            fields: false,
            actions: false,
          },
        },
        modes: [
          { id: 'board', name: 'Board' },
          { id: 'view', name: 'View' },
        ],
        hasBoards: [
          { id: '1', name: 'In board(s)' },
          { id: '0', name: 'Not in any board' },
        ],
        includeCloseds: [{ id: '1', name: 'Include closed tasks' }],
        groupBys: [
          { id: 'group', name: 'Group' },
          { id: 'assignee', name: 'Assignee' },
          { id: 'priority', name: 'Priority' },
          { id: 'track', name: 'Track' },
          { id: 'customer', name: 'Customer' },
        ],
        allFields: [
          'selector',
          'description',
          'assigneeId',
          'status',
          'estimate',
          'priority',
          'customerId',
          'trackId',
          'bucket',
          'reference',
          'boards',
          'pings',
          'createAt',
          'doneAt',
          'actions',
          'sprintIcon',
          'weekIcon',
        ],
        allActions: [
          'group-drag',
          'group-delete',
          'drag',
          'detach',
          'delete',
          'done',
          'backlog',
          'board',
          'incoming',
          'sprint',
          'week',
          'day',
          'clone',
          'show',
          'ping',
        ],
      }
    },
    computed: {
      isBoardVirtual() {
        return !this.board.id
      },
      isAddToBoardActionVisible() {
        return this.actions.indexOf('board') !== -1
      },
      filtersText() {
        const result = []
        for (const key in this.internal.filters.data) {
          const v = this.internal.filters.data[key]
          const isset = Array.isArray(v) ? v.length > 0 : !!v
          if (isset) {
            result.push(key)
          }
        }
        if (result.length > 0) {
          return 'Filtering by ' + result.join(', ')
        } else {
          return 'No filters active'
        }
      },
    },
    watch: {
      filters: {
        handler() {
          this.filterUpdateFromExternal()
        },
        deep: true,
      },
      fields() {
        this.internal.fields = this.fields
      },
      actions() {
        this.internal.actions = this.actions
      },
      collapsed() {
        if (this.collapsed) {
          for (const key in this.collapsed) {
            this.internal.collapsed[key] = this.collapsed[key]
          }
        }
      },
      board() {
        this.internal.mode = this.board.mode
        this.internal.name = this.board.name
      },
      groupBy() {
        this.internal.groupBy = this.groupBy
      },
      orderBy() {
        this.internal.orderBy = this.orderBy
      },
      'internal.fields'() {
        this.$emit('changeFields', this.internal.fields)
      },
      'internal.actions'() {
        this.$emit('changeActions', this.internal.actions)
      },
      'internal.noConfirmations'() {
        this.$emit('changeNoConfirmations', this.internal.noConfirmations)
      },
      'internal.addToBoardId'() {
        this.$emit('changeAddToBoardId', this.internal.addToBoardId)
      },
      'internal.collapsed': {
        handler() {
          this.$emit('changeCollapsed', this.internal.collapsed)
        },
        deep: true,
      },
      noConfirmations() {
        this.internal.noConfirmations = this.noConfirmations
      },
      addToBoardId() {
        this.internal.addToBoardId = this.addToBoardId
      },
    },
    mounted() {
      this.internal.noConfirmations = this.noConfirmations
      this.internal.addToBoardId = this.addToBoardId
      this.internal.groupBy = this.groupBy
      this.internal.orderBy = this.orderBy
      this.filterUpdateFromExternal()
    },
    methods: {
      resetActions() {
        this.internal.actions = [
          'group-drag',
          'group-delete',
          'drag',
          'detach',
          'delete',
          'done',
          'sprint',
          'week',
          'day',
        ]
        setTimeout(() => { this.$emit('saveSettings') }, 500)
      },
      filterUpdateFromExternal() {
        const filters = this.filters || {}
        for (const key in this.internal.filters.data) {
          const defaultValue = this.filterIsText(key) ? '' : []
          let value = null
          if (filters[key]) {
            if (Array.isArray(filters[key])) {
              value = []
              Object.assign(value, filters[key])
            } else {
              value = filters[key]
            }
          } else {
            value = defaultValue
          }
          this.internal.filters.data[key] = value
        }
      },
      filterIsText(field) {
        return field === 'sql' || field === 'search'
      },
      filterUpdate() {
        this.$emit('changeFilters', this.internal.filters.data)
      },
      changeBoardMode() {
        if (this.internal.mode) {
          this.$emit('changeMode', this.internal.mode)
        }
      },
      changeBoardName() {
        this.$emit('changeName', this.internal.name)
      },
      changeBoardGroupBy() {
        this.$emit('changeGroupBy', this.internal.groupBy)
      },
      changeBoardOrderBy() {
        this.$emit('changeOrderBy', this.internal.orderBy)
      },
    },
  }
</script>

<style lang="sass" scoped>
  .actions .btn
    margin-right: 10px

  .section
    margin-bottom: 20px

  .inner
    margin: 20px

  a:hover
    cursor: pointer
</style>
