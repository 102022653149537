<template>
  <div class="stats-card">
    <div class="left">
      <div class="badge" :class="thresholdClass">
        <router-link :to="link">
          <div class="value">{{ value }}</div>
          <div class="suffix">{{ suffix }}</div>
        </router-link>
      </div>
      <div class="badge-footer">{{ label }}</div>
    </div>
    <div class="right">
      <line-chart
        v-if="isTypeLine"
        :labels="chart.labels"
        :values="chart.values"
        :thresholds="thresholds"
        :query="query"
      >
      </line-chart>
      <bar-chart v-if="!isTypeLine" :labels="chart.labels" :values="chart.values" :thresholds="thresholds"> </bar-chart>
    </div>
  </div>
</template>

<script>
  import LineChart from './LineChart.vue'
  import BarChart from './BarChart.vue'
  export default {
    name: 'StatsCard',
    components: { LineChart, BarChart },
    props: ['value', 'suffix', 'label', 'chart', 'thresholds', 'type', 'query', 'period'],
    computed: {
      isTypeLine() {
        return this.type !== 'bar'
      },
      thresholdClass() {
        const classes = ['badge-success', 'badge-warning', 'badge-error']
        if (!this.thresholds.levels) {
          return {}
        }
        for (let i = 0; i < this.thresholds.levels.length; i++) {
          const value = this.thresholds.levels[i]
          if (this.thresholds.ascending) {
            if (this.value <= value) {
              const result = {}
              result[classes[i]] = true
              return result
            }
          } else {
            if (this.value >= value) {
              const result = {}
              result[classes[i]] = true
              return result
            }
          }
        }
        const result = {}
        result[classes[2]] = true
        return result
      },
      link() {
        return {
          name: 'TicketsList',
          params: {
            query: this.query,
            start: this.period ? this.period.start : '',
            end: this.period ? this.period.end : '',
          },
        }
      },
    },
  }
</script>

<style scoped>
  .badge {
    height: 70px;
    width: 70px;
    border-radius: 70px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .badge .value {
    padding-top: 9px;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
  }
  .badge .suffix {
    font-size: 11px;
    line-height: 11px;
    text-align: center;
  }
  .badge-footer {
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
  }
  .badge-success {
    background: #5cb85c;
  }
  .badge-warning {
    background: #e89400;
  }
  .badge-error {
    background: #f9433f;
  }
  .badge a {
    color: black;
    text-decoration: none;
  }
  .badge a:hover {
    color: white;
  }
  .stats-card {
    /*border: 1px solid red;*/
    padding: 20px;
    padding-left: 0;
  }
  .stats-card .left {
    width: 23%;
    display: inline-block;
    vertical-align: top;
  }
  .stats-card .right {
    width: 75%;
    overflow: hidden;
    display: inline-block;
  }
</style>
